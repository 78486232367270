import axios from 'axios';

export const sendContactMessage = (formDetails, googleToken) => async (dispatch) => {
  try {
    const body = {
      firstName: formDetails.firstName,
      lastName: formDetails.lastName,
      emailAddress: formDetails.emailAddress,
      message: formDetails.message,
      googleToken,
    };

    console.log(body);

    const res = await axios.post(`/api/contactMessage`, body, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    console.log(res.data);
  } catch (err) {
    // const errors = err.response.data.message;
    console.log(err);
  }
};
