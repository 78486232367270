export const GET_PUBLIC_COURSES_REQUEST = 'GET_PUBLIC_COURSES_REQUEST';
export const GET_PUBLIC_COURSES_SUCCESS = 'GET_PUBLIC_COURSES_SUCCESS';

export const GET_COURSES_OWNED_REQUEST = 'GET_COURSES_OWNED_REQUEST';
export const GET_COURSES_OWNED_SUCCESS = 'GET_COURSES_OWNED_SUCCESS';

export const GET_PRIVATE_COURSES_REQUEST = 'GET_PRIVATE_COURSES_REQUEST';
export const GET_PRIVATE_COURSES_SUCCESS = 'GET_PRIVATE_COURSES_SUCCESS';
export const GET_PRIVATE_COURSES_RESET = 'GET_PRIVATE_COURSES_RESET';

export const COURSE_LIST_REQUEST = 'COURSE_LIST_REQUEST';

export const SAVE_FEATURED_COURSES_REQUEST = 'SAVE_FEATURED_COURSES_REQUEST';
export const SAVE_FEATURED_COURSES_SUCCESS = 'SAVE_FEATURED_COURSES_SUCCESS';
export const SAVE_FEATURED_COURSES_FAIL = 'SAVE_FEATURED_COURSES_FAIL';

export const DELETE_COURSE_VIDEOCLASS_REQUEST = 'DELETE_COURSE_VIDEOCLASS_REQUEST';
export const DELETE_COURSE_VIDEOCLASS_SUCCESS = 'DELETE_COURSE_VIDEOCLASS_SUCCESS';
export const DELETE_COURSE_VIDEOCLASS_FAIL = 'DELETE_COURSE_VIDEOCLASS_FAIL';

export const CLASS_WATCHED_UPDATED_REQUEST = 'CLASS_WATCHED_UPDATED_REQUEST';
export const CLASS_WATCHED_UPDATED_RESET = 'CLASS_WATCHED_UPDATED_RESET';

export const SET_COURSES_IMAGES = 'SET_COURSES_IMAGES';
export const RESET_COURSES_LOADED = 'RESET_COURSES_LOADED';

export const ADD_FREE_COURSE_TO_USER_REQUEST = 'ADD_FREE_COURSE_TO_USER_REQUEST';
export const ADD_FREE_COURSE_TO_USER_SUCCESS = 'ADD_FREE_COURSE_TO_USER_SUCCESS';

export const SUBMIT_LESSON_COMMENT_REQUEST = 'SUBMIT_LESSON_COMMENT_REQUEST';
export const GET_ALL_LESSON_COMMENTS_REQUEST = 'GET_ALL_LESSON_COMMENTS_REQUEST';
export const GET_ALL_LESSON_COMMENTS_SUCCESS = 'GET_ALL_LESSON_COMMENTS_SUCCESS';
export const GET_ALL_LESSON_COMMENTS_FAIL = 'GET_ALL_LESSON_COMMENTS_FAIL';
export const GET_ALL_LESSON_COMMENTS_RESET = 'GET_ALL_LESSON_COMMENTS_RESET';
