import React, { useState, useEffect } from 'react';
import SecondHeader from '../partials/SecondHeader';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { RESET_CHECKOUT_SUCCESS } from '../../contants/paymentConstants';
import styles from './CheckoutSuccess.module.css';
import { FaLevelUpAlt } from 'react-icons/fa';
// import {
//   RESET_CHECKOUT_SUCCESS
// } from '../../contants/paymentConstants';

const CheckoutSuccess = ({ history }) => {
  const dispatch = useDispatch();
  const payment = useSelector((state) => state.payment);

  const { checkoutSuccess } = payment;

  const [page, setPage] = useState({
    loaded: false,
  });

  const [success, setSuccess] = useState({
    loaded: false,
    value: false,
  });

  useEffect(() => {
    // setPage({ loaded: true })
    if (!success.loaded) {
      setSuccess({
        loaded: true,
        value: checkoutSuccess,
      });
      dispatch({ type: RESET_CHECKOUT_SUCCESS });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  // useEffect(() => {
  //   if( auth && auth.isAuthenticated ) {
  //     setPage({
  //       loaded: true
  //     })
  //   }

  // }, [auth && auth.isAuthenticated]);

  // if( auth && !auth.isAuthenticated && page.loaded) {
  //   return <Redirect to="/" />
  // }
  // useEffect(() => {
  //   if (page.loaded && !checkoutSuccess) {
  //     history.push('/');
  //   }
  // }, [page]);

  if (success.loaded && !success.value) {
    history.push('/');
  }

  return (
    <>
      <SecondHeader />
      {/* <div className="checkoutSuccessCtn">
        <div className="container">
          <div className="row">
            <div className="col-12 checkoutSuccess">
              <Card>
                <Card.Header></Card.Header>
                <Card.Body>
                  <h1>Thank you! Your Order is Complete..</h1>
                  <p>You will receive an email confirmation shortly.</p>
                  <Link className="btn checkoutSuccessBtn" to="/courses">
                    Start Learning
                  </Link>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div> */}
      <div className={`${styles.checkoutSuccessCtn} ctnPadding mainBackground`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <Card className="boxShadow">
                <Card.Header>
                  <h1 className="cardHeaderTitle">Success</h1>
                </Card.Header>
                <Card.Body className="text-center">
                  <p className="carSubTitle">Thank you! Your order is complete...</p>
                  <p className="carSubTitle">You will receive an email confirmation shortly.</p>
                  <Link className="button secondBtn" to="/courses">
                    Start Learning
                  </Link>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// const mapStateToProps = state => ({
//   auth: state.auth,
//   active: state.auth.active,
//   courses: state.courses,
//   payment: state.payment
// });

export default CheckoutSuccess;
