import {
  LOAD_DB_BILLING_ADDRESS_REQUEST,
  LOAD_DB_BILLING_ADDRESS_SUCCESS,
  EDIT_LOCAL_BILLING_ADDRESS,
  SAVE_LOCAL_BILLING_ADDRESS,
} from '../contants/billingConstants';
import { LOGOUT, LOGOUT_FAIL } from '../contants/authConstants';

const initialState = {
  editing: true,
  loading: false,
  loaded: false,
  saveAddress: false,
  local: {
    firstName: '',
    lastName: '',
    billingAddress: '',
    city: '',
    addressCode: '',
    country: '',
    countryCode: '',
  },
  db: {
    firstName: '',
    lastName: '',
    billingAddress: '',
    city: '',
    addressCode: '',
    country: '',
    countryCode: '',
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_DB_BILLING_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case LOAD_DB_BILLING_ADDRESS_SUCCESS:
      return {
        ...state,
        loaded: true,
        db: {
          firstName: payload.firstName,
          lastName: payload.lastName,
          city: payload.city,
          address: payload.address,
          country: payload.country,
          countryCode: payload.countryCode,
          addressCode: payload.addressCode,
        },
        loading: false,
      };
    case EDIT_LOCAL_BILLING_ADDRESS:
      return {
        ...state,
        editing: true,
      };
    case SAVE_LOCAL_BILLING_ADDRESS:
      return {
        ...state,
        editing: false,
        saveAddress: payload.saveAddress,
        local: {
          firstName: payload.firstName,
          lastName: payload.lastName,
          city: payload.city,
          address: payload.address,
          country: payload.country,
          countryCode: payload.countryCode,
          addressCode: payload.addressCode,
        },
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
