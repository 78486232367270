import {
  GET_COUNTRY_TAX_REQUEST,
  GET_COUNTRY_TAX_SUCCESS,
  RESET_COUNTRY_TAX,
  SAVE_COUNTRY_NAME,
} from '../contants/taxConstants';

const initialState = {
  countryName: '',
  countryCode: '',
  countryTax: 0,
  countryTaxLoading: false,
  countryTaxLoaded: false,
};

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_COUNTRY_TAX_REQUEST:
      return {
        ...state,
        countryTaxLoading: true,
        countryTaxLoaded: false,
      };
    case GET_COUNTRY_TAX_SUCCESS:
      return {
        ...state,
        countryCode: payload.countryCode,
        countryTax: payload.countryTax,
        countryTaxLoading: false,
        countryTaxLoaded: true,
      };
    case SAVE_COUNTRY_NAME:
      return {
        ...state,
        countryName: payload,
      };
    case RESET_COUNTRY_TAX:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
