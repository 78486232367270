import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBillingAddress, saveLocalBillingAddress } from '../../actions/billing';
import { Form, Row, Col, Button } from 'react-bootstrap';
import lookup from 'country-code-lookup';
import { Dropdown } from 'semantic-ui-react';
import { RESET_COUNTRY_TAX, SAVE_COUNTRY_NAME } from '../../contants/taxConstants';
import styles from './BillingAddress.module.css';

const BillingAddress = () => {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const { token } = auth;

  const billingRedux = useSelector((state) => state.billing);

  const { local, db, loading, loaded } = billingRedux;

  const [billingAddress, setBillingAddress] = useState({
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    addressCode: '',
    country: '',
    countryCode: '',
    saveAddress: false,
  });

  const [validated, setValidated] = useState(false);

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    setCountries(lookup.countries);
  }, []);

  useEffect(() => {
    if (!loaded && !loading && token) {
      dispatch(getBillingAddress(token));
    }
  }, [loading, loaded, token]);

  useEffect(() => {
    if (local.firstName || db.firstName) {
      setBillingAddress({
        ...billingAddress,
        firstName: local.firstName ? local.firstName : db.firstName,
        lastName: local.lastName ? local.lastName : db.lastName,
        address: local.address ? local.address : db.address,
        city: local.city ? local.city : db.city,
        addressCode: local.addressCode ? local.addressCode : db.addressCode,
        country: local.country ? local.country : db.country,
        countryCode: local.countryCode ? local.countryCode : db.countryCode,
      });
    }
  }, [billingRedux]);

  const updateBilingState = (e) => {
    setBillingAddress({
      ...billingAddress,
      [e.target.name]: e.target.name !== 'saveAddress' ? e.target.value : e.target.checked,
    });
  };

  const submitBillingAddress = (event) => {
    event.preventDefault();

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      dispatch({ type: SAVE_COUNTRY_NAME, payload: billingAddress.country });
      dispatch(saveLocalBillingAddress(billingAddress));
    }

    setValidated(true);
  };

  const countryOptions = countries.map((country) => {
    return {
      key: country.iso2,
      value: country.iso2,
      flag: country.iso2.toLowerCase(),
      text: country.country,
    };
  });

  const countriesToRemove = [
    'im',
    'aq',
    'gg',
    'bl',
    'je',
    'cw',
    'mf',
    'sx',
    'ss',
    'bq',
    'xq',
    'xk',
  ];

  const filteredCountries = countryOptions.filter((country) => {
    return !countriesToRemove.includes(country.flag);
  });

  const updateCountry = (e) => {
    dispatch({ type: RESET_COUNTRY_TAX });
    const findCountry = countries.find((country) => {
      return country.country === e.target.textContent;
    });
    setBillingAddress({
      ...billingAddress,
      country: findCountry?.country ?? '',
      countryCode: findCountry?.iso2 ?? '',
    });
  };

  return (
    <Form noValidate validated={validated} onSubmit={submitBillingAddress}>
      <Row className="align-items-center m-0">
        <Col xs={12} sm={6}>
          <Form.Group className="mb-3" controlId="formGroupFirstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              value={billingAddress.firstName}
              onChange={updateBilingState}
              required
            />
            <Form.Control.Feedback type="invalid">Enter your first name.</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="formGroupLastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              value={billingAddress.lastName}
              onChange={updateBilingState}
              required
            />
            <Form.Control.Feedback type="invalid">Enter your last name.</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row className="align-items-center m-0">
        <Col>
          <Form.Group className="mb-3" controlId="formGroupLastName">
            <Form.Label>Billing address</Form.Label>
            <Form.Control
              type="text"
              name="address"
              value={billingAddress.address}
              onChange={updateBilingState}
              required
            />
            <Form.Control.Feedback type="invalid">
              Enter your billing address.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row className="align-items-center m-0">
        <Col>
          <Form.Group className="mb-3" controlId="formGroupCity">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              name="city"
              value={billingAddress.city}
              onChange={updateBilingState}
              required
            />
            <Form.Control.Feedback type="invalid">Enter your city.</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={12} sm={6}>
          <Form.Group className="mb-3" controlId="formGroupPostalCode">
            <Form.Label>Zip or postal code</Form.Label>
            <Form.Control
              type="text"
              name="addressCode"
              value={billingAddress.addressCode}
              onChange={updateBilingState}
              required
            />
            <Form.Control.Feedback type="invalid">Enter your zip code.</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row className="align-items-center m-0">
        <Col>
          <Form.Group className="mb-3" controlId="formGroupCountry">
            <Form.Label>Country</Form.Label>
            <Form.Control
              type="text"
              name="country"
              value={billingAddress.country}
              onChange={updateBilingState}
              className="d-none"
              required
            />

            <Dropdown
              // defaultValue={billingAddress.countryCode}
              clearable
              fluid
              selection
              name="country"
              onChange={updateCountry}
              options={filteredCountries}
              placeholder="Select Country"
              value={billingAddress.countryCode}
            />
            <Form.Control.Feedback type="invalid">Enter your country.</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row className="align-items-center m-0">
        <Col className="my-3">
          <Form.Check
            type="checkbox"
            className={styles.defaultCheckboxCtn}
            id={'default-checkbox'}
            name="saveAddress"
            onChange={updateBilingState}
            checked={billingAddress.saveAddress}
            value={billingAddress.saveAddress}
            label="Save my billing information so checkout is easy next time"
          />
        </Col>
        <Col md={12} className="d-flex justify-content-end">
          <Button type="submit" className="button mainBtn">
            Continue
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default BillingAddress;
