import React from 'react';
// import footerLogo from '../../images/telmoacademy-logo1.png';
import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer>
      <Container>
        <Row className={styles.footerSocialCtn}>
          <div className={styles.footerLogoSocialCtn}>
            <div className={styles.footerRights}>
              <p>&copy; Telmo Academy {year}</p>
              {/* <p>All rights reserved</p> */}
            </div>
            {/* <span className="footerEmail"><i className="fas fa-envelope"></i>support@telmoacademy.com</span> */}
            <div className={styles.footerSocial}>
              <a
                href="https://www.youtube.com/user/Telmo87/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="fab fa-youtube"></i>
              </a>
              <a href="https://twitter.com/DevTelmo" rel="noopener noreferrer" target="_blank">
                <i className="fab fa-twitter"></i>
              </a>
              <a
                href="https://www.instagram.com/sampaiotravels"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>

          <div className={styles.footerLinks}>
            <div>
              <Link to="/privacy">Privacy</Link>
              <Link to="/terms">Terms</Link>
            </div>
            <div>
              <Link to="/migration">Old Website</Link>
              <Link to="/contact">Contact</Link>
            </div>
            <div>
              <a
                href={
                  process.env.REACT_APP_NODE_ENV === 'production'
                    ? 'https://blog.telmoacademy.com'
                    : 'https://blog.telmo.academy'
                }
              >
                Blog
              </a>
            </div>
          </div>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
