import axios from 'axios';
import {
  CHECK_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_FAIL,
  RESUBSCRIBE_SUBSCRIPTION_REQUEST,
  SUBSCRIPTION_DETAILS_SUCCESS,
  SUBSCRIPTION_DETAILS_REQUEST,
  SUBSCRIPTION_DETAILS_FAIL,
} from '../contants/subscriptionConstants';

export const checkMembership = (token) => async (dispatch) => {
  try {
    const body = JSON.stringify({ token });

    const res = await axios.post(`/api/checkMembership`, body, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({
      type: CHECK_SUBSCRIPTION,
      payload: res.data,
    });
  } catch (err) {
    // const errors = err.response.data.message;
    console.log(err);
  }
};

export const cancelSubscription = (token) => async (dispatch) => {
  try {
    dispatch({
      type: CANCEL_SUBSCRIPTION_REQUEST,
    });

    const res = await axios.post(
      `/api/cancelSubscription`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(membershipDetails(token));
  } catch (error) {
    console.log(error);
    dispatch({
      type: CANCEL_SUBSCRIPTION_FAIL,
    });
  }
};

export const subscriptionResubscribe = (token, history) => async (dispatch) => {
  try {
    dispatch({
      type: RESUBSCRIBE_SUBSCRIPTION_REQUEST,
    });

    const res = await axios.post(
      `/api/resubscribeSubscription`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(membershipDetails(token));
    if (!res.data.success) {
      history.push('/subscription');
    }
  } catch (error) {
    console.log(error);
  }
};

export const membershipDetails = (token) => async (dispatch) => {
  try {
    if (!token) {
      throw new Error('Token missin in membershipDetails Action');
    }

    dispatch({
      type: SUBSCRIPTION_DETAILS_REQUEST,
    });

    const res = await axios.post(
      `/api/subscriptionDetails`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch({
      type: SUBSCRIPTION_DETAILS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    // console.log(error);
    dispatch({
      type: SUBSCRIPTION_DETAILS_FAIL,
    });
  }
};
