import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { sendContactMessage } from '../../actions/contact';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import PageWrapper from '../utils/PageWrapper';
import styles from './Contact.module.css';

const Contact = () => {
  const dispatch = useDispatch();

  const [validated, setValidated] = useState(false);
  const [displayMessage, setDisplayMessage] = useState(false);
  const [formLocked, setFormLocked] = useState(false);

  const [formDetails, setFormDetails] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    message: '',
  });

  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    document.title = `Contact page`;
  }, []);

  const submitMessage = async (event) => {
    event.preventDefault();
    // console.log(billingAddress);
    const form = event.currentTarget;

    if (form.checkValidity() === false || formLocked) {
      event.stopPropagation();
    } else {
      // dispatch({ type: SAVE_COUNTRY_NAME, payload: billingAddress.country });
      // dispatch(saveLocalBillingAddress(billingAddress));
      const googleToken = await executeRecaptcha('submit');
      setFormLocked(true);

      setDisplayMessage(true);
      dispatch(sendContactMessage(formDetails, googleToken));
    }

    setValidated(true);
  };

  const updateFormState = (e) => {
    console.log(e.target.value);

    // if (displayMessage) {
    //   setDisplayMessage(false);
    // }

    setFormDetails({
      ...formDetails,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <PageWrapper>
      <div className={styles.contactCtn}>
        {/* <SecondHeader /> */}
        <h1 className="mainTitle">Contact Telmo Academy</h1>
        <p className="my-4">
          We're here to help and answer any question you might have. We look forward to hearing from
          you
        </p>
        {/* <Form noValidate validated={validated} onSubmit={submitBillingAddress}> */}
        <Form
          noValidate
          validated={validated}
          className={`${styles.formCtn} card boxShadow`}
          onSubmit={submitMessage}
        >
          <Row className="align-items-center m-0">
            <Col xs={12} sm={6}>
              <Form.Group className="mb-3" controlId="formGroupFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  value={formDetails.firstName}
                  onChange={updateFormState}
                  disabled={formLocked}
                  required
                />
                <Form.Control.Feedback type="invalid">Enter your first name.</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formGroupLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  value={formDetails.lastName}
                  onChange={updateFormState}
                  disabled={formLocked}
                  required
                />
                <Form.Control.Feedback type="invalid">Enter your last name.</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center m-0">
            <Col>
              <Form.Group className="mb-3" controlId="formGroupEmailAddress">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  name="emailAddress"
                  value={formDetails.emailAddress}
                  onChange={updateFormState}
                  disabled={formLocked}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Enter your email address.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center m-0">
            <Col xs={12}>
              <Form.Group className="mb-4" controlId="formGroupMessage">
                <Form.Label>Message:</Form.Label>
                <Form.Control
                  as="textarea"
                  name="message"
                  style={{ height: '150px' }}
                  value={formDetails.message}
                  onChange={updateFormState}
                  disabled={formLocked}
                  required
                />

                <Form.Control.Feedback type="invalid">Enter your message.</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Button type="submit" className="button mainBtn">
                Send message
              </Button>
            </Col>
            {displayMessage && (
              <Col xs={12} className="mt-4">
                <p className={styles.success}>
                  Your message was submitted, we will get back to you as soon as possible.
                </p>
              </Col>
            )}
          </Row>
        </Form>
      </div>
    </PageWrapper>
  );
};

export default Contact;
