import {
  UPDATE_USER,
  UPDATE_USER_ERROR,
  REGISTER_SUCCESS,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGISTER_FAIL,
  USER_LOADED,
  USER_GUEST,
  EMAIL_ACTIVATION,
  ACCOUNT_ACTIVATION,
  AUTH_ERROR,
} from '../actions/types';
import { LOGOUT, LOGOUT_FAIL } from '../contants/authConstants';
import {
  USER_DETAILS_REQUEST,
  USER_LAST_LOGIN_FAIL,
  USER_LAST_LOGIN_SUCCESS,
  GET_USER_IMAGE_REQUEST,
  GET_USER_IMAGE_SUCCESS,
} from '../contants/userConstants';
import {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  ACCOUNT_ACTIVATION_FAIL,
  RESET_NOTIFICATION,
  LOGIN_REQUEST,
  REGISTER_REQUEST,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_FAIL,
  RESET_MEMBERSHIP_LOADED,
  COURSES_OWNED_RELOAD,
} from '../contants/authConstants';
import { CHECK_SUBSCRIPTION, CHECK_SUBSCRIPTION_REQUEST } from '../contants/subscriptionConstants';

const initialState = {
  // token: localStorage.getItem('token'),
  token: '',
  isAuthenticated: false,
  isActive: false,
  user: null,
  imageLoading: false,
  imageLoaded: false,
  loading: true,
  isAuthLoading: true,
  notification: {},
  coursesOwned: [],
  buttonLoading: false,
  message: '',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;
  // console.log("inside auth reducers");
  // console.log( type );
  if (payload) {
    // console.log("-------------CHANGING MESSAGE----------")
    // console.log(type)
    // console.log( payload.message );
  }
  switch (type) {
    case USER_DETAILS_REQUEST:
    case LOGIN_REQUEST:
    case REGISTER_REQUEST:
    case FORGOT_PASSWORD_REQUEST:
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        isAuthLoading: true,
      };
    case COURSES_OWNED_RELOAD:
      return {
        ...state,
        coursesOwnedLoaded: false,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        user: {
          ...state.user,
          ...payload.user,
        },
        token: payload.token,
        isActive: payload.isActive,
        loading: false,
        isAuthLoading: false,
      };
    case USER_GUEST:
      return {
        ...state,
        token: '',
        isAuthenticated: false,
        user: '',
        loading: false,
        isAuthLoading: false,
      };
    case REGISTER_FAIL:
    case REGISTER_SUCCESS:
    case FORGOT_PASSWORD:
    case FORGOT_PASSWORD_FAIL:
    case RESET_PASSWORD_SUCCESS:
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        notification: payload,
        loading: false,
        isAuthLoading: false,
      };
    case RESET_NOTIFICATION: {
      return {
        ...state,
        notification: {},
      };
    }
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        token: payload.token,
        message: payload.message,
        user: payload.user,
        isActive: payload.user.isActive,
        loading: false,
        isAuthLoading: false,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        notification: payload,
        loading: false,
        isAuthLoading: false,
      };
    // case REGISTER_FAIL:
    case AUTH_ERROR:
    case LOGOUT_FAIL:
    case LOGOUT:
      return {
        ...initialState,
        loading: false,
        isAuthLoading: false,
      };
    case UPDATE_USER_ERROR:
      return {
        ...state,
        notification: payload,
      };
    case USER_LAST_LOGIN_FAIL:
      return {
        ...state,
        message: payload,
      };
    case EMAIL_ACTIVATION:
      return {
        ...state,
        notification: {
          status: payload.status,
          message: payload.message,
        },
      };
    case ACCOUNT_ACTIVATION:
    case ACCOUNT_ACTIVATION_FAIL:
      return {
        ...state,
        isActive: payload.isActive,
        message: payload.message,
      };
    // case GET_COURSES_OWNED:
    //   return {
    //     ...state,
    //     coursesOwned: payload.courses,
    //     privateCourses: payload.courses,
    //     coursesOwnedLoaded: true,
    //   };
    // case COURSE_ACCESS:
    //   return {
    //     ...state,
    //     courseAcces: payload.courses,
    //   };
    case CHECK_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        subscriptionLoaded: false,
      };
    case CHECK_SUBSCRIPTION:
      return {
        ...state,
        membership: payload,
        subscriptionLoaded: true,
      };
    case RESET_MEMBERSHIP_LOADED:
      return {
        ...state,
        subscriptionLoaded: false,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: {
          ...state.user,
          name: payload.userName || state.user.name,
        },
        notification: {
          status: payload.status,
          message: payload.message,
        },
      };
    case USER_LAST_LOGIN_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          lastLogin: payload,
        },
      };
    case GET_USER_IMAGE_REQUEST: {
      return {
        ...state,
        imageLoading: true,
        imageLoaded: false,
      };
    }
    case GET_USER_IMAGE_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          image: payload,
        },
        imageLoading: false,
        imageLoaded: true,
      };
    }
    default:
      return state;
  }
}
