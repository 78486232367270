import React from 'react';
import SecondHeader from '../partials/SecondHeader';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './Migration.module.css';

const Migration = () => {
  return (
    <>
      <SecondHeader />
      <div className={`${styles.privacyCtn} mainBackground`}>
        <Container className={`${styles.divCard} card boxShadow`}>
          <Row>
            <Col>
              <h1 className={`${styles.privacyMainTitle} mb-4`}>Migration from Teachable</h1>
              <p>
                As some of you might have noticed, I have been away from youtube for a while to
                focus on building this new Telmo Academy platform.
              </p>

              <p>
                It's now ready and live, so I'm migrating all my courses from Teachable to here.
              </p>

              <h2 className={`${styles.privacySecondTitle} mb-3`}>
                Where are my courses from the old website at Teachable?
              </h2>
              <p>
                The old website is still live at{' '}
                <span className={styles.website}>https://telmo-academy.teachable.com</span>
              </p>
              <p>
                So there you can still have access to all the courses you purchased in the past, and
                manage any active subscriptions you might have.
              </p>

              <h2 className={`${styles.privacySecondTitle} mb-3`}>
                How to transfer courses from Teachable to Telmo Academy?
              </h2>
              <p>
                To confirm your previous purchases you just need to register here, with the same
                name and email, that you used on Teachable.
              </p>
              <p>
                So to transfer any courses you purchased in the past to this new platform of Telmo
                Academy, just simply contact me through the{' '}
                <Link to="/contact" className={styles.contact}>
                  contact page
                </Link>
                , and I will add those courses to your account.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Migration;
