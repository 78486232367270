import React, { Fragment, useEffect, useState } from 'react';
import { getPublicCourses } from '../../actions/courses';
import { useDispatch, useSelector } from 'react-redux';
import { Col } from 'react-bootstrap';
import SecondHeader from '../partials/SecondHeader';
import Loader from '../utils/Loader';
import { Form } from 'react-bootstrap';
import CourseCard from './CourseCard';
import styles from './Courses.module.css';

const Courses = () => {
  const dispatch = useDispatch();

  const publicCourses = useSelector((state) => state.publicCourses);
  const { publicCoursesLoading, publicCoursesLoaded } = publicCourses;

  const [stateCourses, setStateCourses] = useState([]);

  useEffect(() => {
    document.title = 'Courses Page';
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!publicCoursesLoading && !publicCoursesLoaded) {
      dispatch(getPublicCourses());
    }

    if (!publicCoursesLoading && publicCoursesLoaded) {
      const sortedCourses = [...publicCourses.courses];

      sortedCourses.length > 0 &&
        sortedCourses.sort((a, b) => {
          return a.position - b.position;
        });

      setStateCourses(sortedCourses);
    }
  }, [publicCourses]);

  const allCourses = stateCourses.map((course, index) => {
    return (
      <CourseCard
        name={course.name}
        price={course.price?.standard?.value}
        key={index}
        tag={course.tag}
        courseLevel={course.courseLevel}
        publicAccess={true}
        freeCourse={course.freeCourse}
      />
    );
  });

  const findCourse = (e) => {
    const text = e.target.value.toLowerCase();

    const filteredCourses = publicCourses.courses.filter((course) => {
      return course.name.toLowerCase().indexOf(text) > -1;
    });

    setStateCourses(filteredCourses);
  };

  return (
    <Fragment>
      <SecondHeader />
      <div className={`${styles.mainContainer} mainBackground`}>
        <div className={`${styles.courses} my-5 container`}>
          <div className="row">
            <Col md={4} lg={3} className="offset-1 my-4 col-10 offset-md-0">
              <Form.Control
                type="text"
                placeholder="Find a course"
                onChange={findCourse}
                className="boxShadow"
                aria-label="Search for courses"
              />
            </Col>
          </div>
          <div className="row">
            {publicCoursesLoading ? (
              <Loader />
            ) : stateCourses.length < 1 ? (
              <Col xs={12} className="offset-1 offset-md-0">
                <h2 className={styles.noCoursesTitle}>No courses found</h2>
              </Col>
            ) : (
              allCourses
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Courses;
