import { GET_COURSES_OWNED_REQUEST, GET_COURSES_OWNED_SUCCESS } from '../contants/courseConstants';
import { LOGOUT, LOGOUT_FAIL } from '../contants/authConstants';

const initialState = {
  coursesOwnedLoading: false,
  coursesOwnedLoaded: false,
  courses: [],
};

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_COURSES_OWNED_REQUEST:
      return {
        ...state,
        coursesOwnedLoading: true,
        coursesOwnedLoaded: false,
      };
    case GET_COURSES_OWNED_SUCCESS:
      return {
        ...state,
        coursesOwnedLoading: false,
        coursesOwnedLoaded: true,
        courses: payload.courses,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
