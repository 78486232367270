export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST';

export const FIND_USER_REQUEST = 'FIND_USER_REQUEST';
export const FIND_USER_SUCCESS = 'FIND_USER_SUCCESS';
export const FIND_USER_FAIL = 'FIND_USER_FAIL';

export const FIND_USER_PURCHASES_REQUEST = 'FIND_USER_PURCHASES_REQUEST';
export const FIND_USER_PURCHASES_SUCCESS = 'FIND_USER_PURCHASES_SUCCESS';
export const FIND_USER_PURCHASES_FAIL = 'FIND_USER_PURCHASES_FAIL';

export const USER_LAST_LOGIN_SUCCESS = 'USER_LAST_LOGIN_SUCCESS';
export const USER_LAST_LOGIN_FAIL = 'USER_LAST_LOGIN_FAIL';

export const GET_USER_IMAGE_REQUEST = 'GET_USER_IMAGE_REQUEST';
export const GET_USER_IMAGE_SUCCESS = 'GET_USER_IMAGE_SUCCESS';
