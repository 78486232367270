import React, { Fragment, useEffect, useState } from 'react';
import SecondHeader from '../partials/SecondHeader';
import styles from './CourseLesson.module.css';
import { useParams, Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../utils/Loader';
import {
  finishLessonAction,
  getCoursesOwned,
  getPrivateCourses,
  addCommentAction,
  getAllCommentsAction,
} from '../../actions/courses';
import { membershipDetails } from '../../actions/subscription';
import moment from 'moment';

import CustomEditor from '../utils/Editor';

const CourseLessons = ({ history }) => {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const { token, isAuthLoading, isAuthenticated, user } = auth;
  // const course = useSelector((state) => state.courses);

  const role = user?.role;
  const specialAccess = user?.specialAccess;

  const progress = useSelector((state) => state.privateCourses);
  const { privateCourses, privateCoursesLoading, privateCoursesLoaded } = progress;

  const subscription = useSelector((state) => state.subscription);
  const { valid, subscriptionLoading, subscriptionLoaded } = subscription;

  const coursesOwned = useSelector((state) => state.coursesOwned);
  const { coursesOwnedLoading, coursesOwnedLoaded, courses: userCourses } = coursesOwned;

  const lessonComments = useSelector((state) => state.lessonComments);
  const { comments: lessonDbComments, commentsLoading, commentsLoaded } = lessonComments;

  const [courseState, setCourseState] = useState({});

  const [coursePurchased, setCoursePurchased] = useState(false);

  const [comments, setComments] = useState({
    blocks: [],
    time: 0,
  });

  const [commentsError, setCommentsError] = useState('');

  const [submitComment, setSubmitComment] = useState(false);

  let { courseTag, lesson } = useParams();

  lesson = parseInt(lesson);

  const totalLessons = courseState?.classes?.length;

  useEffect(() => {
    document.title = `${courseTag.charAt(0).toUpperCase() + courseTag.slice(1)} - lesson ${lesson}`;
  }, []);

  useEffect(() => {
    if (courseState?.classes?.length > 0 && lesson) {
      dispatch(getAllCommentsAction(courseTag, courseState.classes[lesson - 1]._id, token));
    }
  }, [courseState, lesson]);

  useEffect(() => {
    if (courseState?.classes?.length > 0 && !commentsLoading && !commentsLoaded) {
      dispatch(getAllCommentsAction(courseTag, courseState.classes[lesson - 1]._id, token));
    }
  }, [courseState, commentsLoading, commentsLoaded]);

  useEffect(() => {
    if (submitComment) {
      let totalWords = 0;
      comments.blocks.forEach((element) => {
        totalWords += element.data.text.length;
      });

      if (totalWords < 5) {
        setCommentsError('Your comment is too short');
      } else if (totalWords > 500) {
        setCommentsError('Your comments must be less than 500 characters');
      } else {
        dispatch(
          addCommentAction(
            comments,
            courseState._id,
            courseState.classes[lesson - 1]._id,
            courseTag,
            token
          )
        );
        setComments({ blocks: [], time: 0 });
        async function destroy() {
          await editorCore.current.clear();
        }
        destroy();
        setCommentsError('');
      }

      setSubmitComment(false);
    }
  }, [submitComment]);

  useEffect(() => {
    if (totalLessons && (lesson > totalLessons || lesson < 1)) {
      history.push('/404');
    }
  }, [lesson, courseState]);

  useEffect(() => {
    if (!isAuthLoading && isAuthenticated && !coursesOwnedLoading && !coursesOwnedLoaded) {
      dispatch(getCoursesOwned(token));
    }
  }, [auth, coursesOwned]);

  useEffect(() => {
    if (!isAuthLoading && isAuthenticated && !subscriptionLoading && !subscriptionLoaded) {
      dispatch(membershipDetails(token));
    }
  }, [auth, subscription]);

  useEffect(() => {
    if (!coursesOwnedLoading && coursesOwnedLoaded && courseState.name) {
      setCoursePurchased(userCourses.includes(courseState._id));
    }
  }, [courseState, coursesOwned]);

  useEffect(() => {
    if (privateCoursesLoaded) {
      const found =
        privateCourses &&
        privateCourses.some((course) => {
          return course.tag === courseTag;
        });

      if (found) {
        const data = privateCourses.find((course) => {
          return course.tag === courseTag;
        });

        setCourseState(data);
      } else {
        history.push('/404');
      }
    }
  }, [progress]);

  useEffect(() => {
    if (!auth.loading && auth.isAuthenticated && !privateCoursesLoading && !privateCoursesLoaded) {
      dispatch(getPrivateCourses(token));
    }
  }, [auth, progress]);

  const updateLesson = (i) => {
    const updatedLessons = [...courseState.classes];
    updatedLessons[i].loading = true;

    setCourseState({
      ...courseState,
      classes: updatedLessons,
    });

    dispatch(finishLessonAction(i, courseState._id, token, courseTag));
  };

  let classes =
    courseState.classes &&
    courseState.classes.length > 0 &&
    courseState.classes.map((theClass, i) => {
      return (
        <div className={lesson === i + 1 ? styles.lessonActiveCtn : ''} key={i}>
          <div className={styles.lesson}>
            <button
              className={styles.lessonComplete}
              disabled={theClass.loading}
              onClick={() => updateLesson(i)}
            >
              {theClass.loading ? (
                <Loader width="20px" height="20px" />
              ) : theClass.complete ? (
                <i className="fas fa-check-square complete"></i>
              ) : (
                <i className="far fa-square"></i>
              )}
            </button>
            <Link
              className={
                lesson === i + 1 ? `${styles.lessonLink} ${styles.lessonActive}` : styles.lessonLink
              }
              to={`/courses/${courseTag}/lessons/${i + 1}`}
            >
              <p>
                {i + 1} - {theClass.title}{' '}
                <span className={styles.lessonTime}>
                  {' '}
                  <span className="d-none d-sm-block">({theClass.duration} mins)</span>
                </span>
              </p>
            </Link>
          </div>
        </div>
      );
    });

  const lessonContinue = () => {
    if (lesson + 1 > totalLessons) {
      return (
        <>
          <Link to={lesson !== 1 ? `/courses/${courseTag}/lessons/${lesson - 1}` : '#'}>
            <i className="fas fa-arrow-left"></i> Previous Lesson{' '}
          </Link>
          <Link to="#" className={styles.buttonDisabled}>
            Next Lesson <i className="fas fa-arrow-right"></i>
          </Link>
        </>
      );
    } else {
      return (
        <>
          <Link
            to={lesson !== 1 ? `/courses/${courseTag}/lessons/${lesson - 1}` : '#'}
            className={lesson !== 1 ? '' : `${styles.buttonDisabled}`}
          >
            <i className="fas fa-arrow-left"></i> Previous Lesson{' '}
          </Link>
          <Link to={`/courses/${courseTag}/lessons/${lesson + 1}`}>
            Next Lesson <i className="fas fa-arrow-right"></i>
          </Link>
        </>
      );
    }
  };

  const editorCore = React.useRef(null);

  const handleInitialize = React.useCallback((instance) => {
    editorCore.current = instance;
  }, []);

  const handleSave = async () => {
    const savedData = await editorCore.current.save();

    setComments({ ...comments, blocks: [...savedData.blocks], time: savedData.time });

    setSubmitComment(true);
  };

  const allComments = lessonDbComments.map((lesson) => {
    const allWords = [];

    allWords.push(
      `<div class="card cardBorder mb-4"><h5 class="card-header"><img class="${
        styles.userAvatar
      } mr-3" src="/default.png" alt="user avatar"/>${lesson.user.name} - ${moment(
        lesson.time
      ).format('MM/DD/YYYY')}</h5><div class="card-body">`
    );
    lesson.blocks.forEach((b) => {
      allWords.push(`<p>${b.data.text}</p>`);
    });

    allWords.push(`<hr /></div></div>`);

    return allWords.join(' ');
  });

  if (
    (!isAuthLoading && !isAuthenticated) ||
    (!isAuthLoading &&
      isAuthenticated &&
      !subscriptionLoading &&
      subscriptionLoaded &&
      !valid &&
      !coursesOwnedLoading &&
      coursesOwnedLoaded &&
      courseState.name &&
      !courseState.purchased &&
      !specialAccess)
  ) {
    return <Redirect to="/" />;
  }

  return (
    <Fragment>
      <SecondHeader />
      <div className={`container-fluid ${styles.courseLesson}`}>
        <div className={`row ${styles.classesCol}`}>
          <div className={styles.courseLinksCtn}>
            <div className={styles.courseLessonCardCtn}>
              <div className={styles.courseLessonCard}>
                <h1>{courseState && courseState.name}</h1>
                <div className={styles.courseProgressBorder}>
                  <div className={styles.courseProgressCtn}>
                    <div
                      style={{
                        backgroundColor: '#537ddc',
                        width: `${
                          courseState && courseState.progress ? courseState.progress : '0'
                        }%`,
                        height: '100%',
                        transition: 'all 0.5s ease-in-out',
                      }}
                    ></div>
                  </div>
                  <p>{courseState && courseState.progress}% Complete</p>
                </div>
              </div>
            </div>
            <div className={styles.lessonsCtn}>{classes}</div>
          </div>

          <div className={`${styles.courseLinksCtn} ${styles.backup}`}></div>
          <div className={styles.courseMainVideoCtn}>
            <div className={styles.nextPrevButtonsCtn}>{lessonContinue()}</div>
            <div className={styles.currentLessonTitle}>
              <i className="fas fa-play-circle"></i>
              <p>{courseState && courseState.classes && courseState.classes[lesson - 1]?.title}</p>
            </div>

            <div className={`${styles.videoIframe} embed-responsive embed-responsive-16by9`}>
              <iframe
                className="embed-responsive-item"
                id="iframe1"
                style={{ display: 'flex' }}
                src={courseState && courseState.classes && courseState.classes[lesson - 1]?.url}
                width="1024"
                height="768"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
                title="courseClass"
              ></iframe>
            </div>
            <div className={`${styles.commentsCtn} d-none d-md-block mt-5`}>
              <div className={`${styles.commentsBtnCtn} mb-5`}>
                <h3 className={styles.commentsTitle}>Comments section:</h3>
              </div>
              <div className={`${styles.allComments} my-4`}>
                {allComments.length > 0 ? (
                  <div dangerouslySetInnerHTML={{ __html: allComments.join(' ') }}></div>
                ) : (
                  <>
                    <p>No comments</p>
                    <hr />
                  </>
                )}
              </div>

              {commentsError && (
                <p className={`${styles.commentError} py-2 px-4`}>{commentsError}</p>
              )}

              <div className={`${styles.customEditorCtn} pb-4`}>
                <h5 className="mb-4">Your comment:</h5>

                <div className={`${styles.customEditor} px-5 py-2`}>
                  {CustomEditor && (
                    <CustomEditor
                      handleInitialize={handleInitialize}
                      handleSave={handleSave}
                      blocks={comments.blocks}
                    />
                  )}
                </div>
                <div>
                  <button className="button mainBtn mt-4" onClick={handleSave}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CourseLessons;
