import React, { memo, useEffect } from 'react';
import ProfileSidebar from './ProfileSidebar';
import Profile from './Profile';
import ProfileBilling from './ProfileBilling';
import ProfileCourses from './ProfileCourses';
import SecondHeader from '../partials/SecondHeader';
import { membershipDetails } from '../../actions/subscription';
import { useDispatch, useSelector } from 'react-redux';

const ProfileCtn = ({ match, history }) => {
  // console.log(match);
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const { isActive, token, isAuthLoading, isAuthenticated } = auth;

  const subscription = useSelector((state) => state.subscription);

  const { subscriptionLoading, subscriptionLoaded } = subscription;

  useEffect(() => {
    if (!isAuthLoading && !isActive) {
      console.log('inside redirect');
      history.push('/activate');
    }
  }, [auth]);

  useEffect(() => {
    if (!isAuthLoading && isAuthenticated && token && !subscriptionLoading && !subscriptionLoaded) {
      dispatch(membershipDetails(token));
    }
  }, [isAuthLoading, isAuthenticated, subscriptionLoading, subscriptionLoaded, token]);

  return (
    <>
      <SecondHeader />
      <div className="profileCtn">
        <div className="container">
          <div className="row profileGapCol">
            <ProfileSidebar />
            {match.params.page === 'courses' ? (
              <ProfileCourses />
            ) : match.params.page === 'billing' ? (
              <ProfileBilling />
            ) : (
              <Profile />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(ProfileCtn);
