import {
  SUBSCRIPTION_DETAILS_SUCCESS,
  SUBSCRIPTION_DETAILS_REQUEST,
  SUBSCRIPTION_DETAILS_FAIL,
  PAY_SUBSCRIPTION_REQUEST,
  PAY_SUBSCRIPTION_ERROR,
  PAY_SUBSCRIPTION_FAILED,
  CANCEL_SUBSCRIPTION_REQUEST,
  RESUBSCRIBE_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_FAIL,
  SUBSCRIPTION_RESET_MESSAGE,
  PAY_SUBSCRIPTION_TOKENS_RECEIVED,
} from '../contants/subscriptionConstants';

import { LOGOUT, LOGOUT_FAIL } from '../contants/authConstants';

const initialState = {
  subscriptionLoading: false,
  subscriptionLoaded: false,
  tokens: {},
  details: {},
  valid: false,
  subscriptionError: '',
};

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SUBSCRIPTION_DETAILS_REQUEST:
      return {
        ...state,
        subscriptionLoading: true,
      };
    case SUBSCRIPTION_DETAILS_SUCCESS:
      return {
        ...state,
        details: payload.details,
        valid: payload.valid,
        subscriptionLoading: false,
        subscriptionLoaded: true,
      };
    case SUBSCRIPTION_DETAILS_FAIL:
      return {
        ...initialState,
        subscriptionLoading: false,
        subscriptionLoaded: true,
      };
    case PAY_SUBSCRIPTION_REQUEST:
      return {
        ...initialState,
        subscriptionLoading: true,
        subscriptionLoaded: false,
      };
    case PAY_SUBSCRIPTION_TOKENS_RECEIVED:
      return {
        ...state,
        tokens: payload,
      };
    case PAY_SUBSCRIPTION_FAILED:
      return {
        ...initialState,
        subscriptionLoading: false,
        subscriptionLoaded: true,
        details: {},
        valid: false,
      };
    case PAY_SUBSCRIPTION_ERROR:
      return {
        ...initialState,
        subscriptionLoading: false,
        subscriptionLoaded: true,
        details: {},
        valid: false,
        subscriptionError: payload.error,
      };
    case SUBSCRIPTION_RESET_MESSAGE:
      return {
        ...state,
        subscriptionError: '',
      };
    case CANCEL_SUBSCRIPTION_REQUEST:
    case RESUBSCRIBE_SUBSCRIPTION_REQUEST:
      return {
        ...initialState,
        subscriptionLoading: true,
        subscriptionLoaded: false,
      };
    // case CANCEL_SUBSCRIPTION_SUCCESS:
    //   return {
    //     ...initialState,
    //     subscriptionLoading: false,
    //     subscriptionLoaded: true,
    //     cancelAtPeriodEnd: true
    //   }
    case CANCEL_SUBSCRIPTION_FAIL:
      return {
        ...initialState,
        subscriptionLoading: false,
        subscriptionLoaded: true,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
