import React, { Fragment, useState, useEffect } from 'react';
import parse from 'html-react-parser';
import { useParams, Link, Redirect } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getPublicCourses, addFreeCourseToUserAction } from '../../actions/courses';
import { addCheckout, getCoursesOwned } from '../../actions/courses';
import { membershipDetails } from '../../actions/subscription';
import { loadCheckout } from '../../actions/courses';
import { RESET_CHECKOUT_LOADED } from '../../actions/types';
import Loader from '../utils/Loader';
import SecondHeader from '../partials/SecondHeader';
import styles from './Course.module.css';

const Course = ({ history }) => {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const { user, token } = auth;

  const specialAccess = user?.specialAccess;

  const publicCourses = useSelector((state) => state.publicCourses);
  const { publicCoursesLoading, publicCoursesLoaded, courses } = publicCourses;

  const coursesOwned = useSelector((state) => state.coursesOwned);
  const { coursesOwnedLoading, coursesOwnedLoaded, courses: userCourses } = coursesOwned;

  const subscription = useSelector((state) => state.subscription);
  const { valid, details, subscriptionLoading, subscriptionLoaded } = subscription;

  const subscriptionPricing = useSelector((state) => state.subscriptionPricing);

  const { yearlyBreakdownInt, yearlyBreakDownDecimal } = subscriptionPricing;

  const { courseTag } = useParams();

  const [course, setCourse] = useState({});
  const [coursePurchased, setCoursePurchased] = useState(false);
  const [addFreeCourse, setAddFreeCourse] = useState(false);

  useEffect(() => {
    document.title = `${courseTag.charAt(0).toUpperCase() + courseTag.slice(1)} course page`;
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!publicCoursesLoading && !publicCoursesLoaded) {
      dispatch(getPublicCourses());
    }

    if (!publicCoursesLoading && publicCoursesLoaded) {
      const findCourse = courses.find((course) => {
        return course.tag === courseTag;
      });

      if (findCourse === undefined) {
        // console.log('Cant find that course');
        history.push('/404');
      }
      setCourse(findCourse);
    }
  }, [publicCourses]);

  useEffect(() => {
    if (publicCoursesLoaded && coursesOwnedLoaded && course?.name) {
      setCoursePurchased(userCourses.includes(course._id));
    }
  }, [course, coursesOwned]);

  useEffect(() => {
    if (!auth.loading && auth.isAuthenticated && !subscriptionLoading && !subscriptionLoaded) {
      dispatch(membershipDetails(token));
    }
  }, [auth, subscription]);

  useEffect(() => {
    if (
      !auth.loading &&
      auth.isAuthenticated &&
      auth.token &&
      !coursesOwnedLoading &&
      !coursesOwnedLoaded
    ) {
      dispatch(getCoursesOwned(token));
    }
  }, [auth, coursesOwned]);

  const goCheckout = () => {
    const selectedCourse = {
      courseId: course._id,
      name: course.name,
      tag: course.tag,
      price: course.price?.standard?.value,
      priceId: course.price?.standard?.id,
    };

    dispatch(addCheckout(selectedCourse, token));
    // dispatch(loadCheckout(token));
    dispatch({ type: RESET_CHECKOUT_LOADED });

    history.push('/cart/checkout');
  };

  let classes =
    course &&
    course.classes &&
    course.classes.length > 0 &&
    course.classes.map((theClass, i) => {
      return (
        <div
          className={
            i % 2 === 0 ? `${styles.courseClassItems} bg-light` : `${styles.courseClassItems}`
          }
          key={i}
        >
          <div className={styles.courseClassLecture}>
            {/* <i className="far fa-file"></i> */}
            🎞️
            <span className="d-none d-sm-block"> - {i + 1} </span> {theClass.lecture}
          </div>
          <div className={styles.courseClassTitle}>
            {/* <Link to={`/courses/${course.data.tag}/lessons/1`}> */}
            {theClass.title}
            {/* </Link> */}
            <span className="d-none d-sm-block">{theClass.duration} min</span>
          </div>
        </div>
      );
    });

  const addFreeCourseToUser = () => {
    console.log('Adding course');
    setAddFreeCourse(true);

    dispatch(addFreeCourseToUserAction(course._id, course.name, token, history));
  };

  const coursePage = () => {
    return (
      <>
        <div className={styles.courseCtnHeader}>
          <h1 className={styles.coursePageTitle}>{course?.name}</h1>
          {coursePurchased || valid || specialAccess ? (
            <Link
              className={`${styles.removePadding} button mainBtn`}
              to={`/courses/${course?.tag}/lessons/1`}
            >
              <span className={styles.buyCoursePrice}>Start Learning</span>
            </Link>
          ) : null}
        </div>

        <div className={styles.courseCtnBody}>
          <div className={styles.courseIntro}>
            <iframe
              className="embed-responsive-item"
              id="iframe1"
              style={{ display: 'flex' }}
              src={course?.intro}
              width="640"
              height="360"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
              title="courseIntro"
            ></iframe>
          </div>
          <div className="row">
            <div
              className={valid && coursePurchased ? 'col-lg-12 col-sm-12' : 'col-lg-8 col-sm-12'}
            >
              <div className="card boxShadow">
                <div className="card-header">Description</div>
                <div className={`${styles.courseDescription} card-body`}>
                  {course?.description && parse(course?.description)}
                </div>
              </div>

              <div className={`${styles.curriculumCtn} card boxShadow`}>
                <div className="card-body">
                  <h4 className={styles.courseOverview}>Curriculum</h4>
                  {classes}
                </div>
              </div>
            </div>

            <div className="col-lg-4 offset-sm-2 offset-lg-0 col-sm-8">
              {!coursePurchased && course?.freeCourse && (
                <div className={`card ${styles.purchaseButtons} ${styles.membershipCardCtn}`}>
                  <div className="card-header">Add course to library</div>
                  <div className="card-body">
                    <h1>FREE</h1>
                    {/* <Link
                      className="secondBtn button width80"
                      id="membershipButton"
                      to="/subscription"
                    >
                      Get Course
                    </Link> */}
                    {auth?.isAuthenticated && auth?.isActive ? (
                      <button
                        className={`mainBtn button width80 ${styles.freeCourseBtn}`}
                        onClick={addFreeCourseToUser}
                      >
                        {addFreeCourse ? <Loader width="25px" height="25px" /> : 'Get Course'}
                      </button>
                    ) : auth?.isAuthenticated && !auth?.isActive ? (
                      <Link to="/profile" className="mainBtn button width80">
                        Get Course
                      </Link>
                    ) : (
                      <Link to="/register" className="mainBtn button width80">
                        Get Course
                      </Link>
                    )}
                  </div>
                </div>
              )}
              {subscriptionLoading && !subscriptionLoaded ? (
                <Loader />
              ) : (
                !valid && (
                  <div className={`card ${styles.purchaseButtons} ${styles.membershipCardCtn}`}>
                    <div className="card-header">Unlimited access all courses</div>
                    <div className="card-body">
                      <h1>
                        ${yearlyBreakdownInt}.{yearlyBreakDownDecimal}/month
                      </h1>
                      <Link
                        className="secondBtn button width80"
                        id="membershipButton"
                        to="/subscription"
                      >
                        Buy Membership
                      </Link>
                    </div>
                  </div>
                )
              )}

              {!coursePurchased && !course?.freeCourse && (
                <div className={`card ${styles.purchaseButtons} boxShadow`}>
                  <div className="card-header">Buy Lifetime Access:</div>
                  <div className="card-body">
                    <h1>${course?.price?.standard?.value} USD</h1>
                    <Link to="/cart/checkout"></Link>
                    {auth && auth.isAuthenticated ? (
                      <button className="mainBtn button width80" onClick={goCheckout}>
                        Buy Course
                      </button>
                    ) : (
                      <Link to="/register" className="mainBtn button width80">
                        Buy Course
                      </Link>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <Fragment>
      <SecondHeader />

      <div className={`${styles.courseCtn} mainBackground `}>
        <div className="container">
          {!publicCoursesLoading && publicCoursesLoaded ? (
            coursePage()
          ) : (
            <div className={styles.loaderCtn}>
              <Loader />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Course;
