import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCoursesComments } from '../../actions/admin';
import { Link } from 'react-router-dom';
import moment from 'moment';
import styles from './CoursesComments.module.css';

const CoursesComments = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { token } = auth;

  const admin = useSelector((state) => state.admin);
  const { coursesComments } = admin;

  useEffect(() => {
    if (token) {
      dispatch(getCoursesComments(token));
    }
  }, [token]);
  console.log(coursesComments);

  const comments = Object.values(coursesComments).map((course) => {
    return (
      <>
        <div className={`row`}>
          <div className="col-3">
            <h3>{course.name}</h3>
            <img src={`/courses/${course.tag}.jpg`} alt="" className="img-fluid" />
          </div>
          <div className="col-9">
            {course.classes.map((theClass, i) => {
              return (
                <>
                  <div className="card boxShadow mb-4">
                    <div className="card-header">
                      <Link to={`/courses/${course.tag}/lessons/${theClass.lessonNumber + 1}`}>
                        Lesson {theClass.lessonNumber + 1} - {theClass.lessonName}
                      </Link>
                    </div>
                    <div className="card-body overflow-auto" style={{ maxHeight: '500px' }}>
                      {theClass.comments.map((comment) => {
                        return (
                          <>
                            <p>
                              <span className={styles.userName}>
                                <b>{comment.userName}:</b>
                              </span>
                              <br />
                              {moment(comment.time).format('Do MMMM YYYY, h:mm:ss a')}
                            </p>
                            {comment.blocks.map((block) => {
                              return (
                                <>
                                  <p
                                    dangerouslySetInnerHTML={{ __html: block.data.text }}
                                    className="mb-0"
                                  ></p>
                                </>
                              );
                            })}

                            <hr />
                          </>
                        );
                      })}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <hr />
      </>
    );
  });

  return (
    <>
      <div className={`${styles.adminCtn}`}>{comments}</div>
    </>
  );
};

export default CoursesComments;
