import React from 'react';
import { createReactEditorJS } from 'react-editor-js';
import Header from '@editorjs/header';
import List from '@editorjs/list';

const CustomEditor = ({ blocks, handleInitialize }) => {
  const EDITOR_JS_TOOLS = {
    // header: Header,
    list: {
      class: List,
      inlineToolbar: true,
      config: {
        style: 'ordered',
        defaultStyle: 'unordered',
      },
    },
  };
  const EditorJs = createReactEditorJS();

  return (
    <EditorJs
      defaultValue={blocks}
      onInitialize={handleInitialize}
      tools={EDITOR_JS_TOOLS}
      placeholder={`Write from here...`}
    />
  );
};

export default CustomEditor;
