import React, { Fragment, useState, useEffect } from 'react';
import SecondHeader from '../partials/SecondHeader';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { register } from '../../actions/auth';
import { membershipDetails } from '../../actions/subscription';
import './Subscription.css';

const Subscription = ({ history }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const { token } = auth;

  const subscription = useSelector((state) => state.subscription);
  const { valid, details, subscriptionLoading, subscriptionLoaded } = subscription;

  const { status, cancelAtPeriodEnd } = details;

  const subscriptionPricing = useSelector((state) => state.subscriptionPricing);

  const {
    monthlyPriceInt,
    monthlyPriceDecimal,
    yearlyPriceFull,
    yearlyBreakdownInt,
    yearlyBreakDownDecimal,
  } = subscriptionPricing;

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    passwordConfirm: '',
    randNumber1: Math.floor(Math.random() * 10 + 1),
    randNumber2: Math.floor(Math.random() * 10 + 1),
    message: '',
    result: 0,
  });
  const { name, email, password, passwordConfirm, randNumber1, randNumber2, message, result } =
    formData;

  useEffect(() => {
    document.title = `Subscription choice page`;
  }, []);

  useEffect(() => {
    if (
      !auth.isAuthLoading &&
      auth.isAuthenticated &&
      !subscriptionLoading &&
      !subscriptionLoaded
    ) {
      dispatch(membershipDetails(token));
    } else if (!auth.isAuthLoading && !auth.isAuthenticated && !subscriptionLoaded) {
      dispatch(membershipDetails());
    }
  }, [auth, subscription]);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        // resetMessage();
        console.log('message deleted');
        setFormData({ ...formData, message: '' });
      }, 5000);
    }
  }, [message]);

  return (
    <Fragment>
      <SecondHeader />
      <div className="membershipCtn mainBackground">
        <div className="container">
          <div className="row">
            <div className="offset-1 col-10 offset-md-0 col-lg-5 offset-lg-1 col-md-10 offset-md-1">
              <div className="annualCtn">
                <h2 className="membershipRecommended">MOST POPULAR</h2>
                <h3 className="membershipTitle">Yearly</h3>
                <h1 className="membershipPrice">
                  <span className="membershipDollar">$</span>
                  <span className="membershipPriceValue">
                    {yearlyBreakdownInt}
                    <span>.{yearlyBreakDownDecimal}</span>
                  </span>
                  <span>/month</span>
                </h1>
                <div>
                  <span className="membershipPriceToPay">${yearlyPriceFull}</span>
                  <span className="membershipBilled">BILLED YEARLY</span>
                </div>
                <ul className="membershipFeatures">
                  <li>
                    <i className="fa fa-check"></i>Access all courses from Library
                  </li>
                  <li>
                    <i className="fa fa-check"></i>New courses every month
                  </li>
                  <li>
                    <i className="fa fa-check"></i>Cancel at any time
                  </li>

                  <li>
                    <i className="fa fa-check"></i>50% Discount on yearly
                  </li>
                </ul>
                {valid && details.status === 'active' && details.subscriptionName === 'yearly' ? (
                  <button className="membershipBuyButton membershipBtnDisable">
                    {!subscriptionLoaded ? 'Loading...' : 'Currently active'}
                  </button>
                ) : (
                  <Link
                    className={`membershipBuyButton ${
                      !subscriptionLoaded ? 'membershipBtnDisable' : ''
                    }`}
                    to={!subscriptionLoaded ? '#' : '/subscription/yearly'}
                  >
                    {!subscriptionLoaded ? 'Loading...' : 'Start Plan'}
                  </Link>
                )}
              </div>
            </div>
            <div className="offset-1 col-10 offset-md-0 col-lg-5 col-md-10 offset-md-1">
              <div className="monthlyCtn boxShadow">
                <h3 className="membershipTitle">Monthly</h3>
                <h1 className="membershipPrice">
                  <span className="membershipDollar">$</span>
                  <span className="membershipPriceValue">
                    {monthlyPriceInt}
                    <span>.{monthlyPriceDecimal}</span>
                  </span>
                  <span>/Month</span>
                </h1>

                <ul className="membershipFeatures">
                  <li>
                    <i className="fa fa-check"></i>Access all courses from Library
                  </li>
                  <li>
                    <i className="fa fa-check"></i>New courses every month
                  </li>
                  <li>
                    <i className="fa fa-check"></i>Cancel at any time
                  </li>

                  <li>
                    <i className="fa fa-times"></i>
                    <span style={{ textDecoration: 'line-through' }}>50% Discount on yearly</span>
                  </li>
                </ul>
                {valid &&
                details.status === 'active' &&
                details.subscriptionName === 'monthly' &&
                !cancelAtPeriodEnd ? (
                  <button className="membershipBuyButton membershipBtnDisable">
                    {!subscriptionLoaded ? 'Loading...' : 'Currently active'}
                  </button>
                ) : details.status === 'active' &&
                  details.subscriptionName === 'monthly' &&
                  cancelAtPeriodEnd ? (
                  <Link
                    className={`membershipBuyButton ${
                      !subscriptionLoaded ? 'membershipBtnDisable' : ''
                    }`}
                    to={!subscriptionLoaded ? '#' : '/profile/billing'}
                  >
                    {!subscriptionLoaded ? 'Loading...' : 'Restart Plan'}
                  </Link>
                ) : (
                  <Link
                    className={`membershipBuyButton ${
                      !subscriptionLoaded ? 'membershipBtnDisable' : ''
                    }`}
                    to={!subscriptionLoaded ? '#' : '/subscription/monthly'}
                  >
                    {!subscriptionLoaded ? 'Loading...' : 'Start Plan'}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Subscription;
