import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ component: Component, ...rest }) => {
  // const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { loading, isAuthenticated } = auth;

  // const progress = useSelector((state) => state.coursesProgress);

  // useEffect(() => {
  //   console.log(active);
  // }, [active]);

  return (
    <Route
      {...rest}
      // render={props => !isAuthenticated || !activev
      render={(props) =>
        !loading && isAuthenticated === null ? <Redirect to="/login" /> : <Component {...props} />
      }
    />
  );
};

export default PrivateRoute;
