import axios from 'axios';
import { GET_COUNTRY_TAX_REQUEST, GET_COUNTRY_TAX_SUCCESS } from '../contants/taxConstants';

export const getCountryTax = (token, countryCode) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COUNTRY_TAX_REQUEST,
    });

    const body = { countryCode };

    const res = await axios.post(`/api/countryTax`, body, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({
      type: GET_COUNTRY_TAX_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    // const errors = err.response.data.message;
    console.log(err);
  }
};
