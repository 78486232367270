export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const USER_GUEST = 'USER_GUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const COURSE_ACCESS = 'COURSE_ACCESS';
export const GET_COURSES = 'GET_COURSES';
export const GET_COURSES_OWNED = 'GET_COURSES_OWNED';
export const GET_ONE_COURSE = 'GET_ONE_COURSE';
export const GET_ONE_COURSE_REQUEST = 'GET_ONE_COURSE_REQUEST';
export const CREATE_COURSE = 'GET_ONE_COURSE';
export const UPDATE_COURSE = 'UPDATE_COURSE';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const EMAIL_ACTIVATION = 'EMAIL_ACTIVATION';
export const ACCOUNT_ACTIVATION = 'ACCOUNT_ACTIVATION';
export const PAY_COURSE = 'PAY_COURSE';
export const ADD_CHECKOUT = 'ADD_CHECKOUT';
export const REMOVE_CHECKOUT = 'REMOVE_CHECKOUT';
export const REQUEST_LOAD_CHECKOUT = 'REQUEST_LOAD_CHECKOUT';
export const RESET_CHECKOUT_LOADED = 'RESET_CHECKOUT_LOADED';
export const LOAD_CHECKOUT = 'LOAD_CHECKOUT';
export const GET_PAYMENT_TOKEN = 'GET_PAYMENT_TOKEN';
export const PAY_ERROR = 'PAY_ERROR';
export const RESET_PAYMENT_RESULT = 'RESET_PAYMENT_RESULT';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const GET_USER_BILLING = 'GET_USER_BILLING';
export const FINISH_LESSON_REQUEST = 'FINISH_LESSON_REQUEST';
export const FINISH_LESSON = 'FINISH_LESSON';
export const FINISH_LESSON_ERROR = 'FINISH_LESSON_ERROR';
export const GET_LESSONS_WATCHED = 'GET_LESSONS_WATCHED';
export const GET_LESSONS_WATCHED_REQUEST = 'GET_LESSONS_WATCHED_REQUEST';
export const GET_LESSONS_WATCHED_FAIL = 'GET_LESSONS_WATCHED_FAIL';
