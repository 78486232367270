import React, { Fragment, useState, useEffect } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import fetchFromAPI from '../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Row, Col, Button, Card } from 'react-bootstrap';
import styles from './Checkout.module.css';
import SecondHeader from '../partials/SecondHeader';
import {
  processPayment,
  saveCoursePaymentDetails,
  findCouponIdAction,
} from '../../actions/payments';
import { getCountryTax } from '../../actions/tax';
import { removeCheckout, loadCheckout } from '../../actions/courses';
import { applyCouponAction, getBilling, removeDeclinedCard } from '../../actions/payments';
import { GET_COUPON_BY_ID_RESET, RESET_COUPON_NOTIFICATION } from '../../contants/couponConstants';
import { RESET_CHECKOUT_SUCCESS } from '../../contants/paymentConstants';
import { GET_PRIVATE_COURSES_RESET } from '../../contants/courseConstants';
import MessageDisplay from '../utils/MessageDisplay';
import BillingAddress from './BillingAddress';
import { editBillingAddress } from '../../actions/billing';
import Loader from '../utils/Loader';
import { GoAlert } from 'react-icons/go';
import { getCoursesOwned } from '../../actions/courses';

const Membership = ({ history }) => {
  const dispatch = useDispatch();

  const payment = useSelector((state) => state.payment);
  const auth = useSelector((state) => state.auth);
  const { user, loading: authLoading, isAuthenticated, isAuthLoading, isActive, token } = auth;

  const {
    checkoutSuccess,
    coupon,
    checkoutPrice,
    checkout,
    loading,
    result,
    couponNotification,
    buttonLoading,
    couponApplied,
    checkoutLoading,
    checkoutLoaded,
    // tokens,
  } = payment;

  // const { clientSecret, invoiceId, paymentMethodId } = tokens;

  const { editing, saveAddress, local, db } = useSelector((state) => state.billing);
  const { countryCode, countryTax, countryName, countryTaxLoading, countryTaxLoaded } = useSelector(
    (state) => state.tax
  );

  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [tokens, setTokens] = useState({
    clientSecret: '',
    invoiceId: '',
    paymentMethodId: '',
    keepCard: false,
  });

  const [cards, setCards] = useState(null);
  const [paymentCard, setPaymentCard] = useState('');
  const [saveCard, setSavedCard] = useState(false);

  const [savedCardpaymentMethodId, setSavedCardpaymentMethodId] = useState('');

  const [termsAgreed, setTermsAgreed] = useState(false);
  const [termsError, setTermsError] = useState('');

  const [cardState, setCardState] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });

  const stripe = useStripe();
  const elements = useElements();

  const [paymentState, setPaymentState] = useState({
    code: '',
    checkoutBackup: [],
    checkoutSale: [],
    coursesInCheckout: [],
    finalPrice: 0,
  });

  useEffect(() => {
    document.title = `Checkout page`;

    if (checkoutSuccess) {
      dispatch({ type: RESET_CHECKOUT_SUCCESS });
    }

    if (couponNotification.status === 'fail') {
      dispatch({ type: RESET_COUPON_NOTIFICATION });
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && !countryTaxLoaded && (local.countryCode || db.countryCode)) {
      const countryCode = local.countryCode || db.countryCode;

      dispatch(getCountryTax(token, countryCode));
    }
  }, [isAuthenticated, local, db]);

  useEffect(() => {
    if (checkout) {
      const coursesInCheckout = [];
      for (let i = 0; i < checkout.length; i++) {
        coursesInCheckout.push(checkout[i].courseId);
      }

      setPaymentState({
        ...paymentState,
        checkoutBackup: [...checkout],
        coursesInCheckout: coursesInCheckout,
        finalPrice: checkoutPrice,
      });
    }
  }, [checkoutPrice, checkout]);

  useEffect(() => {
    const coursesInCheckout = [];
    if (couponIsValid() && coupon && coupon.active) {
      const checkoutCopy = [...paymentState.checkoutBackup];

      const newArray = [];

      const coursesDiscounted = [];
      for (let i = 0; i < checkoutCopy.length; i++) {
        newArray.push(checkoutCopy[i].price);
        coursesInCheckout.push(checkoutCopy[i]._id);
        for (let j = 0; j < coupon.courses.length; j++) {
          if (
            coupon.courses[j].name === 'All Courses' ||
            JSON.stringify(checkoutCopy[i]._id) === JSON.stringify(coupon.courses[j].courseId)
          ) {
            newArray[i] =
              coupon.amountType === 'percentage'
                ? newArray[i] - (newArray[i] * parseInt(coupon.amount)) / 100
                : newArray[i] - parseInt(coupon.amount);
            // course.price = 10
            coursesDiscounted.push(coupon.courses[j].courseId);
          }
        }
      }

      const finalPriceWithDiscount = newArray.reduce((total, price) => {
        return parseFloat(total) + parseFloat(price);
      }, 0);

      const couponAppliedBody = {
        code: paymentState.code,
        checkoutSale: coursesDiscounted.length > 0 ? [...newArray] : [],
        coursesInCheckout: [...coursesInCheckout],
        finalPrice: finalPriceWithDiscount,
      };

      if (!couponApplied.status) {
        dispatch(applyCouponAction(couponAppliedBody));
      }
    }
  }, [coupon]);

  useEffect(() => {
    if (!authLoading && !isAuthenticated && isAuthenticated !== null) {
      history.push('/register');
    }
  }, [auth]);

  const couponIsValid = () => {
    if (coupon && coupon.active) {
      const today = new Date();
      const couponDate = new Date(coupon.expires);
      const dateInPast = function (future, present) {
        if (future.setHours(0, 0, 0, 0) <= present.setHours(0, 0, 0, 0)) {
          return true;
        }
        return false;
      };

      return coupon.active && coupon.available > 0 && !dateInPast(couponDate, today);
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (tokens.clientSecret) {
      stripe
        .confirmCardPayment(tokens.clientSecret, {
          payment_method: tokens.paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            setError(`Payment Failed: ${result.error.message}`);
            // setProcessing(false);
            dispatch(removeDeclinedCard(tokens.paymentMethodId, savedCardpaymentMethodId, token));
          } else {
            // Successful subscription payment
            // console.log(result);

            const addressToSave = {
              firstName: local.firstName ? local.firstName : db.firstName,
              lastName: local.lastName ? local.lastName : db.lastName,
              address: local.address ? local.address : db.address,
              city: local.city ? local.city : db.city,
              addressCode: local.addressCode ? local.addressCode : db.addressCode,
              country: local.country ? local.country : db.country,
              countryCode: local.countryCode ? local.countryCode : db.countryCode,
            };

            dispatch(
              saveCoursePaymentDetails(
                tokens.invoiceId,
                addressToSave,
                saveAddress,
                tokens.paymentMethodId,
                tokens.keepCard,
                token
              )
            );

            // dispatch(
            //   membershipPayment(
            //     token,
            //     duration,
            //     addressToSave,
            //     saveAddress,
            //     paymentMethodId,
            //     subscriptionId
            //   )
            // );
          }
        });
    }
  }, [tokens]);

  useEffect(() => {
    if (checkoutSuccess) {
      // dispatch(processPayment(history, token));
      dispatch(getCoursesOwned(token));
      dispatch({
        type: GET_PRIVATE_COURSES_RESET,
      });

      dispatch(loadCheckout(token));
      dispatch(getBilling(token));
      history.push('/cart/checkout/success');
    }
  }, [checkoutSuccess]);

  const checkoutItems = paymentState.checkoutBackup.map((course, i) => {
    let price = couponApplied.checkoutSale.length > 0 && couponApplied.checkoutSale[i];
    let sale = false;
    if (
      couponApplied.checkoutSale.length > 0 &&
      paymentState.checkoutBackup[i].price != couponApplied.checkoutSale[i]
    ) {
      sale = true;
    }

    return (
      <div className={styles.courseInCheckout} key={i}>
        <div className={styles.courseImageMobile}>
          <img
            className={`${styles.courseImage} mr-4`}
            src={`/courses/${course.tag}.jpg`}
            alt={course.name}
          />
          <div className={styles.courseNameCtn}>
            <h3 className={styles.checkoutCourse}>{course.name}</h3>
            <p className="d-block d-sm-none">
              {sale ? (
                <>
                  <del>${paymentState.checkoutBackup[i].price}</del>
                  <span>${price}</span>
                </>
              ) : (
                <span>${paymentState.checkoutBackup[i].price}</span>
              )}
            </p>
          </div>
        </div>
        <div className={styles.courseDeleteCtn}>
          <span className={styles.courseItemPrice}>
            {' '}
            {sale ? (
              <>
                <del>${paymentState.checkoutBackup[i].price}</del>
                <span>${price}</span>
              </>
            ) : (
              <span>${paymentState.checkoutBackup[i].price}</span>
            )}
          </span>
          <span className={styles.courseDelete} onClick={() => refreshCheckout(course._id)}>
            <i className="fas fa-trash"></i>
          </span>
        </div>
      </div>
    );
  });

  const refreshCheckout = async (courseId) => {
    await dispatch(removeCheckout(token, courseId));
    dispatch(loadCheckout(token));
  };

  const checkCoupon = (e) => {
    e.preventDefault();
    dispatch({ type: GET_COUPON_BY_ID_RESET });
    dispatch(findCouponIdAction(paymentState.code));
  };

  useEffect(() => {
    if (!isAuthLoading && isAuthenticated && isActive && user?.stripe?.customerId) {
      const savedCards = async () => {
        try {
          const cardsList = await fetchFromAPI(
            '/api/get-payment-methods',
            {
              body: {},
            },
            token
          );

          setCards(cardsList);
        } catch (error) {
          // console.log(error);
        }
      };
      savedCards();
    }
  }, [isAuthLoading, isAuthenticated, checkoutPrice, isActive]);

  const handleCheckout = async () => {
    if (!cardState.cardNumber || !cardState.cardExpiry || !cardState.cardCvc) {
      if (!error) {
        setError('Your card details are incomplete');
      }
      return;
    } else if (!termsAgreed) {
      setTermsError('Review and accept terms & conditions before purchase');
      return;
    }

    setProcessing(true);
    let si;
    // check if user has selcted to save card
    if (saveCard) {
      // make to create a setup intent
      si = await fetchFromAPI(
        '/api/save-payment-method',
        {
          body: {},
        },
        token
      );
    }

    const addressToSave = {
      firstName: local.firstName ? local.firstName : db.firstName,
      lastName: local.lastName ? local.lastName : db.lastName,
      address: local.address ? local.address : db.address,
      city: local.city ? local.city : db.city,
      addressCode: local.addressCode ? local.addressCode : db.addressCode,
      country: local.country ? local.country : db.country,
      countryCode: local.countryCode ? local.countryCode : db.countryCode,
    };

    const paymentMethod = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: {
        address: {
          city: addressToSave.city,
          country: addressToSave.countryCode,
          line1: addressToSave.address,
          line2: '',
          postal_code: addressToSave.addressCode,
          state: '',
        },
        email: user.email,
        name: `${addressToSave.firstName} ${addressToSave.lastName}`,
      },
    });

    try {
      const payload = await fetchFromAPI(
        '/api/stripe/course-payment',
        {
          body: {
            paymentMethod: paymentMethod.paymentMethod.id,
            code: couponApplied.code,
            billingAddress: addressToSave,
          },
        },
        token
      );

      if (payload.error) {
        setError(`Payment Failed: ${payload.error.message}`);
        setProcessing(false);
      } else {
        if (saveCard && si) {
          // send the customers card details to be saved with stripe
          const savedCard = await stripe.confirmCardSetup(si.client_secret, {
            payment_method: {
              card: elements.getElement(CardNumberElement),
              billing_details: {
                address: {
                  city: addressToSave.city,
                  country: addressToSave.countryCode,
                  line1: addressToSave.address,
                  line2: '',
                  postal_code: addressToSave.addressCode,
                  state: '',
                },
                email: user.email,
                name: `${addressToSave.firstName} ${addressToSave.lastName}`,
              },
            },
          });

          setSavedCardpaymentMethodId(savedCard.setupIntent.payment_method);

          // console.log('The card saved is:');
          // console.log(cardSaved);
          setTokens({
            ...tokens,
            keepCard: true,
          });
        }
        setTokens({
          ...tokens,
          clientSecret: payload.clientSecret,
          invoiceId: payload.invoiceId,
          paymentMethodId: payload.paymentMethodId,
        });

        // dispatch(processPayment(history, token));
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const savedCardCheckout = async () => {
    if (!termsAgreed) {
      setTermsError('Review and accept terms & conditions before purchase');
      return;
    }

    setProcessing(true);

    const addressToSave = {
      firstName: local.firstName ? local.firstName : db.firstName,
      lastName: local.lastName ? local.lastName : db.lastName,
      address: local.address ? local.address : db.address,
      city: local.city ? local.city : db.city,
      addressCode: local.addressCode ? local.addressCode : db.addressCode,
      country: local.country ? local.country : db.country,
      countryCode: local.countryCode ? local.countryCode : db.countryCode,
    };

    try {
      const payload = await fetchFromAPI(
        '/api/stripe/course-payment',
        {
          body: {
            paymentMethod: paymentCard,
            code: couponApplied.code,
            billingAddress: addressToSave,
            saveAddress: saveAddress,
          },
        },
        token
      );

      if (payload.error) {
        setError(`Payment Failed: ${payload.error.message}`);
        setProcessing(false);
      } else {
        // dispatch(processPayment(history, token));
        // dispatch(processPayment(couponApplied.code, addressToSave, saveAddress, token));
        setTokens({
          clientSecret: payload.clientSecret,
          invoiceId: payload.invoiceId,
          paymentMethodId: payload.paymentMethodId,
          keepCard: true,
        });
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const cardHandleChange = (event) => {
    const { error } = event;
    setError(error ? error.message : '');
    if (processing) {
      setProcessing(false);
    }
    setCardState({
      ...cardState,
      [event.elementType]: event.complete,
    });
  };

  const cardStyle = {
    style: {
      base: {
        color: '#000',
        fontFamily: 'Roboto, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#606060',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  let cardOption;

  if (cards) {
    cardOption = cards.map((card) => {
      const {
        card: { brand, last4, exp_month, exp_year },
      } = card;
      return (
        <option key={card.id} value={card.id}>
          {`${brand}/ **** **** **** ${last4} ${exp_month}/${exp_year}`}
        </option>
      );
    });
    cardOption.unshift(
      <option key="Select a card" value="">
        Select a card
      </option>
    );
  }

  const updatePaymentCard = (e) => {
    setError(null);
    setPaymentCard(e.target.value);
  };

  const userCards = () => {
    if (cards && cards.length > 0) {
      return (
        <div className={styles.submitBtn}>
          <h3 className={styles.subTitle}>Pay with saved card</h3>

          <select className="mb-4" value={paymentCard} onChange={(e) => updatePaymentCard(e)}>
            {cardOption}
          </select>
          <h1 className={`${!paymentCard && styles.hideSavedCard} ${styles.totalPriceCard}`}>
            Billed today: ${(paymentState.finalPrice + vat()).toFixed(2)} USD
          </h1>
          <button
            type="submit"
            disabled={processing || !paymentCard}
            className={`button actionButton ${!paymentCard ? styles.hideSavedCard : ''} my-4`}
            onClick={() => savedCardCheckout()}
          >
            <i className="fa fa-shopping-cart"></i>
            Complete purchase
          </button>
        </div>
      );
    }
    return '';
  };

  function vat() {
    if (countryTaxLoaded) {
      return parseFloat((checkoutPrice * countryTax).toFixed(2));
    }
    return 0;
  }

  const resetErrorMessage = () => {
    setError('');
    setProcessing(false);
  };

  const paymentLoading = () => {
    return (
      <div className={styles.paymentLoadingOverlay}>
        <div className={styles.paymentLoadingCtn}>
          <Card>
            <Card.Header className="text-center">
              {error ? 'Payment failed' : 'Sending payment'}
            </Card.Header>
            <Card.Body className="py-4 text-center">
              {error ? (
                <GoAlert className={styles.paymentError} />
              ) : (
                <Loader width="50px" height="50px" />
              )}

              <h2 className="mt-4">{error ? 'Payment was not successful!' : 'Processing...'}</h2>
              {error ? (
                <>
                  <p className="mt-4">
                    <b>REASON:</b> {error}
                  </p>
                  <button className="button mainBtn mb-3" onClick={resetErrorMessage}>
                    Try again
                  </button>
                </>
              ) : (
                <>
                  <p className="mt-4">We are processing your payment.</p>
                  <p className="mb-3">Almost done!</p>
                </>
              )}
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  };

  const updateTerms = () => {
    setTermsAgreed(!termsAgreed);
  };

  return (
    <Fragment>
      <SecondHeader />
      {processing && paymentLoading()}
      <div className={styles.checkoutCtn}>
        <div className="container">
          <div className="row">
            {payment && payment.checkout.length > 0 ? (
              <div className="col-12 col-lg-6 my-4 order-1">
                {editing ? (
                  <div className={`${styles.billingAddressCtn} card`}>
                    <h1>Billing information:</h1>
                    <BillingAddress />
                  </div>
                ) : (
                  <div
                    className={`${styles.paymentCtn} card ${loading ? styles.paymentLoading : ''}`}
                  >
                    <span
                      className={styles.editBillBtn}
                      onClick={() => dispatch(editBillingAddress())}
                    >
                      Edit billing address
                    </span>
                    <h1>Confirm your purchase:</h1>

                    {userCards()}
                    {!paymentCard && (
                      <>
                        <div className={`${styles.stripe_card} ${styles.cardNumber}`}>
                          <h3 className={styles.subTitle}>Credit card number</h3>
                          <div className={styles.iconsWrapper}>
                            <CardNumberElement
                              className={`${styles.card_element} ${styles.paymentIconsBackground}`}
                              options={cardStyle}
                              onChange={cardHandleChange}
                              style={{ backgroundImage: `url("/payment-icons.png")` }}
                            />
                            <img
                              className={styles.paymentIcons}
                              src="/payment-icons.png"
                              alt="payment-icons"
                            />
                          </div>
                        </div>
                        <div className={styles.cardDetails}>
                          <div className={`${styles.stripe_card} ${styles.cardExpiry}`}>
                            <h3 className={styles.subTitle}>Expiration date</h3>
                            <CardExpiryElement
                              id="cardExpiry"
                              className={styles.card_element}
                              options={cardStyle}
                              onChange={cardHandleChange}
                            />
                          </div>
                          <div className={`${styles.stripe_card} ${styles.cardCvc}`}>
                            <h3 className={styles.subTitle}>CVC</h3>
                            <CardCvcElement
                              className={styles.card_element}
                              options={cardStyle}
                              onChange={cardHandleChange}
                            />
                          </div>
                        </div>
                        <div className={styles.saveCard}>
                          <label htmlFor="saveCard">Save Card</label>
                          <input
                            type="checkbox"
                            checked={saveCard}
                            id="saveCard"
                            onChange={(e) => setSavedCard(e.target.checked)}
                          />
                        </div>
                        <div className={`${styles.submitBtn} my-4`}>
                          <h1 className={`${styles.totalPriceCard}`}>
                            Billed today: ${(paymentState.finalPrice + vat()).toFixed(2)} USD
                          </h1>
                          <button
                            disabled={processing}
                            // className="button is-black nomad-btn submit"
                            className="button actionButton"
                            onClick={() => handleCheckout()}
                          >
                            <i className="fa fa-shopping-cart"></i>
                            Complete purchase
                          </button>
                        </div>
                      </>
                    )}

                    {error && <p className={styles.errorMessage}>{error}</p>}
                    {termsError && !termsAgreed && (
                      <p className={styles.errorMessage}>{termsError}</p>
                    )}
                  </div>
                )}
              </div>
            ) : null}
            <div
              className={
                payment && payment.checkout && payment.checkout.length > 0
                  ? 'col-12 col-lg-6 my-4 order-0'
                  : `offset-1 col-10 col-md-8 offset-md-2`
              }
            >
              <div className={`boxShadow ${styles.paperGray} card`}>
                <h1 className={`text-center ${styles.basketTitle}`}>Products in Basket:</h1>
                {checkoutItems?.length > 0 ? (
                  checkoutItems
                ) : (
                  <Fragment>
                    {checkoutLoading ? (
                      <>
                        <Loader width="50px" height="50px" />
                        <h3 className="text-center mt-4">Loading checkout...</h3>
                      </>
                    ) : (
                      <>
                        <h1 className="text-center">Your basket is empty</h1>
                        <Link
                          to="/courses"
                          className={`btn btn-primary mt-3 ${styles.searchCourses}`}
                        >
                          Search courses
                        </Link>
                      </>
                    )}
                  </Fragment>
                )}

                {checkoutItems?.length > 0 ? (
                  <>
                    {/* {message && <h5 className="my-4">Coupon is not valid</h5>} */}
                    <div className={`${styles.checkoutPrice} mb-4`}>
                      <p>Subtotal: ${checkoutPrice}</p>
                      {countryTax ? (
                        <p>
                          Estimated tax: ({countryTax * 100}%{' '}
                          {countryName || local?.country || db?.country}): ${vat()}
                        </p>
                      ) : (
                        ''
                      )}
                      <hr />
                      <p className={`${styles.finalPrice}`}>
                        Total:{' '}
                        {couponIsValid() &&
                        couponApplied.checkoutSale &&
                        couponApplied.checkoutSale.length > 0 ? (
                          <del>${checkoutPrice + vat()}</del>
                        ) : null}{' '}
                        $
                        {couponApplied && couponApplied.finalPrice
                          ? couponApplied.finalPrice + vat()
                          : (paymentState.finalPrice + vat()).toFixed(2)}
                      </p>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          {!checkoutLoading && checkoutLoaded && checkoutItems?.length > 0 && (
            <Row>
              <Col>
                <div className="form-check text-center mb-4">
                  <input
                    className={`form-check-input ${styles.termsCheckBox}`}
                    type="checkbox"
                    value={termsAgreed}
                    onChange={updateTerms}
                    id="agreeTerms"
                  />
                  <label
                    className={`${styles.checkoutTerms} text-center form-check-label ${
                      termsError && !termsAgreed && styles.textRed
                    }`}
                    htmlFor="agreeTerms"
                  >
                    By completing this purchase, I agree to Telmo Academy -{' '}
                    <a
                      href="/terms"
                      rel="noreferrer"
                      target="_blank"
                      className={termsError && !termsAgreed && styles.textRed}
                    >
                      Terms of Use
                    </a>{' '}
                    &{' '}
                    <a
                      href="/privacy"
                      rel="noreferrer"
                      target="_blank"
                      className={termsError && !termsAgreed && styles.textRed}
                    >
                      Privacy Policy.
                    </a>
                  </label>
                </div>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Membership;
