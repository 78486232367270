const initialState = {
  monthlyPriceFull: 14.99,
  monthlyPriceInt: 14,
  monthlyPriceDecimal: 99,
  yearlyPriceFull: 119.88,
  yearlyBreakdownInt: 9,
  yearlyBreakDownDecimal: 99,
};

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    default:
      return state;
  }
}
