export const CHECK_SUBSCRIPTION = 'CHECK_SUBSCRIPTION';
export const CHECK_SUBSCRIPTION_REQUEST = 'CHECK_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAIL = 'CANCEL_SUBSCRIPTION_FAIL';
export const RESUBSCRIBE_SUBSCRIPTION_REQUEST = 'RESUBSCRIBE_SUBSCRIPTION_REQUEST';
export const RESUBSCRIBE_SUBSCRIPTION_SUCCESS = 'RESUBSCRIBE_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST';
export const SUBSCRIPTION_DETAILS_SUCCESS = 'SUBSCRIPTION_DETAILS_SUCCESS';
export const SUBSCRIPTION_DETAILS_REQUEST = 'SUBSCRIPTION_DETAILS_REQUEST';
export const SUBSCRIPTION_DETAILS_FAIL = 'SUBSCRIPTION_DETAILS_FAIL';
export const PAY_SUBSCRIPTION_REQUEST = 'PAY_SUBSCRIPTION_REQUEST';
export const PAY_SUBSCRIPTION_TOKENS_RECEIVED = 'PAY_SUBSCRIPTION_TOKENS_RECEIVED';
export const PAY_SUBSCRIPTION_ERROR = 'PAY_SUBSCRIPTION_ERROR';
export const PAY_SUBSCRIPTION_FAILED = 'PAY_SUBSCRIPTION_FAILED';
export const SUBSCRIPTION_RESET_MESSAGE = 'SUBSCRIPTION_RESET_MESSAGE';
