import React from 'react';
import SecondHeader from './SecondHeader';
import { Link } from 'react-router-dom';

import styles from './Header.module.css';

const Header = () => {
  return (
    <header>
      <SecondHeader />
      <div className={styles.heroTitle}>
        <div className="container">
          <div className="row">
            <div className="offset-sm-2 col-sm-8">
              <h1>Empower Yourself with Web Development Skills</h1>
              <p>
                Take your career to the next level or build your own website with our comprehensive
                video courses.
              </p>
              <Link to="/courses" className={styles.actionButton}>
                VIEW COURSES
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
