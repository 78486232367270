import {
  GET_PRIVATE_COURSES_REQUEST,
  GET_PRIVATE_COURSES_SUCCESS,
  GET_PRIVATE_COURSES_RESET,
} from '../contants/courseConstants';
import { LOGOUT, LOGOUT_FAIL } from '../contants/authConstants';

const initialState = {
  privateCoursesLoading: false,
  privateCoursesLoaded: false,
  privateCourses: [],
};

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PRIVATE_COURSES_REQUEST:
      return {
        ...state,
        privateCoursesLoading: true,
        privateCoursesLoaded: false
      };
    case GET_PRIVATE_COURSES_SUCCESS:
      return {
        ...state,
        privateCoursesLoading: false,
        privateCoursesLoaded: true,
        privateCourses: payload.coursesEnrolled,
      };
    case GET_PRIVATE_COURSES_RESET:
      return {
        ...state,
        privateCoursesLoaded: false,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
