import axios from 'axios';
import {
  COURSE_ACCESS,
  GET_ONE_COURSE,
  GET_ONE_COURSE_REQUEST,
  GET_LESSONS_WATCHED,
  GET_LESSONS_WATCHED_REQUEST,
  GET_LESSONS_WATCHED_FAIL,
  GET_COURSES,
  ADD_CHECKOUT,
  REMOVE_CHECKOUT,
  REQUEST_LOAD_CHECKOUT,
  LOAD_CHECKOUT,
  UPDATE_COURSE,
  FINISH_LESSON,
  FINISH_LESSON_ERROR,
  FINISH_LESSON_REQUEST,
} from './types';
import {
  GET_PUBLIC_COURSES_REQUEST,
  GET_PUBLIC_COURSES_SUCCESS,
  GET_COURSES_OWNED_REQUEST,
  GET_COURSES_OWNED_SUCCESS,
  GET_PRIVATE_COURSES_REQUEST,
  GET_PRIVATE_COURSES_SUCCESS,
  GET_PRIVATE_COURSES_RESET,
  COURSE_LIST_REQUEST,
  DELETE_COURSE_VIDEOCLASS_FAIL,
  DELETE_COURSE_VIDEOCLASS_REQUEST,
  DELETE_COURSE_VIDEOCLASS_SUCCESS,
  SAVE_FEATURED_COURSES_FAIL,
  SAVE_FEATURED_COURSES_REQUEST,
  SAVE_FEATURED_COURSES_SUCCESS,
  CLASS_WATCHED_UPDATED_RESET,
  SUBMIT_LESSON_COMMENT_REQUEST,
  GET_ALL_LESSON_COMMENTS_REQUEST,
  GET_ALL_LESSON_COMMENTS_SUCCESS,
  GET_ALL_LESSON_COMMENTS_FAIL,
  GET_ALL_LESSON_COMMENTS_RESET,
} from '../contants/courseConstants';

export const getCourses = (courses) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_LIST_REQUEST,
    });

    const body = JSON.stringify({ courses });

    const res = await axios.post(`/api/getCourses`, body, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    dispatch({
      type: GET_COURSES,
      payload: res.data,
    });
  } catch (err) {
    // const errors = err.response.data.message;
    console.log('WE HAVE AN ERROR TRYING TO GET THE COURSES');
    console.log(err);
  }
};

export const getCoursesOwned = (token) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COURSES_OWNED_REQUEST,
    });

    const res = await axios.get(`/api/getCoursesOwned`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({
      type: GET_COURSES_OWNED_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getCourse = (token, courseTag, userId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ONE_COURSE_REQUEST,
    });

    const body = userId ? { courseTag, userId } : { courseTag };

    const res = await axios.post(`/api/getCourse`, body, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({
      type: GET_ONE_COURSE,
      payload: res.data,
    });
  } catch (err) {
    // const errors = err.response.data.message;
    console.log(err);
  }
};

export const lessonsWatchedAction = (courseTag, token) => async (dispatch) => {
  try {
    dispatch({
      type: GET_LESSONS_WATCHED_REQUEST,
    });

    const body = JSON.stringify({ courseTag });

    const res = await axios.post(`/api/getLessonsWatched`, body, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({
      type: GET_LESSONS_WATCHED,
      payload: res.data,
    });
  } catch (error) {
    console.log('GET LESSONS WATCHED FAIL');
    console.log(error);
    dispatch({
      type: GET_LESSONS_WATCHED_FAIL,
    });
  }
};

export const createCourseAction =
  (
    {
      courseName,
      courseIntro,
      courseTag,
      courseLevel,
      courseDescription,
      coursePrice,
      coursePriceId,
      classes,
    },
    token
  ) =>
  async (dispatch) => {
    try {
      const body = {
        courseName,
        courseIntro,
        courseTag,
        courseLevel,
        courseDescription,
        coursePrice,
        coursePriceId,
        classes,
      };

      const res = await axios.post(`/api/admin/createCourse`, body, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      console.log(res);
    } catch (error) {}
  };

export const addCheckout = (selectedCourse, token) => async (dispatch) => {
  try {
    const body = {
      selectedCourse,
    };

    const res = await axios.post('/api/addCheckout', body, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({
      type: ADD_CHECKOUT,
      payload: res.data.checkout,
    });
  } catch (error) {
    console.log(error);
  }
};

export const removeCheckout = (token, courseId) => async (dispatch) => {
  try {
    const body = { courseId };

    const res = await axios.post('/api/removeCheckout', body, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({
      type: REMOVE_CHECKOUT,
      payload: res.data.checkout,
    });
  } catch (error) {}
};

export const updateCourseAction =
  ({
    id,
    courseName,
    courseIntro,
    courseTag,
    courseDescription,
    freeCourse,
    coursePrice,
    classes,
    courseLevel,
  }) =>
  async (dispatch, getState) => {
    try {
      const { auth } = getState();

      const body = {
        id,
        courseName,
        courseIntro,
        courseTag,
        courseDescription,
        freeCourse,
        coursePrice,
        classes,
        courseLevel,
      };

      // console.log(classes);

      const res = await axios.post('/api/admin/updateCourse', body, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      });

      dispatch({
        type: UPDATE_COURSE,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

export const loadCheckout = (token) => async (dispatch) => {
  try {
    dispatch({
      type: REQUEST_LOAD_CHECKOUT,
    });

    const res = await axios.post(
      '/api/loadCheckout',
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch({
      type: LOAD_CHECKOUT,
      payload: res.data,
    });
  } catch (error) {}
};

export const finishLessonAction = (lesson, courseId, token, tag) => async (dispatch) => {
  try {
    // console.log('clicking lesson finished ' + lesson);

    dispatch({
      type: FINISH_LESSON_REQUEST,
      payload: lesson,
    });

    const body = { lesson, courseId, tag };
    // console.log(body);
    await axios.post('/api/finishLesson', body, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({
      type: GET_PRIVATE_COURSES_RESET,
    });
  } catch (error) {
    console.log('There is an error finishing the class');
    dispatch({
      type: FINISH_LESSON_ERROR,
    });
  }
};

export const saveFeaturedCoursesAction = (data, token) => async (dispatch) => {
  try {
    dispatch({
      type: SAVE_FEATURED_COURSES_REQUEST,
    });

    const res = await axios.post('/api/admin/saveFeaturedCourses', data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({
      type: SAVE_FEATURED_COURSES_SUCCESS,
      payload: res.data.courses,
    });
  } catch (error) {
    const errors = error.response.data;
    console.log('SAVING FAIL');
    console.log(errors);
    dispatch({
      type: SAVE_FEATURED_COURSES_FAIL,
      payload: errors.message,
    });
  }
};

export const deleteVideoClassAction = (courseId, classId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_COURSE_VIDEOCLASS_REQUEST,
    });

    const body = { courseId, classId };
    console.log('the body is:');
    console.log(body);
    const {
      auth: { token },
    } = getState();

    const res = await axios.delete('/api/admin/deleteVideoClass', body, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({
      type: DELETE_COURSE_VIDEOCLASS_SUCCESS,
      payload: res.data.course,
    });
  } catch (error) {
    const errors = error.response.data;
    console.log('SAVING FAIL');
    console.log(errors);
    dispatch({
      type: DELETE_COURSE_VIDEOCLASS_FAIL,
      payload: errors.message,
    });
  }
};

export const classWatchedUpdateReset = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CLASS_WATCHED_UPDATED_RESET,
    });
  } catch (error) {}
};

export const getPrivateCourses = (token) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_PRIVATE_COURSES_REQUEST,
    });

    const mydata = await axios.get(`/api/getPrivateCourses`, {
      headers: {
        // Accept: 'application/json',
        // 'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({
      type: GET_PRIVATE_COURSES_SUCCESS,
      payload: mydata.data,
    });
  } catch (error) {
    console.log('Error in getting private courses');
  }
};

export const getPublicCourses = () => async (dispatch) => {
  try {
    // const { courses } = getState();
    // const { coursesImages } = courses;
    dispatch({
      type: GET_PUBLIC_COURSES_REQUEST,
    });

    const mydata = await axios.get('/api/getPublicCourses');

    dispatch({
      type: GET_PUBLIC_COURSES_SUCCESS,
      payload: mydata.data,
    });
  } catch (error) {
    console.log('THE ERROR IS ENROLLED');
  }
};

export const addFreeCourseToUserAction =
  (courseId, courseName, token, history) => async (dispatch) => {
    try {
      const body = { courseId, courseName };

      const { data } = await axios.put('/api/addFreeCourseToUser', body, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      // console.log(data);

      if (data.success) {
        // console.log('we have success');
        getCoursesOwned(token);
        getPrivateCourses(token);
        history.push('/profile/courses');
      } else {
        throw new Error('Unable to get free course');
      }
    } catch (error) {
      console.log(error);
    }
  };

export const addCommentAction = (comments, courseId, lessonId, tag, token) => async (dispatch) => {
  try {
    // const { courses } = getState();
    // const { coursesImages } = courses;
    // console.log({ comments, courseId, lessonId, token });
    dispatch({
      type: SUBMIT_LESSON_COMMENT_REQUEST,
    });

    const body = { comments, courseId, lessonId, tag };
    // console.log(body);
    const res = await axios.post('/api/addCommentToLesson', body, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    // console.log(res.data);

    dispatch({
      type: GET_ALL_LESSON_COMMENTS_RESET,
    });

    // const mydata = await axios.get('/api/getPublicCourses');

    // dispatch({
    //   type: GET_PUBLIC_COURSES_SUCCESS,
    //   payload: mydata.data,
    // });
  } catch (error) {
    console.log('Error trying to add comment');
  }
};

export const getAllCommentsAction = (courseTag, lessonId, token) => async (dispatch) => {
  try {
    // const { courses } = getState();
    // const { coursesImages } = courses;
    // console.log({ lessonId, token });
    dispatch({
      type: GET_ALL_LESSON_COMMENTS_REQUEST,
    });

    // const body = { comments, courseId, lessonId, tag };
    // console.log(body);
    const res = await axios.get(`/api/getLessonComments/${courseTag}/${lessonId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // console.log(res.data);

    dispatch({
      type: GET_ALL_LESSON_COMMENTS_SUCCESS,
      payload: res.data.lessonComments,
    });

    // console.log(res.data);
  } catch (error) {
    // console.log(error.message);
    // console.log(error.response.data);
    dispatch({
      type: GET_ALL_LESSON_COMMENTS_FAIL,
    });
  }
};
