import React, { useEffect } from 'react';

import 'semantic-ui-css/semantic.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { loadUser, lastLoginAction, getUserImage } from './actions/auth';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import store from './store';
import './fontawesome/css/all.min.css';
import Home from './components/pages/Home';
import Footer from './components/pages/Footer';
import Course from './components/pages/Course';
import Courses from './components/pages/Courses';
import CourseLessons from './components/pages/CourseLessons';
import Register from './components/pages/Register';
import Login from './components/pages/Login';
import Logout from './components/pages/Logout';
import Contact from './components/pages/Contact';
import Checkout from './components/pages/Checkout';
import Subscription from './components/pages/Subscription';
import SubscriptionCheckout from './components/pages/SubscriptionCheckout';
import CheckoutSuccess from './components/pages/CheckoutSuccess';
import FGT_PASSWORD from './components/pages/ForgotPassword';
import RST_PASSWORD from './components/pages/ResetPassword';
import Activate from './components/pages/Activate';
import ActivateEmail from './components/pages/ActivateEmail';
import Admin from './components/admin/AdminCtn';
import Privacy from './components/pages/Privacy';
import Terms from './components/pages/Terms';
// import CourseCreate from './components/admin/CourseCreate';
// import CourseUpdate from './components/pages/CourseUpdate';
import ProfileCtn from './components/private/ProfileCtn';
import CookiePolicy from './components/utils/CookiePolicy';
import PrivateRoute from './components/private/PrivateRoute';
import Migration from './components/pages/Migration';
import PageNotFound from './components/pages/PageNotFound';

function App() {
  if (process.env.REACT_APP_NODE_ENV === 'production') {
    const script1 = document.createElement('script');
    const script2 = document.createElement('script');

    script1.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_MEASURE_ID}`;
    script1.async = true;

    script2.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());

    gtag('config', '${process.env.REACT_APP_GA_MEASURE_ID}');
  `;

    document.body.appendChild(script1);
    document.body.appendChild(script2);
  }

  useEffect(() => {
    store.dispatch(loadUser());
    // store.dispatch(getUserImage());
    store.dispatch(lastLoginAction());
  }, []);

  const publicKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_PUBLIC;

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/courses" component={Courses} />
          <Route exact path="/courses/:courseTag" component={Course} />
          <Route exact path="/courses/:courseTag/lessons/:lesson" component={CourseLessons} />

          <Route exact path="/register">
            <GoogleReCaptchaProvider reCaptchaKey={publicKey}>
              <Register />
            </GoogleReCaptchaProvider>
          </Route>

          <Route exact path="/login">
            <GoogleReCaptchaProvider reCaptchaKey={publicKey}>
              <Login />
            </GoogleReCaptchaProvider>
          </Route>

          <Route exact path="/contact">
            <GoogleReCaptchaProvider reCaptchaKey={publicKey}>
              <Contact />
            </GoogleReCaptchaProvider>
          </Route>

          <Route exact path="/cart/checkout" component={Checkout} />

          <Route exact path="/cart/checkout/success" component={CheckoutSuccess} />
          <Route exact path="/subscription" component={Subscription} />
          <Route exact path="/subscription/success" component={CheckoutSuccess} />
          <Route exact path="/subscription/:duration" component={SubscriptionCheckout} />

          <Route exact path="/activate" component={Activate} />
          <Route exact path="/activate/:token" component={ActivateEmail} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/terms" component={Terms} />

          <Route path="/logout" component={Logout} />

          <Route exact path="/forgotPassword">
            <GoogleReCaptchaProvider reCaptchaKey={publicKey}>
              <FGT_PASSWORD />
            </GoogleReCaptchaProvider>
          </Route>
          <Route path="/resetPassword/:token" component={RST_PASSWORD} />
          <Route exact path="/admin/:page" component={Admin} />
          <Route exact path="/admin/:page/:subPage" component={Admin} />
          <Route exact path="/admin/:page/:subPage/:courseTag" component={Admin} />
          <Route exact path="/migration" component={Migration} />
          <PrivateRoute exact path="/profile" component={ProfileCtn} />
          <PrivateRoute exact path="/profile/:page" component={ProfileCtn} />
          <Route path="*" component={PageNotFound} />
        </Switch>
        <CookiePolicy />
        <Footer />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
