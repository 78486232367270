import {
  GET_PUBLIC_COURSES_REQUEST,
  GET_PUBLIC_COURSES_SUCCESS,
} from '../contants/courseConstants';
import { LOGOUT, LOGOUT_FAIL } from '../contants/authConstants';

const initialState = {
  publicCoursesLoading: false,
  publicCoursesLoaded: false,
  courses: [],
};

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PUBLIC_COURSES_REQUEST:
      return {
        ...state,
        publicCoursesLoading: true,
      };
    case GET_PUBLIC_COURSES_SUCCESS:
      return {
        ...state,
        courses: [...payload.courses],
        publicCoursesLoading: false,
        publicCoursesLoaded: true,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
