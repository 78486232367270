import axios from 'axios';
import {
  GET_COUPON_BY_ID_FAIL,
  GET_COUPON_BY_ID_REQUEST,
  GET_COUPON_BY_ID_SUCCESS,
  SET_COUPON_SUCCESS,
} from '../contants/couponConstants';

import { GET_PRIVATE_COURSES_RESET } from '../contants/courseConstants';
import {
  // PAY_COURSE,
  PAY_ERROR,
  // GET_PAYMENT_TOKEN,
  RESET_PAYMENT_RESULT,
  GET_USER_BILLING,
} from './types';

import {
  PAY_SUBSCRIPTION_REQUEST,
  PAY_SUBSCRIPTION_ERROR,
  PAY_SUBSCRIPTION_FAILED,
  PAY_SUBSCRIPTION_TOKENS_RECEIVED,
} from '../contants/subscriptionConstants';

import { SET_CHECKOUT_SUCCESS, RESET_CHECKOUT_SUCCESS } from '../contants/paymentConstants';

import { membershipDetails } from './subscription';
import { getCoursesOwned, getPrivateCourses, loadCheckout } from './courses';

export const processPayment = (history, token) => async (dispatch) => {
  try {
    dispatch(getCoursesOwned(token));
    dispatch({
      type: GET_PRIVATE_COURSES_RESET,
    });

    dispatch({ type: SET_CHECKOUT_SUCCESS });

    dispatch(loadCheckout(token));
    dispatch(getBilling(token));
    history.push('/cart/checkout/success');
  } catch (err) {
    // const errors = err.response.data;
    dispatch({
      type: PAY_ERROR,
      payload: err,
    });
  }
};

export const membershipPayment =
  (token, duration, billingAddress, shouldSaveAddress, paymentMethod, subscriptionId) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const body = {
        membershipDuration: duration,
        billingAddress,
        shouldSaveAddress,
        paymentMethod,
        subscriptionId,
      };

      dispatch({
        type: PAY_SUBSCRIPTION_REQUEST,
      });

      console.log('before res');

      const res = await axios.post(`/api/stripe/subscription`, body, config);

      console.log(res.data);
      if (res.data.status === 'active') {
        dispatch(membershipDetails(token));
        dispatch(getPrivateCourses(token));
        dispatch(getBilling(token));
        dispatch({ type: SET_CHECKOUT_SUCCESS });
      } else {
        console.log(res.data);
        console.log('before pay subscription error');
        dispatch({
          type: PAY_SUBSCRIPTION_FAILED,
        });
      }
    } catch (error) {
      // const errors = err.response.data.message;
      console.log('inside error subscription');
      console.log(error.response.data);
      dispatch({
        type: PAY_SUBSCRIPTION_ERROR,
        payload: error.response.data,
      });
    }
  };

export const membershipPayment3d =
  (token, duration, billingAddress, paymentMethodId) => async (dispatch) => {
    try {
      const config = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const body = {
        membershipDuration: duration,
        billingAddress,
      };

      dispatch({
        type: PAY_SUBSCRIPTION_REQUEST,
      });

      console.log('before res');

      const res = await axios.post(`/api/stripe/subscription/3d`, body, config);

      console.log(res.data);

      dispatch({
        type: PAY_SUBSCRIPTION_TOKENS_RECEIVED,
        payload: {
          ...res.data,
          paymentMethodId,
        },
      });

      console.log();
    } catch (error) {
      console.log('inside error subscription');
      console.log(error.response.data);
      dispatch({
        type: PAY_SUBSCRIPTION_ERROR,
        payload: error.response.data,
      });
    }
  };

export const resetPaymentResult = () => async (dispatch) => {
  try {
    dispatch({
      type: RESET_PAYMENT_RESULT,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getBilling = (token) => async (dispatch) => {
  try {
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.post('/api/braintree/getUserBilling', {}, config);

    dispatch({
      type: GET_USER_BILLING,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const findCouponIdAction = (couponCode) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COUPON_BY_ID_REQUEST,
    });

    const { data } = await axios.get(`/api/getCouponId/${couponCode}`);

    dispatch({
      type: GET_COUPON_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    const errors = error.response.data;
    console.log(errors.message);

    dispatch({
      type: GET_COUPON_BY_ID_FAIL,
      payload: errors,
    });
  }
};

export const applyCouponAction = (couponBody) => async (dispatch) => {
  try {
    dispatch({
      type: SET_COUPON_SUCCESS,
      payload: couponBody,
    });
  } catch (error) {
    console.log(error);
  }
};

export const saveCoursePaymentDetails =
  (invoiceId, billingAddress, saveAddress, paymentMethodId, keepCard, token) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RESET_CHECKOUT_SUCCESS,
      });

      const config = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const body = {
        invoiceId,
        billingAddress,
        saveAddress,
        paymentMethodId,
        keepCard,
      };

      const res = await axios.post('/api/stripe/save-course-payment-details', body, config);

      if (res.data.success) {
        dispatch({
          type: SET_CHECKOUT_SUCCESS,
        });
      }
    } catch (error) {
      console.log('inside error subscription');
      console.log(error.response.data);
      dispatch({
        type: PAY_SUBSCRIPTION_ERROR,
        payload: error.response.data,
      });
    }
  };

export const removeDeclinedCard =
  (paymentMethodId, savedCardpaymentMethodId, token) => async (dispatch) => {
    try {
      const config = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const body = {
        paymentMethodId,
        savedCardpaymentMethodId,
      };

      const res = await axios.post('/api/stripe/remove-declined-card', body, config);
    } catch (error) {
      console.log(error.message);
    }
  };
