import {
  PAY_COURSE,
  PAY_ERROR,
  GET_PAYMENT_TOKEN,
  ADD_CHECKOUT,
  REMOVE_CHECKOUT,
  RESET_CHECKOUT_LOADED,
  REQUEST_LOAD_CHECKOUT,
  LOAD_CHECKOUT,
  RESET_PAYMENT_RESULT,
  GET_USER_BILLING,
} from '../actions/types';

import { LOGOUT, LOGOUT_FAIL } from '../contants/authConstants';
import {
  GET_COUPON_BY_ID_FAIL,
  GET_COUPON_BY_ID_REQUEST,
  GET_COUPON_BY_ID_RESET,
  GET_COUPON_BY_ID_SUCCESS,
  SET_COUPON_SUCCESS,
  RESET_COUPON_NOTIFICATION,
} from '../contants/couponConstants';
import {
  PAY_COURSE_REQUEST,
  PAY_BUTTON_LOAD_SUCCESS,
  PAY_BUTTON_LOAD_REQUEST,
  SET_CHECKOUT_SUCCESS,
  RESET_CHECKOUT_SUCCESS,
} from '../contants/paymentConstants';

const initialState = {
  checkoutSuccess: false,
  loading: false,
  paymentToken: '',
  tokens: {},
  result: '',
  checkout: [],
  checkoutPrice: 0,
  addingToCheckout: false,
  paymentComplete: false,
  billing: [],
  billingLoaded: false,
  coupon: {},
  couponApplied: {
    status: false,
    code: '',
    checkoutSale: [],
    coursesInCheckout: [],
    finalPrice: 0,
  },
  message: '',
  couponNotification: {},
  buttonLoading: false,
  checkoutLoading: false,
  checkoutLoaded: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case RESET_CHECKOUT_SUCCESS:
      return {
        ...state,
        checkoutSuccess: false,
      };
    case SET_CHECKOUT_SUCCESS:
      return {
        ...state,
        checkoutSuccess: true,
      };
    case GET_COUPON_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PAYMENT_TOKEN:
      return {
        ...state,
        addingToCheckout: false,
        paymentToken: payload.clientToken,
      };
    case PAY_COURSE:
      return {
        ...state,
        loading: false,
        result: payload.status,
        checkout: [],
      };
    case PAY_ERROR:
      return {
        ...state,
        loading: false,
        couponNotification: payload,
      };
    case RESET_COUPON_NOTIFICATION:
      return {
        ...state,
        couponNotification: {},
      };
    case ADD_CHECKOUT:
      return {
        ...state,
        addingToCheckout: true,
        checkout: payload,
      };
    case REMOVE_CHECKOUT:
      return {
        ...state,
        checkout: payload,
      };
    case REQUEST_LOAD_CHECKOUT:
      return {
        ...state,
        checkoutLoading: true,
        checkoutLoaded: false,
      };
    case LOAD_CHECKOUT:
      return {
        ...state,
        checkout: payload.checkout,
        checkoutPrice: +payload.checkoutPrice.toFixed(2),
        checkoutLoading: false,
        checkoutLoaded: true,
      };
    case RESET_CHECKOUT_LOADED: {
      return {
        ...state,
        checkoutLoaded: false,
      };
    }
    case RESET_PAYMENT_RESULT:
      return {
        ...state,
        result: '',
        checkout: [],
      };
    case GET_USER_BILLING:
      return {
        ...state,
        billing: payload.billing,
        billingLoaded: true,
      };
    case GET_COUPON_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        coupon: payload.coupon,
        couponNotification: {
          status: payload.status,
          message: payload.coupon.name,
        },
      };
    case SET_COUPON_SUCCESS:
      console.log('SET_COUPON_SUCCESS');
      console.log(payload);
      return {
        ...state,
        couponApplied: {
          status: true,
          code: payload.code,
          checkoutSale: payload.checkoutSale,
          coursesInCheckout: payload.coursesInCheckout,
          finalPrice: payload.finalPrice,
        },
      };
    case GET_COUPON_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        couponNotification: payload,
        couponApplied: {
          ...initialState.couponApplied,
        },
      };
    case GET_COUPON_BY_ID_RESET:
      return {
        ...state,
        coupon: {},
        couponApplied: {
          ...state.couponApplied,
          status: false,
        },
      };
    case PAY_COURSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PAY_BUTTON_LOAD_REQUEST:
      // case PAY_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        buttonLoading: true,
      };
    case PAY_BUTTON_LOAD_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
