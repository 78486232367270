import React from 'react';
import PageWrapper from '../utils/PageWrapper';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './PageNotFound.module.css';

const PageNotFound = () => {
  return (
    <PageWrapper>
      <Row>
        <Col className="text-center">
          <h1 className={styles.title}>Whoops! We can't find that page.</h1>
          <p>Why not instead check some of our best courses?</p>
          <Link to="/courses" className="button mainBtn">
            VIEW COURSES
          </Link>
        </Col>
      </Row>
    </PageWrapper>
  );
};

export default PageNotFound;
