import React, { useEffect, useState } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Col, Table, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getCoursesOwned } from '../../actions/courses';
import {
  checkMembership,
  cancelSubscription,
  subscriptionResubscribe,
} from '../../actions/subscription';
import { getBilling } from '../../actions/payments';
import Loader from '../utils/Loader';
import { GoAlert } from 'react-icons/go';
import styles from './ProfileBilling.module.css';

function ProfileBilling() {
  const dispatch = useDispatch();
  const history = useHistory();

  const auth = useSelector((state) => state.auth);
  const { isAuthenticated, buttonLoading, token, user } = auth;

  const specialAccess = user?.specialAccess;

  const coursesOwned = useSelector((state) => state.coursesOwned);
  const { coursesOwnedLoading, coursesOwnedLoaded } = coursesOwned;

  const payment = useSelector((state) => state.payment);
  const { billingLoaded } = payment;

  const subscription = useSelector((state) => state.subscription);
  const { valid, details, subscriptionLoaded, subscriptionLoading } = subscription;

  const [cancelling, setCancelling] = useState(false);

  useEffect(() => {
    document.title = `Profile billing page`;
  }, []);

  useEffect(() => {
    if (isAuthenticated && !billingLoaded) {
      dispatch(getBilling(token));
    }
  }, [isAuthenticated, billingLoaded]);

  useEffect(() => {
    if (isAuthenticated && !coursesOwnedLoading && !coursesOwnedLoaded) {
      dispatch(getCoursesOwned(token));
    }
  }, [token, isAuthenticated, coursesOwnedLoading, coursesOwnedLoaded]);

  const userBilling =
    payment &&
    payment.billing &&
    payment.billing.map((bill, i) => {
      const date = new Date(bill.date);
      const newDate = `${('0' + date.getDate()).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(
        -2
      )}/${date.getFullYear()}`;

      return (
        <tr key={bill._id}>
          <td>{newDate}</td>

          <td>
            {bill.productName.length > 1
              ? bill.productName.map((product, i) => (
                  <>
                    <span
                      className={`${styles.productTag} ${
                        i !== bill.productName.length - 1 ? 'mb-1' : ''
                      }`}
                    >
                      {product}
                      {i === bill.productName.length - 1 ? '' : <br />}
                    </span>
                    <br />
                    {/* {i !== bill.productName.length - 1 && <hr />} */}
                  </>
                ))
              : bill.productName}
          </td>

          <td>{bill.transactionId}</td>

          <td>${bill.price ? (bill.price + (bill.totalTax ?? 0)).toFixed(2) : 0}</td>
          <td>
            <a href={bill.hostedInvoiceUrl} target="_blank" rel="noopener noreferrer">
              {bill.hostedInvoiceUrl ? 'Download' : 'Free'}
            </a>
          </td>
        </tr>
      );
    });

  const billingDateParser = (dateToParse) => {
    const untilDate = new Date(dateToParse);
    return `${('0' + untilDate.getDate()).slice(-2)}/${('0' + (untilDate.getMonth() + 1)).slice(
      -2
    )}/${untilDate.getFullYear()}`;
  };

  const membershipStatus = () => {
    let status = '';

    if (details.status === 'active' && details.cancelAtPeriodEnd) {
      status = 'cancelled';
    } else if (details.status === 'active' && !details.cancelAtPeriodEnd) {
      status = 'active';
    } else if (details.status !== 'active') {
      status = 'not active';
    }

    return status;
  };

  const handleCancelModal = () => {
    dispatch(cancelSubscription(token));
    setCancelling(false);
  };

  const paymentLoading = () => {
    return (
      <div className={styles.paymentLoadingOverlay}>
        <div className={styles.paymentLoadingCtn}>
          <Card>
            <Card.Header className="text-center">{'Cancel membership'}</Card.Header>
            <Card.Body className="py-4 text-center">
              <GoAlert className={styles.cancelWarning} />
              <h4 className="mt-4 mb-4">Do you really want to cancel your membership?</h4>

              <button className="btn btn-danger mb-3 mr-4" onClick={handleCancelModal}>
                Cancel membership
              </button>
              <button className="btn btn-primary mb-3" onClick={() => setCancelling(false)}>
                Keep membership
              </button>
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  };

  if (!auth.loading && auth.isAuthenticated === null) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      {cancelling && paymentLoading()}
      <Col xl={9} lg={9} md={12} sm={12} className={styles.billingCtn}>
        <div className="card mb-4">
          <Card.Header as="h5" className="bold">
            Membership Details
          </Card.Header>
          <Card.Body>
            {subscriptionLoading && <Loader width="20px" height="20px" />}
            {specialAccess && (
              <Card.Text>
                <b>Membership Status:</b> Access to all courses
              </Card.Text>
            )}
            {!valid && subscriptionLoaded && !specialAccess && (
              <Card.Text>
                <b>Membership Status:</b> Not active
              </Card.Text>
            )}
            {valid && (
              <>
                <Card.Text>
                  <b>Status:</b> {membershipStatus()}
                </Card.Text>
                <Card.Text>
                  {details.status === 'active' && !details.cancelAtPeriodEnd ? (
                    <>
                      <b>Next billing date: </b>
                      {billingDateParser(details.nextBillingDate)}
                    </>
                  ) : (
                    <>
                      <b>Valid Until: </b>
                      {billingDateParser(details && details.currentPeriodEnd)}
                    </>
                  )}
                </Card.Text>
              </>
            )}
            {details.status === 'active' && !details.cancelAtPeriodEnd && (
              <>
                {buttonLoading ? (
                  <button className="btn btn-info">
                    <div className={`spinner-border ${styles.spinner}`} role="status">
                      <span className="sr-only">Processing payment...</span>
                    </div>
                    Cancelling...
                  </button>
                ) : (
                  <button
                    className="btn btn-info"
                    // onClick={() => dispatch(cancelSubscription(token))}
                    onClick={() => setCancelling(true)}
                  >
                    Cancel membership
                  </button>
                )}
              </>
            )}
            {subscriptionLoaded &&
              ((details.status === 'active' && details.cancelAtPeriodEnd) ||
                (details.status !== 'active' && !specialAccess)) && (
                <button
                  onClick={() => dispatch(subscriptionResubscribe(token, history))}
                  className="button secondBtn font-weight-bold"
                >
                  Start membership
                </button>
              )}
            {details.status === 'Failed' && (
              <Link to="/subscription">Add a new payment method</Link>
            )}
          </Card.Body>
        </div>

        <div className={`${styles.separator} my-4`}></div>

        {/* <div className="card"> */}
        {/* <Card.Header as="h5" className="bold">
          Billing History
        </Card.Header> */}
        <h2>Billing history</h2>
        <div
          className={`${userBilling && Object.keys(userBilling).length > 0 ? styles.tableCtn : ''}`}
        >
          {userBilling && Object.keys(userBilling).length > 0 ? (
            <div className={styles.userBillingCtn}>
              <Table striped hover responsive size="sm" className={styles.tableBilling}>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th className={styles.productName}>Product Name</th>
                    <th>Order Number</th>
                    <th>Sale Price</th>
                    <th>Invoice</th>
                  </tr>
                </thead>
                <tbody>{userBilling}</tbody>
              </Table>
            </div>
          ) : (
            <Card.Text>You have not purchased any course or membership.</Card.Text>
          )}
        </div>
        {/* </div> */}
      </Col>
    </>
  );
}

export default ProfileBilling;
