import React, { useEffect } from 'react';
import SecondHeader from '../partials/SecondHeader';
import { Container, Row, Col } from 'react-bootstrap';
import styles from './Privacy.module.css';

const Privacy = () => {
  useEffect(() => {
    document.title = `Privacy page`;

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SecondHeader />
      <div className={`${styles.privacyCtn} mainBackground`}>
        <Container className={`${styles.divCard} card boxShadow`}>
          <Row>
            <Col>
              <h1 className={styles.privacyMainTitle}>Privacy Policy</h1>
              <p>
                This Privacy Policy governs the manner in which the School collects, uses, maintains
                and discloses information collected from users (each, a “Student”) of the School.
                This Privacy Policy applies to the School and all Courses offered by the School.
              </p>

              <h2 className={styles.privacySecondTitle}>Personal identification information</h2>
              <p>
                We may collect personal identification information from Students in a variety of
                ways, including, but not limited to, when Students enroll in the School or a Course
                within the School, subscribe to a newsletter, and in connection with other
                activities, services, features, or resources we make available in our School.
                Students may visit the School anonymously. We will collect personal identification
                information from Students only if they voluntarily submit such information to us.
                Students can refuse to supply personal identification information but doing so may
                prevent them from engaging in certain School related activities.
              </p>

              <h2 className={styles.privacySecondTitle}>How we use collected information</h2>

              <p>
                The School may collect and use Students’ personal identification information for the
                following purposes:
              </p>
              <ul>
                <li>
                  To improve customer service Information you provide helps us respond to your
                  customer service requests and support needs more efficiently.
                </li>
                <li>
                  To personalize user experience We may use information in the aggregate to
                  understand how our Students as a group use the services and resources provided in
                  our School.
                </li>
                <li>
                  To send periodic emails We may use Student email addresses to send Students
                  information and updates pertaining to their order. Student email addresses may
                  also be used to respond to Student inquiries, questions, or other requests.
                </li>
              </ul>

              <h2 className={styles.privacySecondTitle}>Sharing your personal information</h2>
              <p>
                We do not sell, trade, or rent Student personal identificationinformation to others.
              </p>

              <h2 className={styles.privacySecondTitle}>Third party websites</h2>
              <p>
                Student may find advertising or other content in our School that link to the
                websites and services of our partners, suppliers, advertisers, sponsors, licensors
                and other third parties. We do not control the content or links that appear on these
                websites and are not responsible for the practices employed by websites linked to or
                from our School. In addition, these websites or services, including their content
                and links, may be constantly changing. These websites and services may have their
                own privacy policies and customer service policies. Browsing and interaction on any
                other website, including websites which have a link to our Student, is subject to
                that website's own terms and policies.
              </p>

              <h2 className={styles.privacySecondTitle}>What are cookies?</h2>
              <p>
                A cookie is a file containing an identifier that is sent by a web server to a web
                browser and is stored by the browser. The identifier is then set back to the server
                each time the browser requests a page from the server.
              </p>

              <p>
                Cookies can be either "persistent" or "session" cookies. A persistent cookie will be
                stored by a web browser and will remain valid until it's expiration date or when
                deleted by the user before the expiration date. A session cookie will expire at the
                end of the user session, usually when the web browser is closed.
              </p>

              <p>
                Cookies do not typically contain any information that personally identifies a user,
                but personal information that we store about you may be linked to the information
                stored in and obtained from cookies.
              </p>

              <p>
                Cookies can be used by web sites to identify and track users as they navigate
                different pages on a website. Cookies set by the website owner are called "first
                party cookies". Cookies set by parties other than the website owner are called
                "third party cookies".
              </p>

              <h2 className={styles.privacySecondTitle}>How Telmo Academy uses cookies</h2>
              <p>
                When you use and access Telmo Academy, we may place a number of cookie files in your
                web browser.
              </p>

              <p>
                We use first and third party cookies for the following purposes: to enable certain
                functions of the Service, to provide analytics, to store your preferences, to
                personalize your experience, to tailor content and information we may send or
                display to you and to deliver promotions and advertisements.
              </p>

              <p>
                We use both session and persistent cookies on the Service and we use different types
                of cookies to run the Service as described below. In addition to our own cookies, we
                also use various third-party cookies to report usage statistics of the Service,
                deliver advertisements on and through the Service, and other such purposes.
              </p>

              <h2 className={styles.privacySecondTitle}>
                What are your choices regarding cookies?
              </h2>
              <p>
                You have the right to decide whether to accept or reject cookies as well as the
                ability to delete cookies you’ve previously accepted. Please note, however, that if
                you refuse to accept cookies or delete them, you might not be able to use all of the
                features we offer, you may not be able to store your preferences, and some of our
                pages might not display properly.
              </p>

              <h2 className={styles.privacySecondTitle}>Changes to this Privacy Policy</h2>
              <p>
                The School has the discretion to update this Privacy Policy at any time. We
                encourage Students to frequently check this page for any changes. You acknowledge
                and agree that it is your responsibility to review this Privacy Policy periodically
                and become aware of modifications.
              </p>

              <h2 className={styles.privacySecondTitle}>Your acceptance of these terms</h2>
              <p>
                By enrolling in the School, you signify your acceptance of this Privacy Policy. If
                you do not agree to this Privacy Policy, please do not enroll in the School. Your
                continued enrollment in the School following the posting of changes to this Privacy
                Policy will be deemed your acceptance of those changes.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Privacy;
