import { combineReducers } from 'redux';
import auth from './auth';
import billing from './billing';
import courses from './courses';
import coursesOwned from './coursesOwned';
import payment from './payment';
import admin from './admin';
import privateCourses from './privateCourses';
import subscription from './subscription';
import publicCourses from './publicCourses';
import subscriptionPricing from './subscriptionPricing';
import tax from './tax';
import lessonComments from './lessonComments';

export default combineReducers({
  auth,
  billing,
  courses,
  coursesOwned,
  publicCourses,
  privateCourses,
  payment,
  admin,
  subscription,
  subscriptionPricing,
  tax,
  lessonComments,
});
