import React, { useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import 'react-image-crop/dist/ReactCrop.css';
import { useDispatch, useSelector } from 'react-redux';

import { getPrivateCourses } from '../../actions/courses';
import Loader from '../utils/Loader';
import CourseCard from '../pages/CourseCard';
// import './Profile.css';
import styles from './ProfileCourses.module.css';
// import axios from 'axios';

function ProfileCourses() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { token, isAuthLoading, isAuthenticated, user } = auth;

  const specialAccess = user?.specialAccess;

  const progress = useSelector((state) => state.privateCourses);
  const { privateCourses, privateCoursesLoaded, privateCoursesLoading } = progress;

  const subscription = useSelector((state) => state.subscription);
  const { valid, details, subscriptionLoading, subscriptionLoaded } = subscription;

  useEffect(() => {
    document.title = `My courses page`;
  }, []);

  useEffect(() => {
    if (!isAuthLoading && isAuthenticated && !privateCoursesLoading && !privateCoursesLoaded) {
      dispatch(getPrivateCourses(token));
    }
  }, [isAuthLoading, isAuthenticated, privateCoursesLoading, privateCoursesLoaded, privateCourses]);

  const membershipCoursesFiltered =
    privateCourses &&
    privateCourses.length > 0 &&
    privateCourses.filter((course) => {
      return !course.purchased && course.progress !== 0;
    });

  const membershipCourses =
    membershipCoursesFiltered &&
    membershipCoursesFiltered.map((course, index) => {
      return (
        <CourseCard
          name={course.name}
          key={index}
          index={0}
          tag={course.tag}
          courseLevel={'normal'}
          courseEnrolled={true}
          courseProgress={course.progress}
          membershipStatus={course.subscriptionActive}
          purchased={course.purchased}
          publicAccess={false}
          lg={4}
        />
      );
    });

  const coursesPurchasedFiltered =
    privateCourses &&
    privateCourses.length > 0 &&
    privateCourses.filter((course) => {
      return course.purchased;
    });

  const coursesPurchased =
    coursesPurchasedFiltered &&
    coursesPurchasedFiltered.map((course, index) => {
      return (
        <CourseCard
          name={course.name}
          key={index}
          index={0}
          tag={course.tag}
          courseLevel={'normal'}
          purchased={course.purchased}
          courseProgress={course.progress}
          lg={4}
        />
      );
    });

  return (
    <div className={`col-lg-9 col-md-12 ${styles.myCoursesCtn}`}>
      <Row>
        {!progress.privateCoursesLoaded ? (
          <Loader />
        ) : membershipCourses && membershipCourses.length > 0 ? (
          <>
            <Col xs={12}>
              <h2 className={styles.myCoursesTitle}>Progress from membership courses:</h2>

              {!valid && !specialAccess && (
                <div>
                  <h3 className={`${styles.myCoursesSubTitle} mb-3`}>
                    Your subscription is currently not active, resubscribe and keep learning...
                  </h3>
                  <Link to="/subscription" className="button secondBtn">
                    Start membership
                  </Link>
                </div>
              )}
            </Col>
            {membershipCourses}
            <Col xs={12}>
              <div className={`${styles.separator} my-4`}></div>
            </Col>
          </>
        ) : (
          <>
            <div className="col-12">
              <Card>
                <Card.Header as="h5" className="bold">
                  Courses from membership:
                </Card.Header>
                <Card.Body>
                  {/* <Card.Title>Special title treatment</Card.Title> */}
                  <Card.Text>
                    You are currently not enrolled in any course from the membership.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <Col xs={12}>
              <div className={`${styles.separator} my-4`}></div>
            </Col>
          </>
        )}
      </Row>

      <Row>
        {coursesPurchased && coursesPurchased.length > 0 ? (
          <>
            <Col xs={12}>
              <h2 className={styles.myCoursesTitle}>Courses purchased:</h2>
            </Col>
            {coursesPurchased}
          </>
        ) : !progress.privateCoursesLoaded ? null : (
          <Col xs={12}>
            <Card>
              <Card.Header as="h5" className="bold">
                Courses purchased:
              </Card.Header>
              <Card.Body>
                {/* <Card.Title>Special title treatment</Card.Title> */}
                <Card.Text>You have not purchased any course yet.</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
}

export default ProfileCourses;
