import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import store from '../../store';
import { useParams } from 'react-router-dom';
import { getCourse } from '../../actions/courses';
import axios from 'axios';
import { updateCourseAction, deleteVideoClassAction } from '../../actions/courses';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { stateFromHTML } from 'draft-js-import-html';
import { getPrivateCourses, getPublicCourses } from '../../actions/courses';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import './Admin.css';
import './CourseUpdate.css';

const CourseUpdate = () => {
  const dispatch = useDispatch();

  const course = useSelector((state) => state.courses);
  const { data } = course;

  const auth = useSelector((state) => state.auth);
  const { user, token, isAuthenticated } = auth;

  const { privateCourses, privateCoursesLoaded } = useSelector((state) => state.privateCourses);
  const { courses: publicCourses, publicCoursesLoaded } = useSelector(
    (state) => state.publicCourses
  );

  const [image, setImage] = useState('');
  const [userPic, setUserPic] = useState('');
  const [uploading, setUploading] = useState(false);

  const [courseState, setCourseState] = useState({
    id: '',
    courseName: '',
    courseIntro: '',
    courseTag: '',
    courseDescription: ' ',
    freeCourse: false,
    coursePrice: {
      standard: {
        id: '',
        value: 0,
      },
    },
    courseLevel: 'DEFAULT',
    classes: [
      {
        _id: '',
        lecture: '',
        title: '',
        url: '',
        duration: 0,
      },
    ],
    loaded: false,
  });

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [saveCourse, setSaveCourse] = useState(false);

  const { courseTag } = useParams();

  // useEffect(() => {
  //   getUserPic();
  // });

  // useEffect(() => {
  //   setCourseValues();
  // }, [courseState.loaded, course.loading, auth]);

  useEffect(() => {
    if (isAuthenticated && token && !privateCoursesLoaded) {
      dispatch(getPrivateCourses(token));
    }
  }, [isAuthenticated, token, privateCoursesLoaded]);

  useEffect(() => {
    if (isAuthenticated && token && !publicCoursesLoaded) {
      dispatch(getPublicCourses());
    }
  }, [isAuthenticated, token, publicCoursesLoaded]);

  useEffect(() => {
    if (privateCoursesLoaded && publicCoursesLoaded) {
      const courseDetailsPrivate = privateCourses.find((course) => {
        return courseTag === course.tag;
      });

      const courseDetailsPublic = publicCourses.find((course) => {
        return courseTag === course.tag;
      });

      setCourseState({
        id: courseDetailsPrivate?._id,
        courseName: courseDetailsPrivate?.name,
        courseIntro: courseDetailsPublic?.intro,
        courseTag: courseDetailsPrivate?.tag,
        courseDescription: courseDetailsPublic?.description,
        freeCourse: courseDetailsPublic?.freeCourse || false,
        coursePrice: {
          standard: {
            id: courseDetailsPublic?.price?.standard?.id,
            value: courseDetailsPublic?.price?.standard?.value,
          },
        },
        courseLevel: courseDetailsPublic?.courseLevel,
        classes: courseDetailsPrivate?.classes.map((course, i) => {
          return {
            _id: course._id,
            lecture: i + 1,
            title: course.title,
            url: course.url,
            duration: course.duration,
          };
        }),
        loaded: true,
      });

      console.log(courseDetailsPrivate);
    }
  }, [privateCourses, publicCourses, privateCoursesLoaded, publicCoursesLoaded]);

  useEffect(() => {
    if (courseState.courseDescription) {
      setEditorState(EditorState.createWithContent(stateFromHTML(courseState.courseDescription)));
    }
  }, [courseState.courseDescription]);

  useEffect(() => {
    if (saveCourse) {
      dispatch(updateCourseAction(courseState));
    }
  }, [saveCourse]);

  const setCourseValues = async () => {
    await dispatch(getCourse(token, courseTag, user && user._id));

    setCourseState({
      ...courseState,
      id: course && course.data && course.data._id,
      courseName: course && course.data && course.data.name,
      courseIntro: course && course.data && course.data.intro,
      courseTag: course && course.data && course.data.tag,
      courseDescription: course && course.data && course.data.description,
      coursePrice: parseInt(course && course.data && course.data.price),
      classes: course && course.data && course.data.classes,
      loaded: true,
      courseLevel: course && course.data && course.data.courseLevel,
    });
  };

  const addClass = () => {
    setCourseState({
      ...courseState,
      classes: [
        ...courseState.classes,
        {
          lecture: '',
          title: '',
          url: '',
          duration: 0,
        },
      ],
    });
  };

  const updateCourse = (e) => {
    if (e.target.name === 'coursePriceValue') {
      setCourseState({
        ...courseState,
        coursePrice: {
          standard: {
            ...courseState.coursePrice.standard,
            value: e.target.value,
          },
        },
      });
    } else if (e.target.name === 'coursePriceId') {
      setCourseState({
        ...courseState,
        coursePrice: {
          standard: {
            ...courseState.coursePrice.standard,
            id: e.target.value,
          },
        },
      });
    } else if (e.target.name === 'freeCourse') {
      setCourseState({
        ...courseState,
        freeCourse: !courseState.freeCourse,
      });
    } else {
      setCourseState({
        ...courseState,
        [e.target.name]: e.target.value,
      });
    }
  };

  const updateClass = (e) => {
    const index = e.target.parentElement.parentElement.firstChild.value;
    console.log('current index is ' + index);
    const stateRef = { ...courseState };
    console.log(e.target.value);
    if (e.target.name === ('duration' || 'lecture')) {
      stateRef.classes[index][e.target.name] = parseInt(e.target.value);
    } else {
      stateRef.classes[index][e.target.name] = e.target.value;
    }
    setCourseState(stateRef);
  };

  const allClasses =
    courseState.classes &&
    courseState.classes.length > 0 &&
    courseState.classes.map((theClass, index) => {
      return (
        <Draggable draggableId={`draggable-${index}`} key={`draggable-${index}`} index={index}>
          {(provided) => (
            <div
              key={index}
              className="card singleClassCtn"
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
            >
              <input type="hidden" value={index} />
              <div className="card-body">
                <label htmlFor="classNumber" className="col-2">
                  Class Number
                </label>
                <span>{index + 1}</span>
              </div>
              <div className="card-body">
                <label htmlFor="classTitle" className="col-2">
                  Class Title
                </label>
                <input
                  id="classTitle"
                  type="text"
                  name="title"
                  placeholder="title"
                  onChange={updateClass}
                  value={courseState.classes[index].title ? courseState.classes[index].title : ''}
                  size="50"
                />
              </div>
              <div className="card-body">
                <label htmlFor="classVideoUrl" className="col-2">
                  Class Video Url
                </label>
                <input
                  id="classVideoDuration"
                  type="text"
                  name="url"
                  placeholder="url"
                  onChange={updateClass}
                  value={courseState.classes[index].url ? courseState.classes[index].url : ''}
                  size="50"
                />
              </div>
              <div className="card-body">
                <label htmlFor="classVideoDuration" className="col-2">
                  Class Video Duration
                </label>
                <input
                  id="classVideoDuration"
                  type="number"
                  name="duration"
                  placeholder="duration"
                  onChange={updateClass}
                  value={
                    courseState.classes[index].duration ? courseState.classes[index].duration : ''
                  }
                />
              </div>
              <div className="card-body">
                <button
                  onClick={() =>
                    dispatch(deleteVideoClassAction(courseState.id, courseState.classes[index].id))
                  }
                >
                  <i className="fas fa-trash-alt"></i> Delete Video
                </button>
              </div>
            </div>
          )}
        </Draggable>
      );
    });

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    // setCourseState({
    // 	...courseState,
    // 	courseDescription: draftToHtml(convertToRaw(editorState.getCurrentContent()))
    // })
  };

  const updateDescription = () => {
    setCourseState({
      ...courseState,
      courseDescription: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
    setSaveCourse(true);
  };

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    console.log(file);
    const formData = new FormData();
    formData.append('course', file);

    setUploading(true);

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const res = await axios.post(`/api/coursePic/${data.tag}`, formData, config);
      console.log(res.data);
      setImage(res.data);
      setUploading(false);
    } catch (error) {
      console.log(error.data);
      setUploading(false);
    }
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(courseState.classes);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const updateItemsLessonNumber = items.map((item, i) => {
      item.lecture = i + 1;
      return item;
    });

    // console.log(updateItemsLessonNumber);

    setCourseState({ ...courseState, classes: [...updateItemsLessonNumber] });
  };

  console.log(courseState.courseDescription);

  return (
    <>
      <div className="adminCtn">
        <div className="container-fluid">
          <div className="row">
            <div className="col-10">
              <div>
                <h1>Update the Course</h1>

                <label>Name</label>
                <input
                  required
                  type="text"
                  name="courseName"
                  onChange={updateCourse}
                  value={courseState.loaded ? courseState.courseName : ''}
                  size="50"
                />
                <br />
                <label>Course Intro</label>
                <input
                  required
                  type="text"
                  name="courseIntro"
                  onChange={updateCourse}
                  value={courseState.loaded ? courseState.courseIntro : ''}
                  size="70"
                />
                <br />
                <label>Course Tag</label>
                <input
                  required
                  type="text"
                  name="courseTag"
                  onChange={updateCourse}
                  value={courseState.loaded ? courseState.courseTag : ''}
                  size="50"
                />
                <br />
                <label>Course Level</label>
                <select name="courseLevel" onChange={updateCourse} value={courseState.courseLevel}>
                  <option value="DEFAULT" disabled>
                    Level
                  </option>
                  <option value="beginner">Beginner</option>
                  <option value="intermediate">Intermediate</option>
                  <option value="advanced">Advanced</option>
                </select>
                <br />

                <label>Course Description</label>
                <br />

                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={onEditorStateChange}
                />

                <label style={{ marginRight: '20px' }}>Free Course</label>
                <input
                  required
                  type="checkbox"
                  name="freeCourse"
                  value={course.freeCourse}
                  checked={courseState.freeCourse ? 'checked' : false}
                  onChange={updateCourse}
                />
                <br />
                <label>Course Price</label>
                <input
                  required
                  type="number"
                  name="coursePriceValue"
                  onChange={updateCourse}
                  value={courseState?.coursePrice?.standard?.value}
                />
                <br />
                <label>Course PriceId</label>
                <input
                  required
                  type="text"
                  name="coursePriceId"
                  onChange={updateCourse}
                  value={courseState?.coursePrice?.standard?.id}
                />
                <br />
                <div className="card">
                  <div className="card-header">Course Classes</div>
                </div>

                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable
                    droppableId="coursesSequence"
                    // direction="horizontal"
                    type="column"
                  >
                    {(provided) => (
                      <div className="row" {...provided.droppableProps} ref={provided.innerRef}>
                        {allClasses}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

                <button onClick={addClass}>Add Class</button>
                <button onClick={updateDescription}>Update Course</button>
                {course && course.message ? <h1>{course.message}</h1> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseUpdate;
