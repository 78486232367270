import axios from 'axios';

const fetchFromAPI = async (endpoint, opts, token) => {
  const { method, body } = { method: 'POST', body: null, ...opts };

  try {
    const res = await axios({
      method,
      url: endpoint,
      data: {
        ...body,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
  } catch (error) {
    console.log(error);
    console.log(error.response);
    const errorMessage = error.response.data.error;
    console.log(errorMessage);

    throw new Error(errorMessage);
  }
};

export default fetchFromAPI;
