import React, { useEffect } from 'react';
import SecondHeader from '../partials/SecondHeader';
import { Container, Row, Col } from 'react-bootstrap';
import styles from './Terms.module.css';

const Terms = () => {
  useEffect(() => {
    document.title = `Terms and conditions page`;

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SecondHeader />
      <div className={`${styles.privacyCtn} mainBackground`}>
        <Container className={`${styles.divCard} card boxShadow`}>
          <Row>
            <Col>
              <h1 className={styles.privacyMainTitle}>Terms of Use</h1>
              <p>
                These terms and conditions ("Terms of use") govern your use of this website
                telmoacademy.com ("Website"). In these Terms of use, Telmo Academy is referred to as
                the "School", "Company", "Telmo Academy", "us", "our" or "we."
              </p>
              <p>
                By accessing this School, you are agreeing to be bound by these Terms of Use, all
                applicable laws and regulations, and agree that you are responsible for compliance
                with any applicable local laws. If you do not agree with any of these terms, you are
                prohibited from using or accessing this site. The materials contained in this School
                are protected by applicable copyright and trademark law.
              </p>
              <h2 className={styles.privacySecondTitle}>Use License</h2>
              <p>
                Permission is granted to temporarily download one copy of any downloadable materials
                on the School’s website for personal, non-commercial transitory viewing only. This
                is the grant of a license, not a transfer of title, and under this license you may
                not:
              </p>
              <ul>
                <li>modify or copy the materials</li>
                <li>
                  use the materials for any commercial purpose, or for any public display
                  (commercial or non-commercial)
                </li>
                <li>
                  attempt to decompile or reverse engineer any software contained on the School’s
                  web site
                </li>
                <li>remove any copyright or other proprietary notations from the materials or</li>
                <li>
                  transfer the materials to another person or 'mirror' the materials on any other
                  server.
                </li>
              </ul>
              <p>
                This license shall automatically terminate if you violate any of these restrictions
                and may be terminated by Company at any time. Upon terminating your viewing of these
                materials or upon the termination of this license, you must destroy any downloaded
                materials in your possession whether in electronic or printed format.
              </p>
              <h2 className={styles.privacySecondTitle}>Disclaimer</h2>
              <p>
                The materials on the School’s website are provided 'as is'. The School makes no
                warranties, expressed or implied, and hereby disclaims and negates all other
                warranties, including without limitation, implied warranties or conditions of
                merchantability, fitness for a particular purpose, or non-infringement of
                intellectual property or other violation of rights. Further, the School does not
                warrant or make any representations concerning the accuracy, likely results, or
                reliability of the use of the materials on its website or otherwise relating to such
                materials or on any sites linked to this site.
              </p>
              <h2 className={styles.privacySecondTitle}>Limitations</h2>
              <p>
                In no event shall the School be liable for any damages (including, without
                limitation, damages for loss of data or profit, or due to business interruption)
                arising out of the use or inability to use the materials on the School’s website,
                even if the School or an authorized of the School has been notified orally or in
                writing of the possibility of such damage. Because some jurisdictions do not allow
                limitations on implied warranties, or limitations of liability for consequential or
                incidental damages, these limitations may not apply to you.
              </p>
              <h2 className={styles.privacySecondTitle}>Subscriptions</h2>
              <p>
                Our subscriptions will renew and charge your card automatically every month or year,
                depending on the plan you have selected. It is the entire responsibility of each
                user to cancel the subscription or contact us to do so, to avoid future charges.
                When you cancel your subscription, you will still have access to all the content,
                until the end of the billing period. We do not offer any refunds so please manage
                your subscription carefully and responsibly.
              </p>
              <h2 className={styles.privacySecondTitle}>Revisions and Errata</h2>
              <p>
                The materials appearing on the School’s website may include technical,
                typographical, or photographic errors. The School does not warrant that any of the
                materials on its web site are accurate, complete, or current. The School may make
                changes to the materials contained on its web site at any time without notice. The
                School does not, however, make any commitment to update the materials.
              </p>
              <h2 className={styles.privacySecondTitle}>Registration</h2>
              <p>
                In order to use Telmo Academy full services, you will need to register and activate
                your account.
              </p>
              <p>
                If you choose to enroll in one of the Membership plans of Telmo Academy, your
                Membership is subject to Telmo Academy's subscription charges set out in your
                subscription plan. If you wish to change your subscription plan, at any time, please
                notify us and we will update your membership package, which will take effect at the
                end of your current commitment period (which may be either a yearly or monthly).
              </p>

              <p>
                When registering, you must provide true, accurate, current and complete information
                about yourself. You are responsible for making sure this information is updated so
                that it is true, accurate, current and complete. If any information provided by you
                is not true, accurate, current and complete, Telmo Academy has the right to cancel
                your membership and refuse any and all current or future use of this Website.
              </p>
              <p>
                Registration requires a valid email address and unique password. To create a secure
                password, choose a password that uses a combination of letters, upper case and lower
                case, numbers, and symbols. For example, $Mycar007!. Avoid choosing obvious words or
                dates such as a nickname or your birth date. You are responsible for keeping your
                username and password confidential and secure. We strongly recommend that you
                log-off from the Website when your session is complete to prevent unauthorized
                access to your information.
              </p>

              <p>
                You must not share your user name or password. If you believe there has been any
                unauthorized use of username or password, this must be reported to
                support@telmoacademy.com as soon as you are aware of such use and Telmo Academy
                will, as soon as reasonably possible, cancel your user name and/or password. We will
                work with you to have new credentials (username and password) issued to you.
              </p>
              <p>
                Telmo Academy may, for security or other similar reasons, require that you provide
                information to us confirming your identity. In addition, we may also require that
                you change your user name and/ or password or other information which facilitates
                access to this Website or its Services.
              </p>
              <p>
                Telmo Academy reserves the right to cancel your Membership access without notice if
                we become aware of any breach of these Terms of Service by you.
              </p>
              <p>
                Telmo Academy will send you a confirmation e-mail, following registration and this
                will confirm your account activation, allowing you to access the Services available
                via the Website. If your account has not been e-mail confirmed and not activated 3
                months (90 days) after your registration, we reserve the right to delete your
                account.
              </p>

              <h2 className={styles.privacySecondTitle}>Links</h2>
              <p>
                The School has not reviewed all of the sites linked to its website and is not
                responsible for the contents of any such linked site. The inclusion of any link does
                not imply endorsement by the School of the site. Use of any such linked website is
                at the user's own risk.
              </p>
              <h2 className={styles.privacySecondTitle}>Refunds</h2>
              <p>
                We currently DO NOT offer refunds for any purchases of our courses or monthly and
                yearly subscriptions / memberships.
              </p>
              <p>
                We recommend enrolling in one of our memberships which should give you acccess to
                the majority or even all the courses, this way you can check their content, before
                making a final purchase.
              </p>
              <h2 className={styles.privacySecondTitle}>Site Terms of Use Modifications</h2>
              <p>
                The School may revise these Terms of Use for its website at any time without notice.
                By using this website you are agreeing to be bound by the then current version of
                these Terms of Use.
              </p>
              <h2 className={styles.privacySecondTitle}>Intellectual Property</h2>
              <p>
                You acknowledge and agree that all Content provided on the Website or through the
                Services, contained in sponsor advertisements or, presented to you by Telmo Academy,
                its partners or advertisers, is protected by copyrights, trademarks, service marks,
                patents, or other proprietary rights and laws. You shall abide by all copyright
                notices, trademark rules, information, and restrictions contained in any Content
                accessed through the Services, and except as expressly permitted herein, shall not
                use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute,
                perform, upload, display, license, sell or otherwise exploit for any purposes
                whatsoever any Content or third party submissions or other proprietary rights not
                owned by you: (i) without the express prior written consent of the respective
                owners, and (ii) in any way that violates any third party right. You acknowledge and
                agree that you are permitted to review, print and make one copy, for your personal
                use, of the Content (and other items displayed on the Website for download),
                provided that you maintain all copyright and other notices contained in such
                Content. You shall not store any significant Content in any form. Telmo Academy does
                not claim ownership of any Content that you post on the Website or through the
                Services. Instead, you hereby grant Telmo Academy a non-exclusive, fully paid and
                royalty-free, transferable, sub-licensable, perpetual, worldwide license to use the
                Content that you post on the Website or through the Services, subject to our Privacy
                Policy which can be found at https://telmoacademy.com/privacy.
              </p>
              <h2 className={styles.privacySecondTitle}>Termination</h2>
              <p>
                Without prejudice to any remedy that Telmo Academy may have against you, we may
                terminate or suspend with immediate effect and without notice, your access to and
                use of this Website and your Membership if: We do not receive timely payment under
                your subscription; We reasonably believe that you have breached any of these Terms
                of use; We are unable to verify the accuracy or validity of any information provided
                by you; Or we suspect fraudulent, abusive or illegal activity by you. Should you
                object to any of these Terms of use, or any subsequent changes to them, or become
                dissatisfied with this Website in any way, your only recourse is to immediately
                discontinue to access or use this Website. You may notify us of your wish to cancel
                your Membership at any time at support@telmoacademy.com, but your cancellation will
                not take effect until the end of your current commitment period (which may be either
                yearly or monthly). You will not be entitled to a refund for any fees already paid.
                You may notify us of your wish to reduce the number of additional users attached to
                your account or to downgrade to a different package at any time at
                support@telmoacademy.com, but such reduction will not take effect until the end of
                your current commitment period (which may be either yearly or monthly).
              </p>
              <h2 className={styles.privacySecondTitle}>Governing Law</h2>
              <p>
                Any claim relating to Telmo Academy's website shall be governed by the laws of the
                School home jurisdiction without regard to its conflict of law provisions.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Terms;
