import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { allUsersAction } from '../../actions/admin';
import { saveUsersAction, deleteUsersAction } from '../../actions/admin';
// import ModalWindow from '../utils/ModalWindow';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Form, Col } from 'react-bootstrap';
import './AllUsers.css';

function AllUsers() {
  const dispatch = useDispatch();

  const admin = useSelector((state) => state.admin);
  const { users, loading } = admin;

  const auth = useSelector((state) => state.auth);
  const { token } = auth;

  // We start with an empty list of items.
  const [usersState, setCurrentItems] = useState([]);
  const [filteredUsersState, setFilteredUsersState] = useState([]);

  const [pageCount, setPageCount] = useState(0);
  const [userSelected, setUserSelected] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [show, setShow] = useState(false);

  const [orderByState, setOrderByState] = useState(false);

  const [orderState, setOrderState] = useState({
    orderName: '',
    asc: false,
  });

  const [modalText, setModalText] = useState({
    title: '',
    action: '',
    users: [],
  });

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 4;

  useEffect(() => {
    if (token) {
      dispatch(allUsersAction(token));
    }
  }, [token]);

  // useEffect(() => {
  //   const removeAdminFromUsers = users.filter((user) => {
  //     return user.role !== 'admin';
  //   });
  //   console.log('------------ setting current items ---------');
  //   setCurrentItems(removeAdminFromUsers);
  // }, [loading, users]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredUsersState.length;
    // console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
  };

  const allUsers = usersState.map((user, i) => {
    if (user.role !== 'admin') {
      const today = new Date();
      const joinedDate = new Date(user.joined);
      const newJoinedDate = `${('0' + joinedDate.getDate()).slice(-2)}/${(
        '0' +
        (joinedDate.getMonth() + 1)
      ).slice(-2)}/${joinedDate.getFullYear()}`;
      // console.log("Inside all Users");
      // console.log(user.selected)
      const lastLogin = new Date(user.lastLogin);
      const lastLoginDate = () => {
        if (
          today.getDate() === lastLogin.getDate() &&
          today.getMonth() === lastLogin.getMonth() &&
          today.getFullYear() === lastLogin.getFullYear()
        ) {
          return `${('0' + lastLogin.getHours()).slice(-2)}:${('0' + lastLogin.getMinutes()).slice(
            -2
          )} - Today`;
        } else {
          return `${('0' + lastLogin.getHours()).slice(-2)}:${('0' + lastLogin.getMinutes()).slice(
            -2
          )} - ${lastLogin.getDate()}/${('0' + lastLogin.getMonth()).slice(
            -2
          )}/${lastLogin.getFullYear()}`;
        }
      };
      return (
        <tr key={user._id}>
          <td>
            <input
              type="checkbox"
              checked={user.selected == null ? false : user.selected}
              value={user.selected}
              onChange={(e) => {
                selectUsers(i, e);
              }}
            />
            <div className="allUsersTableDiv">
              <Link to={`/admin/user/${user._id}`}>{user.name}</Link>
            </div>
          </td>
          <td>
            <div className="allUsersTableDiv">{user.email}</div>
          </td>
          <td>
            <div className="allUsersTableDiv">{user.active}</div>
          </td>
          <td>
            <div className="allUsersTableDiv">${user.purchases} USD</div>
          </td>
          <td>
            <div className="allUsersTableDiv">{newJoinedDate}</div>
          </td>
          <td>
            <div className="allUsersTableDiv">{lastLoginDate()}</div>
          </td>
        </tr>
      );
    }
  });

  // useEffect(() => {
  //   console.log('--------- setting allUsers -----------');
  //   console.log(allUsers);
  //   setCurrentItems(allUsers);
  // }, [users, loading]);

  useEffect(() => {
    // Fetch items from another resources.
    // console.log('++++++++++ setting current items +++++++++++');
    const offset = 0;
    const endOffset = offset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const removeAdminFromUsers = users.filter((user) => {
      return user.role !== 'admin';
    });
    // console.log('------------ setting current items ---------');
    // setCurrentItems(removeAdminFromUsers);

    setCurrentItems(removeAdminFromUsers.slice(offset, endOffset));
    setFilteredUsersState(removeAdminFromUsers);
    // console.log('setting pagesz count');
    // console.log(Math.ceil(users.length / itemsPerPage));
    // console.log('users lenght is: ', users.length);
    // console.log(itemsPerPage);
    // console.log('------');
    setPageCount(Math.ceil(removeAdminFromUsers.length / itemsPerPage));
  }, [users]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(filteredUsersState.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredUsersState.length / itemsPerPage));
  }, [itemOffset]);

  const orderBy = (order) => {
    // console.log('ordering by date');

    const usersToSort = [...filteredUsersState];

    // const removeAdminFromUsers = usersToSort.filter((user) => {
    //   return user.role !== 'admin';
    // });

    usersToSort.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      if (order === 'date') {
        if (orderState.orderName === 'date' && orderState.asc) {
          return new Date(a.joined) - new Date(b.joined);
        } else {
          return new Date(b.joined) - new Date(a.joined);
        }
      } else if (order === 'purchases') {
        if (orderState.orderName === 'purchases' && orderState.asc) {
          return a.purchases - b.purchases;
        } else {
          return b.purchases - a.purchases;
        }
      } else if (order === 'email') {
        if (orderState.orderName === 'email' && orderState.asc) {
          if (a.email > b.email) {
            return -1;
          }
          if (a.email < b.email) {
            return 1;
          }
        } else {
          if (a.email < b.email) {
            return -1;
          }
          if (a.email > b.email) {
            return 1;
          }
        }
        return 0;
      } else if (order === 'name') {
        let nameA = a.name.toLowerCase();
        let nameB = b.name.toLowerCase();

        if (orderState.orderName === 'name' && orderState.asc) {
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }
        } else {
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        }

        return 0;
      } else if (order === 'active') {
        let activeA = a.active.toLowerCase();
        let activeB = b.active.toLowerCase();

        if (orderState.orderName === 'active' && orderState.asc) {
          if (activeA < activeB) {
            return -1;
          }
          if (activeA > activeB) {
            return 1;
          }
        } else {
          if (activeA > activeB) {
            return -1;
          }
          if (activeA < activeB) {
            return 1;
          }
        }
        return 0;
      } else if (order === 'lastLogin') {
        if (orderState.lastLogin === 'date' && orderState.asc) {
          return new Date(a.lastLogin) - new Date(b.lastLogin);
        } else {
          return new Date(b.lastLogin) - new Date(a.lastLogin);
        }
      }
      return 0;
    });

    setOrderByState(true);
    setCurrentItems(usersToSort.slice(0, itemsPerPage));
    setFilteredUsersState(usersToSort);

    setOrderState({
      orderName: order,
      asc: !orderState.asc,
    });
  };

  useEffect(() => {
    // console.log(stateUsers);

    const findSelected = usersState.find((user) => {
      // console.log(user);
      return user.selected;
    });
    console.log(findSelected);

    setUserSelected(Boolean(findSelected));
  }, [usersState]);

  const selectUsers = (usersSelected, event) => {
    // console.log(stateUsers);
    console.log(event.target.type);
    if (usersSelected === 'all') {
      const selectAllUsers = usersState.map((user, i) => {
        // if () {
        // console.log(user);
        // console.log(selectAll)
        return {
          ...user,
          selected: !selectAll,
          key: i,
        };
        // }
      });

      const filteredUsers = selectAllUsers.filter((user) => {
        return user.role !== 'admin';
      });

      console.log(filteredUsers);
      // setChangePages(true);
      setOrderByState(false);

      setCurrentItems(filteredUsers);
      // setSelectAll(!selectAll);
    } else {
      const selectAllCopy = [...usersState];

      // const globalUserSelected = (pageUsers.number - 1) * pageUsers.usersPerPage + usersSelected;
      console.log('the user selected is:', usersSelected);
      // console.log(selectAllCopy[globalUserSelected]);
      selectAllCopy[usersSelected].selected = !selectAllCopy[usersSelected].selected;
      // selectAllCopy[globalUserSelected].selected = event.target.checked;

      // setChangePages(false);
      setOrderByState(false);
      setCurrentItems(selectAllCopy);
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    console.log(e.target.value);

    const selectedUsers = usersState.filter((user) => {
      return user.selected;
    });

    console.log(selectedUsers);

    if (selectedUsers.length > 0) {
      let title = '';
      // let users = selectedUsers.map(user => {
      //   return user._id;
      // })
      if (e.target.value === 'activate') {
        title = 'Activate Users';
      } else if (e.target.value === 'delete') {
        title = 'Delete Users';
      }

      setModalText({
        title: title,
        action: e.target.value,
        users: selectedUsers,
      });
      handleShow();
    }
  };

  const saveChanges = () => {
    console.log('inside save changes');
    if (modalText.action === 'activate') {
      dispatch(saveUsersAction(token, modalText));
    } else if (modalText.action === 'delete') {
      dispatch(deleteUsersAction(token, modalText));
    }
    setShow(false);
  };

  const findUser = (e) => {
    console.log('find a user');
    // console.log(e.target.value);
    const text = e.target.value.toLowerCase();
    console.log(text);
    console.log('usersState is:', filteredUsersState);
    const filteredusers = filteredUsersState.filter((user) => {
      console.log(user.name);
      return (
        user.role !== 'admin' &&
        (user.name.toLowerCase().indexOf(text) > -1 || user.email.toLowerCase().indexOf(text) > -1)
      );
    });

    setOrderByState(true);

    setCurrentItems(filteredusers.slice(0, itemsPerPage));
    // setFilteredUsersState(filteredusers);
    setPageCount(Math.ceil(filteredusers.length / itemsPerPage));
  };

  console.log(usersState);
  console.log('Filtered users are:');
  console.log(filteredUsersState);

  return (
    <div className="allUsersCtn container">
      <div className="row">
        <div className="col allUsersTable">
          {/* <h5 className="mb-4">Showing 1 - 25 of {stateUsers.length} Students</h5> */}
          <div className="row">
            <Col sm="5">
              <Form.Control
                className="my-3 input-md"
                type="text"
                placeholder="Find a user"
                onChange={findUser}
              />
            </Col>
          </div>
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      selectUsers('all', e);
                    }}
                  />
                  {userSelected ? (
                    <select defaultValue={'DEFAULT'} onChange={handleChange}>
                      <option value="DEFAULT" disabled>
                        Bulk Actions
                      </option>
                      <option value="activate">Activate</option>
                      <option value="delete">Delete</option>
                    </select>
                  ) : (
                    <span onClick={() => orderBy('name')}>Name</span>
                  )}
                </th>
                <th onClick={() => orderBy('email')}>Email</th>
                <th onClick={() => orderBy('active')}>Active</th>
                <th onClick={() => orderBy('purchases')}>Purchases</th>
                <th onClick={() => orderBy('date')}>Joined</th>
                <th onClick={() => orderBy('lastLogin')}>Last login</th>
              </tr>
            </thead>
            <tbody>
              {allUsers.length > 0 ? allUsers : <h3 className="my-3">No Users found</h3>}
            </tbody>
            {/* <h1>{!test.loading ? "Working" : null}</h1> */}
          </table>
        </div>
      </div>

      <div className="pagination-ctn">
        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< previous"
          // renderOnZeroPageCount={null}
        />
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalText.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to {modalText.action} the following users?</p>
          {modalText.users.map((user, i) => {
            return (
              <p key={i}>
                <b>{user.name}</b>
              </p>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={saveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AllUsers;
