import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { activateEmailAction } from '../../actions/auth';
import SecondHeader from '../partials/SecondHeader';
import { Card } from 'react-bootstrap';
import Loader from '../utils/Loader';

import styles from './ActivateEmail.module.css';

const ActivateEmail = () => {
  const { token } = useParams();

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { user, message, isActive, loading, isAuthenticated } = auth;

  console.log('This is my token ' + token);
  useEffect(() => {
    console.log('This is My Message+++++');
    console.log(message);
    dispatch(activateEmailAction(token));
  }, []);

  // useEffect(() => {
  //   if (user && !user.loading && isAuthenticated && user.active === 'active') {
  //     console.log('inside IF statement');
  //     history.push('/');
  //   }
  // }, [user, isAuthenticated]);

  console.log('testing values');
  console.log(user && !user.loading);
  console.log(isAuthenticated);
  console.log(user && user.active);
  console.log(user && user.active === 'active');

  return (
    <>
      <SecondHeader />
      <div className={`${styles.mainContainer}  mainBackground`}>
        <div className={`container ${styles.checkoutSuccessCtn}`}>
          <div className="row">
            <div className={`col-sm-12 offset-md-2 col-md-8 ${styles.checkoutSuccess}`}>
              {/* <Card className={styles.cardCtn}>
                <Card.Header>Success</Card.Header>
                <Card.Body>
                  <h1>{message}</h1>
                  {loading ? (
                    <Loader />
                  ) : active === 'active' ? (
                    <Link to="/courses" className="btn btn-success">
                      Start learning
                    </Link>
                  ) : (
                    <Link to="/activate" className="btn btn-success">
                      Activate account
                    </Link>
                  )}
                </Card.Body>
              </Card> */}
              <Card className="cardSmall">
                <Card.Header as="h5" className="bold">
                  Account status:
                </Card.Header>
                <Card.Body>
                  {/* <Card.Title>Special title treatment</Card.Title> */}

                  {loading ? (
                    <Loader />
                  ) : isActive ? (
                    <>
                      <Card.Text>{message}</Card.Text>

                      <Link to="/courses" className="button secondBtn">
                        Start learning
                      </Link>
                    </>
                  ) : (
                    <>
                      <Card.Text>{message}</Card.Text>

                      <Link to="/activate" className="button secondBtn">
                        Activate account
                      </Link>
                    </>
                  )}
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivateEmail;
