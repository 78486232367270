import React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import { RiMedal2Line } from 'react-icons/ri';from 'react-icons/ri';
import { useSelector } from 'react-redux';

import './CourseCard.css';

const CourseCard = (props) => {
  const auth = useSelector((state) => state.auth);
  const courses = useSelector((state) => state.courses);

  // const { coursesImages } = courses;

  // const { coursesProgress } = auth;

  // const theCourseImage = coursesImages.filter((image) => {
  //   return image.tag === props.tag;
  // });

  // const images = require.context('../../../../uploads/courses/', true);

  // let img = images(`./${props.tag}.jpg`);

  // const mydata = await axios.get('/api/s3images');
  // let img = mydata.image;

  // return <h1>Hello</h1>;

  return (
    <Col
      md={props.md}
      lg={props.lg}
      className={
        props.courseEnrolled || props.purchased
          ? 'offset-md-0 my-4 col-6'
          : 'offset-1 offset-md-0 my-4 col-10'
      }
    >
      <div className="cardBorder">
        <div
          className={
            !props.membershipStatus && !props.purchased && !props.publicAccess
              ? 'blockCourse'
              : null
          }
        ></div>
        {/* <div className={false ? 'blockCourse' : null}></div> */}
        <div className="courseThumbnail courseFeatured1">
          <Link
            className="courseTitle"
            to={
              props.courseEnrolled || props.purchased
                ? `/courses/${props.tag}/lessons/1`
                : `/courses/${props.tag}`
            }
          >
            {/* <img src={img.default} alt="javascript" /> */}
            <img src={`/courses/${props.tag}.jpg`} alt="my course" />
            {/* <img src={`/uploads/courses/react-beginners.jpg`} alt="my course" /> */}
          </Link>
        </div>
        <div className="courseTitleCtn">
          <Link className="courseTitle" to={`/courses/${props.tag}`}>
            {props.name}
          </Link>
        </div>
        {props.price ? (
          <>
            <div className="separator"></div>
            <div className="priceCtn">
              <span className="studentNumbers">
                {props.courseLevel === 'beginner' ? (
                  <>Beginner</>
                ) : props.courseLevel === 'intermediate' ? (
                  <>Intermediate</>
                ) : (
                  <>Advanced</>
                )}
              </span>
              {props.freeCourse ? (
                <span className="price free">
                  <b>FREE</b>
                </span>
              ) : (
                <span className="price">${props.price}</span>
              )}
            </div>
          </>
        ) : (
          <div className="courseProgressBorder">
            <div className="courseProgressCtn">
              <div
                style={{
                  backgroundColor: '#537ddc',
                  width: `${props.courseProgress || 0}%`,
                  height: '100%',
                }}
              ></div>
            </div>
            <p>{props.courseProgress}% Complete</p>
          </div>
        )}
      </div>
    </Col>
  );
};

CourseCard.defaultProps = {
  md: 6,
  lg: 3,
};

export default CourseCard;
