import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SecondHeader from '../partials/SecondHeader';
import { membershipPayment, membershipPayment3d } from '../../actions/payments';
import { editBillingAddress } from '../../actions/billing';
import { Row, Col, Card } from 'react-bootstrap';
import fetchFromAPI from '../../helpers';
import BillingAddress from './BillingAddress';
import { getCountryTax } from '../../actions/tax';
import Loader from '../utils/Loader';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { RESET_CHECKOUT_SUCCESS } from '../../contants/paymentConstants';
import { SUBSCRIPTION_RESET_MESSAGE } from '../../contants/subscriptionConstants';
import styles from './SubscriptionCheckout.module.css';
import { GoAlert } from 'react-icons/go';

const SubscriptionCheckout = ({ history }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { isAuthLoading, isAuthenticated, user, token, isActive } = auth;

  const billing = useSelector((state) => state.billing);

  const payment = useSelector((state) => state.payment);
  const { checkoutSuccess } = payment;
  const { editing, saveAddress, local, db } = useSelector((state) => state.billing);

  const { countryCode, countryTax, countryName, countryTaxLoading, countryTaxLoaded } = useSelector(
    (state) => state.tax
  );

  const subscriptionPricing = useSelector((state) => state.subscriptionPricing);

  const { monthlyPriceFull, yearlyPriceFull } = subscriptionPricing;

  const subscription = useSelector((state) => state.subscription);
  const { subscriptionError, tokens } = subscription;

  const { subscriptionId, clientSecret, paymentMethodId } = tokens;

  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [cards, setCards] = useState(null);
  const [paymentCard, setPaymentCard] = useState('');
  const [cardState, setCardState] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });
  const [membershipPrice, setMembershipPrice] = useState(null);

  const stripe = useStripe();
  const elements = useElements();

  const [hidePaymentChoice, setHidePaymentChoice] = useState(false);

  const [termsAgreed, setTermsAgreed] = useState(false);
  const [termsError, setTermsError] = useState('');

  const { duration } = useParams();

  useEffect(() => {
    document.title = `Subscription ${duration} page`;
    const price = duration === 'monthly' ? monthlyPriceFull : yearlyPriceFull;
    setMembershipPrice(price);
    dispatch({ type: RESET_CHECKOUT_SUCCESS });
  }, []);

  useEffect(() => {
    if (checkoutSuccess) {
      history.push('/cart/checkout/success');
    }
  }, [checkoutSuccess]);

  useEffect(() => {
    if (isAuthenticated && !countryTaxLoaded && (local.countryCode || db.countryCode)) {
      const countryCode = local.countryCode || db.countryCode;

      dispatch(getCountryTax(token, countryCode));
    }
  }, [isAuthenticated, local, db]);

  useEffect(() => {
    if (!isAuthLoading && isAuthenticated && isActive && user?.stripe?.customerId) {
      const savedCards = async () => {
        try {
          const cardsList = await fetchFromAPI(
            '/api/get-payment-methods',
            {
              body: {},
            },
            token
          );

          setCards(cardsList);
        } catch (error) {
          console.log(error);
        }
      };
      savedCards();
    }
  }, [isAuthLoading, isAuthenticated, user, isActive]);

  useEffect(() => {
    if (!isAuthLoading && !isAuthenticated) {
      history.push('/login');
    }
  }, [isAuthLoading, isAuthenticated]);

  useEffect(() => {
    if (subscriptionError) {
      setError(subscriptionError);
      // setProcessing(false);
    }
  }, [subscriptionError]);

  useEffect(() => {
    if (clientSecret) {
      stripe
        .confirmCardPayment(clientSecret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // alert(result.error.message);
            setError(`Payment Failed: ${result.error.message}`);
            // setProcessing(false);
          } else {
            // Successful subscription payment
            console.log(result);

            const addressToSave = {
              firstName: local.firstName ? local.firstName : db.firstName,
              lastName: local.lastName ? local.lastName : db.lastName,
              address: local.address ? local.address : db.address,
              city: local.city ? local.city : db.city,
              addressCode: local.addressCode ? local.addressCode : db.addressCode,
              country: local.country ? local.country : db.country,
              countryCode: local.countryCode ? local.countryCode : db.countryCode,
            };

            dispatch(
              membershipPayment(
                token,
                duration,
                addressToSave,
                saveAddress,
                paymentMethodId,
                subscriptionId
              )
            );
          }
        });
    }
  }, [clientSecret]);

  const handleCheckout = async () => {
    if (!cardState.cardNumber || !cardState.cardExpiry || !cardState.cardCvc) {
      if (!error) {
        setError('Your card details are incomplete');
      }
      return;
    } else if (!termsAgreed) {
      setTermsError('Review and accept terms & conditions before purchase');
      return;
    }

    setProcessing(true);

    try {
      const addressToSave = {
        firstName: local.firstName ? local.firstName : db.firstName,
        lastName: local.lastName ? local.lastName : db.lastName,
        address: local.address ? local.address : db.address,
        city: local.city ? local.city : db.city,
        addressCode: local.addressCode ? local.addressCode : db.addressCode,
        country: local.country ? local.country : db.country,
        countryCode: local.countryCode ? local.countryCode : db.countryCode,
      };

      const paymentMethod = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
        billing_details: {
          address: {
            city: addressToSave.city,
            country: addressToSave.countryCode,
            line1: addressToSave.address,
            line2: '',
            postal_code: addressToSave.addressCode,
            state: '',
          },
          email: user.email,
          name: `${addressToSave.firstName} ${addressToSave.lastName}`,
        },
      });

      if (paymentMethod.error) {
        console.log('payment error');
        setError(`Payment Failed: ${paymentMethod.error.message}`);
        setProcessing(false);
      } else {
        console.log('before dispatch');
        // dispatch(
        //   membershipPayment(
        //     token,
        //     duration,
        //     addressToSave,
        //     saveAddress,
        //     paymentMethod.paymentMethod.id,
        //     history
        //   )
        // );
        dispatch(
          membershipPayment3d(token, duration, addressToSave, paymentMethod.paymentMethod.id)
        );
      }
    } catch (error) {
      console.log('catch');
      setError(error.message);
      setProcessing(false);
    }
  };

  const savedCardCheckout = async () => {
    if (!termsAgreed) {
      setTermsError('Review and accept terms & conditions before purchase');
      return;
    }

    setProcessing(true);

    const addressToSave = {
      firstName: billing.local.firstName ? billing.local.firstName : billing.db.firstName,
      lastName: billing.local.lastName ? billing.local.lastName : billing.db.lastName,
      address: billing.local.address ? billing.local.address : billing.db.address,
      city: billing.local.city ? billing.local.city : billing.db.city,
      addressCode: billing.local.addressCode ? billing.local.addressCode : billing.db.addressCode,
      country: billing.local.country ? billing.local.country : billing.db.country,
      countryCode: local.countryCode ? local.countryCode : db.countryCode,
    };

    dispatch(membershipPayment(token, duration, addressToSave, saveAddress, paymentCard));
  };

  const cardHandleChange = (event) => {
    const { error } = event;
    setError(error ? error.message : '');
    if (processing) {
      setProcessing(false);
    }
    setCardState({
      ...cardState,
      [event.elementType]: event.complete,
    });
  };

  const cardStyle = {
    style: {
      base: {
        color: '#000',
        fontFamily: 'Roboto, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#606060',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  let cardOption;

  if (cards) {
    cardOption = cards.map((card) => {
      const {
        card: { brand, last4, exp_month, exp_year },
      } = card;
      return (
        <option key={card.id} value={card.id}>
          {`${brand}/ **** **** **** ${last4} ${exp_month}/${exp_year}`}
        </option>
      );
    });
    cardOption.unshift(
      <option key="Select a card" value="">
        Select a Card
      </option>
    );
  }

  const userCards = () => {
    if (cards && cards.length > 0) {
      return (
        <div className={styles.submitBtn}>
          <h3 className={styles.subTitle}>Pay with saved card</h3>
          <select
            className="mb-4"
            value={paymentCard}
            onChange={(e) => setPaymentCard(e.target.value)}
          >
            {cardOption}
          </select>
          <button
            type="submit"
            disabled={processing || !paymentCard}
            className={`button actionButton ${!paymentCard ? styles.hideSavedCard : ''}`}
            onClick={() => savedCardCheckout()}
          >
            <i className="fa fa-shopping-cart"></i>
            Complete purchase
          </button>
        </div>
      );
    }
    return '';
  };

  function priceWithVat() {
    if (countryTaxLoaded) {
      return (membershipPrice + membershipPrice * countryTax).toFixed(2);
    }
    return 0;
  }

  const resetErrorMessage = () => {
    setError('');
    setProcessing(false);
    dispatch({
      type: SUBSCRIPTION_RESET_MESSAGE,
    });
  };

  const paymentLoading = () => {
    return (
      <div className={styles.paymentLoadingOverlay}>
        <div className={styles.paymentLoadingCtn}>
          <Card>
            <Card.Header className="text-center">
              {error ? 'Payment failed' : 'Sending payment'}
            </Card.Header>
            <Card.Body className="py-4 text-center">
              {error ? (
                <GoAlert className={styles.paymentError} />
              ) : (
                <Loader width="50px" height="50px" />
              )}
              <h2 className="mt-4">{error ? 'Payment was not successful!' : 'Processing...'}</h2>
              {error ? (
                <>
                  <p className="mt-4">
                    <b>REASON:</b> {error}
                  </p>
                  <button className="button mainBtn mb-3" onClick={resetErrorMessage}>
                    Try again
                  </button>
                </>
              ) : (
                <>
                  <p className="mt-4">We are processing your payment.</p>
                  <p className="mb-3">Almost done!</p>
                </>
              )}
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  };

  const updateTerms = () => {
    setTermsAgreed(!termsAgreed);
  };

  return (
    <Fragment>
      <SecondHeader />
      {processing && paymentLoading()}
      <div className={`${styles.membershipCheckoutCtn} mainBackground`}>
        <div className="container">
          <div className="row my-4">
            <div className="col-12 col-lg-8 offset-lg-2">
              <Card className="boxShadow">
                {editing ? (
                  <>
                    <Card.Header>
                      <h1 className={styles.membershipCheckoutTitle}>Billing information</h1>
                    </Card.Header>
                    <div className={styles.billingAddressCtn}>
                      <BillingAddress />
                    </div>
                  </>
                ) : (
                  <>
                    <Card.Header>
                      <h1 className={styles.membershipCheckoutTitle}>
                        {duration === 'monthly' ? 'Monthly Subscription' : 'Yearly Subscription'}
                      </h1>
                    </Card.Header>
                    <div
                      className={`${styles.membershipCardCtn} ${styles.membershipCard} ${
                        hidePaymentChoice && 'flag'
                      }`}
                    >
                      <span
                        className={styles.editBillBtn}
                        onClick={() => dispatch(editBillingAddress())}
                      >
                        Edit billing address
                      </span>
                      <div className={styles.MembershipTotal}>
                        <div className={styles.priceDetails}>
                          <p>Price:</p>
                          <p>${membershipPrice}</p>
                        </div>
                        <div className={styles.priceDetails}>
                          {countryTax ? (
                            <>
                              <p>
                                Estimated tax: ({countryTax * 100}%{' '}
                                {countryName || local?.country || db?.country})
                              </p>

                              <p>${(countryTax * membershipPrice).toFixed(2)}</p>
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                        <hr />
                        <div className={`${styles.priceDetails} font-weight-bold`}>
                          <span>Billed today:</span>
                          <span>{priceWithVat()} USD</span>
                        </div>
                      </div>
                      <p className={styles.membershipChargingTime}>
                        {duration === 'monthly' ? 'Billed once a month' : 'Billed once a year'} -
                        automatically renews until cancelled
                      </p>
                      <div
                        className={`${styles.paymentCtn} ${
                          isAuthLoading ? styles.paymentLoading : ''
                        }`}
                      >
                        {/* <h1>Confirm your purchase:</h1> */}
                        {/* {showDropIn()} */}
                        {userCards()}
                        {!paymentCard && (
                          <>
                            {/* <img className={styles.paymentIcons} src="/payment-icons.png" alt="payment-icons" /> */}
                            <div className={`${styles.stripe_card} ${styles.cardNumber}`}>
                              <h3 className={styles.subTitle}>Credit card number</h3>
                              <div className={styles.iconsWrapper}>
                                <CardNumberElement
                                  className={`${styles.card_element} ${styles.paymentIconsBackground}`}
                                  options={cardStyle}
                                  onChange={cardHandleChange}
                                  style={{ backgroundImage: `url("/payment-icons.png")` }}
                                />
                                <img
                                  className={styles.paymentIcons}
                                  src="/payment-icons.png"
                                  alt="payment-icons"
                                />
                              </div>
                            </div>
                            <div className={styles.cardDetails}>
                              <div className={`${styles.stripe_card} ${styles.cardExpiry}`}>
                                <h3 className={styles.subTitle}>Expiration date</h3>
                                <CardExpiryElement
                                  id="cardExpiry"
                                  className={styles.card_element}
                                  options={cardStyle}
                                  onChange={cardHandleChange}
                                />
                              </div>
                              <div className={`${styles.stripe_card} ${styles.cardCvc}`}>
                                <h3 className={styles.subTitle}>CVC</h3>
                                <CardCvcElement
                                  className={styles.card_element}
                                  options={cardStyle}
                                  onChange={cardHandleChange}
                                />
                              </div>
                            </div>
                            {/* <div className={styles.saveCard}>
                              <label htmlFor="saveCard">Save Card</label>
                              <input
                                type="checkbox"
                                checked={saveCard}
                                id="saveCard"
                                onChange={(e) => setSavedCard(e.target.checked)}
                              />
                            </div> */}
                            <div className={`${styles.submitBtn} mb-4`}>
                              <button
                                disabled={processing}
                                // className="button is-black nomad-btn submit"
                                className="button actionButton"
                                onClick={() => handleCheckout()}
                              >
                                <i className="fa fa-shopping-cart"></i>
                                Complete purchase
                              </button>
                            </div>
                          </>
                        )}

                        {error && <p className={styles.errorMessage}>{error}</p>}
                        {termsError && !termsAgreed && (
                          <p className={styles.errorMessage}>{termsError}</p>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </Card>
            </div>
          </div>

          <Row>
            <Col>
              <div className="form-check text-center mb-4">
                <input
                  className={`form-check-input ${styles.termsCheckBox}`}
                  type="checkbox"
                  value={termsAgreed}
                  onChange={updateTerms}
                  id="agreeTerms"
                />
                <label
                  className={`${styles.checkoutTerms} text-center form-check-label ${
                    termsError && !termsAgreed && styles.textRed
                  }`}
                  htmlFor="agreeTerms"
                >
                  By completing this purchase, I agree to Telmo Academy -{' '}
                  <a
                    href="/terms"
                    rel="noreferrer"
                    target="_blank"
                    className={termsError && !termsAgreed && styles.textRed}
                  >
                    Terms of Use
                  </a>{' '}
                  &{' '}
                  <a
                    href="/privacy"
                    rel="noreferrer"
                    target="_blank"
                    className={termsError && !termsAgreed && styles.textRed}
                  >
                    Privacy Policy.
                  </a>
                </label>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
};

export default SubscriptionCheckout;
