import React from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from '../utils/Loader';
import './Admin.css';
import axios from 'axios';

function AdminSidebar() {
  const auth = useSelector((state) => state.auth);
  const { loading } = auth;

  let img = auth && auth.user && auth.user.image;

  if (!img) {
    img = 'https://telmo-files.ams3.digitaloceanspaces.com/telmo-academy/users/default.png';
  }
  let userPic = <img src={img} className="userAvatar" alt="User Profile" />;
  // const images = require.context('../../images/', true);
  // const images = require.context('../../../../uploads/users/', true);

  // let img;

  // if (auth && auth.user && auth.user._id && auth.user.hasProfilePic) {
  //   img = images(`./${auth.user._id}.jpg`);
  //   userPic = <img src={img.default} className="userAvatar" alt="user avatar" />
  // } else {
  //   img = images(`./default.png`);
  //   userPic = <img src={img.default} className="userAvatar" alt="user avatar" />
  // }

  // const mydata = await axios.get('/api/s3images');
  // userPic = mydata.image;

  return (
    <div className="col-xl-2 col-lg-3 col-md-4 userLeftCol">
      {loading ? <Loader /> : userPic}
      <h3>{auth && auth.user && auth.user.name}</h3>
      <h4>{auth && auth.user && auth.user.email}</h4>

      <ul className="profileLinks">
        <li>
          <NavLink exact to="/admin/courses" activeClassName="activeProfilePage">
            <i className="fa fa-graduation-cap"></i>Courses
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/users" activeClassName="activeProfilePage">
            <i className="fa fa-user"></i>Users
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/sales" activeClassName="activeProfilePage">
            <i className="fa fa-comment-dollar"></i>Sales
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/coupons" activeClassName="activeProfilePage">
            <i className="fa fa-percent"></i>Coupons
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/subscriptions" activeClassName="activeProfilePage">
            <i className="far fa-credit-card"></i>subscriptions
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/coursescomments" activeClassName="activeProfilePage">
            <i className="far fa-credit-card"></i>comments
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default AdminSidebar;
