export const LOGOUT = 'LOGOUT';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const ACCOUNT_ACTIVATION_FAIL = 'ACCOUNT_ACTIVATION_FAIL';

export const RESET_NOTIFICATION = 'RESET_NOTIFICATION';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';

export const RESET_MEMBERSHIP_LOADED = 'RESET_MEMBERSHIP_LOADED';

export const COURSES_OWNED_RELOAD = 'COURSES_OWNED_RELOAD';
