import {
  GET_ALL_LESSON_COMMENTS_REQUEST,
  GET_ALL_LESSON_COMMENTS_SUCCESS,
  GET_ALL_LESSON_COMMENTS_FAIL,
  GET_ALL_LESSON_COMMENTS_RESET,
} from '../contants/courseConstants';

const initialState = {
  commentsLoading: false,
  commentsLoaded: false,
  comments: [],
  commentsError: '',
};

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_LESSON_COMMENTS_REQUEST:
      return {
        ...state,
        commentsLoading: true,
        commentsLoaded: false,
      };
    case GET_ALL_LESSON_COMMENTS_SUCCESS:
      return {
        ...state,
        commentsLoading: false,
        commentsLoaded: true,
        comments: [...payload],
      };
    case GET_ALL_LESSON_COMMENTS_FAIL:
      return {
        ...state,
        commentsLoading: false,
        commentsLoaded: true,
        comments: [],
      };
    case GET_ALL_LESSON_COMMENTS_RESET:
      return {
        ...state,
        commentsLoading: false,
        commentsLoaded: false,
      };
    default:
      return state;
  }
}
