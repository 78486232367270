import React, { useEffect, useState, useRef } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserAction, loadUser, resetNotification, getUserImage } from '../../actions/auth';
import MessageDisplay from '../utils/MessageDisplay';
import './Profile.css';

function Profile() {
  const [cropState, setCropState] = useState({
    src: null,
    crop: {
      aspect: 1,
      height: 100,
      unit: 'px',
      width: 100,
      x: 0,
      y: 0,
    },
  });

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { isAuthLoading, notification } = auth;

  const [showNotification, setShowNotification] = useState({
    display: false,
    submitted: false,
  });

  const [page, setPage] = useState({
    loaded: false,
    showImagePreview: false,
  });

  useEffect(() => {
    document.title = `Profile page`;
  }, []);

  useEffect(() => {
    if (notification?.status && showNotification.submitted) {
      setShowNotification({
        ...showNotification,
        display: true,
      });
    }
  }, [notification?.status, showNotification.submitted]);

  useEffect(() => {
    if (page.showImagePreview) {
      // document.body.style.overflow = 'hidden';
      document.body.classList.add('lock');
    } else {
      // document.body.style.overflow = 'unset';
      document.body.classList.remove('lock');
    }
  }, [page.showImagePreview]);

  const [userDetails, setUserDetails] = useState({
    name: '',
    newPassword: '',
    newPasswordConfirm: '',
    password: '',
    showError: false,
    formChanged: false,
    isPhoto: false,
    message: '',
  });

  const { showError, formChanged, isPhoto } = userDetails;

  useEffect(() => {
    dispatch(resetNotification());
  }, []);

  useEffect(() => {
    setUserDetails({
      ...userDetails,
      name: auth?.user?.name,
    });
  }, [auth?.user?.name]);

  const updateUserDetails = (event) => {
    setUserDetails({
      ...userDetails,
      showError: false,
      formChanged: true,
      isPhoto: false,
      [event.target.name]: event.target.value,
    });

    if (showNotification.submitted) {
      setShowNotification({
        display: false,
        submitted: false,
      });
    }
  };

  const submitUserDetails = (event) => {
    event.preventDefault();
    if (formChanged) {
      if (
        (userDetails.newPassword || userDetails.newPasswordConfirm) &&
        userDetails.newPassword !== userDetails.newPasswordConfirm
      ) {
        setUserDetails({
          ...userDetails,
          showError: true,
          isPhoto: false,
          message: 'Passwords do not match',
        });
      } else {
        setUserDetails({
          ...userDetails,
          formChanged: false,
          showError: false,
          isPhoto: false,
          newPassword: '',
          newPasswordConfirm: '',
          password: '',
        });

        setShowNotification({
          ...showNotification,
          submitted: true,
        });

        dispatch(updateUserAction(auth && auth.token, userDetails));
      }
    }
  };

  const imageMaxSize = 2000000; // bytes
  const acceptedFileTypes = 'image/png, image/jpg, image/jpeg, image/gif';
  const acceptedFileTypesArray = acceptedFileTypes.split(',').map((item) => {
    return item.trim();
  });

  let imageRef = useRef();
  let fileRef = useRef();

  let img = auth && auth.user && auth.user.image;

  if (!img) {
    // img = 'https://telmo-files.ams3.digitaloceanspaces.com/telmo-academy/users/default.png';
    img = '/default.png';
  }

  const onSelectFile = (e) => {
    if (e.target.files.length < 1) {
      setUserDetails({
        ...userDetails,
        isPhoto: true,
        showError: true,
        message: 'No image selected',
      });
      return;
    }

    if (e.target.files[0].size > imageMaxSize) {
      setUserDetails({
        ...userDetails,
        isPhoto: true,
        showError: true,
        message: 'Image size should be less than 2 MB',
      });
      return;
    } else if (!acceptedFileTypesArray.includes(e.target.files[0].type)) {
      setUserDetails({
        ...userDetails,
        isPhoto: true,
        showError: true,
        message: 'Image should be of the type JPG, JPEG, PNG or GIF',
      });
      return;
    }

    setPage({ ...page, showImagePreview: true });
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        setCropState({
          ...cropState,
          src: reader.result,
        });
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onImageLoaded = async (image) => {
    imageRef.current = image;
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop, percentCrop) => {
    setCropState({
      ...cropState,
      crop,
    });
  };

  const makeClientCrop = async (crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(imageRef.current, crop, 'newFile.jpeg');

      setCropState({
        ...cropState,
        croppedImageUrl: croppedImageUrl,
      });
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const reader = new FileReader();
    canvas.toBlob((blob) => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        dataURLtoFile(reader.result, `${auth.user._id}.jpg`);
      };
    });
  };

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    setCropState({
      ...cropState,
      croppedImage: croppedImage,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const formData = new FormData();
      formData.append('file', cropState.croppedImage);
      formData.append('image', auth.user._id);

      const res = await axios.post(`/api/users/profilePic`, formData, config);

      dispatch(getUserImage());
      closeImagePreview();
    } catch (error) {
      const errors = error.response.data;
      console.log(error);
      setUserDetails({
        ...userDetails,
        isPhoto: true,
        showError: true,
        message: 'Image size should be less than 2 MB',
      });
      // console.log("File Size is too large. Allowed file size is 100KB");
      closeImagePreview();
      console.log(errors.message);
    }
  };

  const closeImagePreview = () => {
    setPage({ ...page, showImagePreview: false });

    fileRef.current.value = null;
    setCropState({
      ...cropState,
      src: null,
      crop: {
        ...cropState.crop,
        //   aspect: 1 / 1,
        //   aspect: 1,
        height: 100,
        unit: 'px',
        width: 100,
        x: 0,
        y: 0,
      },
    });
  };

  return (
    <div className="col-lg-9 col-md-12 profileDetails">
      <div className="userDetails card">
        {!isAuthLoading && showError && isPhoto && (
          <MessageDisplay header="Update error" status="Fail" message={userDetails.message} />
        )}
        <div className="uploadButtonCtn">
          <label htmlFor="file">
            <i className="fa fa-user-circle"></i>
            <span className="btn secondBtn">Upload image</span>
          </label>
          <input ref={fileRef} type="file" id="file" accept="image/*" onChange={onSelectFile} />
        </div>

        {page.showImagePreview && (
          <div className="imagePreviewOverlay">
            <i className="fa fa-times-circle closeOverlay" onClick={closeImagePreview}></i>
            <h2>Crop your Image</h2>
            {cropState.src && (
              <ReactCrop
                src={cropState.src}
                crop={cropState.crop}
                ruleOfThirds
                onImageLoaded={onImageLoaded}
                onComplete={onCropComplete}
                onChange={onCropChange}
              />
            )}
            {cropState.croppedImageUrl && (
              <img alt="Crop" style={{ maxWidth: '100%' }} src={cropState.croppedImageUrl} />
            )}
            {cropState.src ? (
              <form onSubmit={handleSubmit}>
                <button type="submit" className="btn secondBtn">
                  Save image
                </button>
              </form>
            ) : null}
          </div>
        )}

        <div className="limitSize">
          <hr />
          <form onSubmit={submitUserDetails}>
            <label className="userNameLabel" htmlFor="userName">
              Full Name
            </label>
            <input
              id="userName"
              type="text"
              placeholder="My name"
              value={userDetails.name || ''}
              name="name"
              onChange={updateUserDetails}
            />
            <h3>Change your password</h3>
            <div className="changePasswordCtn">
              <label htmlFor="newPassword">New Password</label>
              <input
                type="password"
                name="newPassword"
                id="newPassword"
                onChange={updateUserDetails}
                value={userDetails.newPassword}
              />
              <label htmlFor="newPasswordConfirm">Confirm Password</label>
              <input
                type="password"
                name="newPasswordConfirm"
                id="newPasswordConfirm"
                onChange={updateUserDetails}
                value={userDetails.newPasswordConfirm}
              />

              <hr />
              <label htmlFor="">To save new password, enter current password</label>
              <input
                type="password"
                name="password"
                onChange={updateUserDetails}
                value={userDetails.password}
              />
            </div>

            <button
              className={`mainBtn button ${!formChanged && 'btnDisabled'}`}
              disabled={!formChanged}
              type="submit"
            >
              Update Profile
            </button>
          </form>
          {!isAuthLoading && showError && !isPhoto && (
            <MessageDisplay header="Update error" status="Fail" message={userDetails.message} />
          )}
          {showNotification.display && notification?.status && (
            <MessageDisplay
              header={notification.status === 'success' ? 'Success' : 'Error'}
              status={notification.status}
              message={notification.message}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Profile;
