export const USERS_LIST_REQUEST = 'USERS_LIST_REQUEST';
export const USERS_LIST_SUCCESS = 'USERS_LIST_SUCCESS';
export const USERS_LIST_FAIL = 'USERS_LIST_FAIL';

export const ADMIN_UPDATE_USERS_REQUEST = 'ADMIN_UPDATE_USERS_REQUEST';
export const ADMIN_UPDATE_USERS_SUCCESS = 'ADMIN_UPDATE_USERS_SUCCESS';
export const ADMIN_UPDATE_USERS_FAIL = 'ADMIN_UPDATE_USERS_FAIL';

export const ADMIN_DELETE_USERS_REQUEST = 'ADMIN_DELETE_USERS_REQUEST';
export const ADMIN_DELETE_USERS_SUCCESS = 'ADMIN_DELETE_USERS_SUCCESS';
export const ADMIN_DELETE_USERS_FAIL = 'ADMIN_DELETE_USERS_FAIL';

export const ADMIN_ENROL_USER_IN_COURSE_REQUEST = 'ADMIN_ENROL_USER_IN_COURSE_REQUEST';
export const ADMIN_ENROL_USER_IN_COURSE_SUCCESS = 'ADMIN_ENROL_USER_IN_COURSE_SUCCESS';
export const ADMIN_ENROL_USER_IN_COURSE_FAIL = 'ADMIN_ENROL_USER_IN_COURSE_FAIL';

export const ADMIN_REMOVE_USER_COURSE_REQUEST = 'ADMIN_REMOVE_USER_COURSE_REQUEST';
export const ADMIN_REMOVE_USER_COURSE_SUCCESS = 'ADMIN_REMOVE_USER_COURSE_SUCCESS';
export const ADMIN_REMOVE_USER_COURSE_FAIL = 'ADMIN_REMOVE_USER_COURSE_FAIL';

export const ADMIN_GET_SALES_REQUEST = 'ADMIN_GET_SALES_REQUEST';
export const ADMIN_GET_SALES_SUCCESS = 'ADMIN_GET_SALES_SUCCESS';
export const ADMIN_GET_SALES_FAIL = 'ADMIN_GET_SALES_FAIL';

export const ADMIN_CREATE_COUPON_REQUEST = 'ADMIN_CREATE_COUPON_REQUEST';
export const ADMIN_CREATE_COUPON_SUCCESS = 'ADMIN_CREATE_COUPON_SUCCESS';
export const ADMIN_CREATE_COUPON_FAIL = 'ADMIN_CREATE_COUPON_FAIL';

export const ADMIN_GET_COUPON_REQUEST = 'ADMIN_GET_COUPON_REQUEST';
export const ADMIN_GET_COUPON_SUCCESS = 'ADMIN_GET_COUPON_SUCCESS';
export const ADMIN_GET_COUPON_FAIL = 'ADMIN_GET_COUPON_FAIL';

export const ADMIN_GET_COUPONS_REQUEST = 'ADMIN_GET_COUPONS_REQUEST';
export const ADMIN_GET_COUPONS_SUCCESS = 'ADMIN_GET_COUPONS_SUCCESS';
export const ADMIN_GET_COUPONS_FAIL = 'ADMIN_GET_COUPONS_FAIL';

export const ADMIN_UPDATE_COUPON_REQUEST = 'ADMIN_UPDATE_COUPON_REQUEST';
export const ADMIN_UPDATE_COUPON_SUCCESS = 'ADMIN_UPDATE_COUPON_SUCCESS';
export const ADMIN_UPDATE_COUPON_FAIL = 'ADMIN_UPDATE_COUPON_FAIL';

export const ADMIN_MEMBERSHIPS_REQUEST = 'ADMIN_MEMBERSHIPS_REQUEST';
export const ADMIN_MEMBERSHIPS_SUCCESS = 'ADMIN_MEMBERSHIPS_SUCCESS';
export const ADMIN_MEMBERSHIPS_FAIL = 'ADMIN_MEMBERSHIPS_FAIL';

export const ADMIN_SPECIALACCESS_SUCCESS = 'ADMIN_SPECIALACCESS_SUCCESS';

export const ADMIN_COURSES_COMMENTS_SUCCESS = 'ADMIN_COURSES_COMMENTS_SUCCESS';
