import axios from 'axios';

import {
  LOAD_DB_BILLING_ADDRESS_REQUEST,
  LOAD_DB_BILLING_ADDRESS_SUCCESS,
  EDIT_LOCAL_BILLING_ADDRESS,
  SAVE_LOCAL_BILLING_ADDRESS,
} from '../contants/billingConstants';

export const getBillingAddress = (token) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_DB_BILLING_ADDRESS_REQUEST,
    });
    const res = await axios.get('/api/users/getBillingAddress', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({
      type: LOAD_DB_BILLING_ADDRESS_SUCCESS,
      payload: res.data.billing,
    });
  } catch (err) {
    console.log(err);
  }
};

export const editBillingAddress = () => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_LOCAL_BILLING_ADDRESS,
    });
  } catch (err) {
    console.log(err);
  }
};

export const saveLocalBillingAddress = (localBillingAddress) => async (dispatch) => {
  try {
    dispatch({
      type: SAVE_LOCAL_BILLING_ADDRESS,
      payload: localBillingAddress,
    });
  } catch (err) {
    console.log(err);
  }
};
