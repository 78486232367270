import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { activateEmail } from '../../actions/auth';
import SecondHeader from '../partials/SecondHeader';
import { RESET_NOTIFICATION } from '../../contants/authConstants';
import { Card } from 'react-bootstrap';
import MessageDisplay from '../utils/MessageDisplay';
import styles from './Activate.module.css';
import Loader from '../utils/Loader';

const Activate = ({ history }) => {
  // if (!isAuthenticated) {
  //   return <Redirect to="/" />
  // }
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { isAuthenticated, user, isActive, loading, isAuthLoading, notification } = auth;

  // useEffect(() => {
  //   if(!isAuthenticated && !loading ) {
  //     history.push('/');
  //   }
  // }, [isAuthenticated, loading])

  useEffect(() => {
    if (!isAuthLoading && (!isAuthenticated || isActive)) {
      history.push('/');
    }

    // if (!isAuthLoading && notification && notification.message) {
    //   dispatch({ type: RESET_NOTIFICATION });
    // }
  }, [auth]);

  const email = user && user.email;

  const sendEmail = () => {
    dispatch(activateEmail(email));
  };

  return (
    <>
      <SecondHeader />
      <div className={`container-fluid ${styles.activateCtn} mainBackground`}>
        <div className="row">
          <div className="col-sm-12 offset-md-2 col-md-8">
            {/* <Card className={styles.cardCtn}>
              <Card.Body>
                <h1>Please confirm Email Address</h1>
                <h4>Check your Email inbox, to activate your account</h4>
                <p>Didn't received your email?</p>
                <button className="btn btn-success" onClick={sendEmail}>
                  Resend Email Activation
                </button>
                {!loading && notification && notification.status === 'success' ? (
                  <MessageDisplay
                    header="Success"
                    status={notification.status}
                    message={notification.message}
                  />
                ) : notification.status === 'success' ? (
                  <MessageDisplay
                    header="Error"
                    status={notification.status}
                    message={notification.message}
                  />
                ) : null}
              </Card.Body>
            </Card> */}
            <Card className="cardSmall">
              <Card.Header as="h5" className="bold">
                Please confirm email Address:
              </Card.Header>
              <Card.Body>
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    <Card.Text>Check your email inbox, to activate your account</Card.Text>
                    <Card.Text>Didn't receive your email? </Card.Text>
                    <button className="button secondBtn mainBackground" onClick={sendEmail}>
                      Resend Email Activation
                    </button>
                    {!loading && notification && notification.status === 'success' ? (
                      <MessageDisplay
                        header="Success"
                        status={notification.status}
                        message={notification.message}
                      />
                    ) : notification.status === 'success' ? (
                      <MessageDisplay
                        header="Error"
                        status={notification.status}
                        message={notification.message}
                      />
                    ) : null}
                  </>
                )}
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default Activate;
