import axios from 'axios';
import {
  UPDATE_USER,
  UPDATE_USER_ERROR,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED,
  USER_GUEST,
  AUTH_ERROR,
  EMAIL_ACTIVATION,
  ACCOUNT_ACTIVATION,
} from './types';

import { LOGOUT, LOGOUT_FAIL } from '../contants/authConstants';

import {
  FIND_USER_FAIL,
  FIND_USER_REQUEST,
  FIND_USER_SUCCESS,
  USER_DETAILS_REQUEST,
  USER_LAST_LOGIN_FAIL,
  USER_LAST_LOGIN_SUCCESS,
  GET_USER_IMAGE_REQUEST,
  GET_USER_IMAGE_SUCCESS,
} from '../contants/userConstants';

import {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  ACCOUNT_ACTIVATION_FAIL,
  RESET_NOTIFICATION,
  LOGIN_REQUEST,
  REGISTER_REQUEST,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_FAIL,
  RESET_MEMBERSHIP_LOADED,
} from '../contants/authConstants';

//Register User
export const register =
  (name, email, password, passwordConfirm, googleToken) => async (dispatch) => {
    try {
      dispatch({
        type: REGISTER_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const body = {
        name,
        email,
        password,
        passwordConfirm,
        token: googleToken,
      };

      const res = await axios.post('/api/users/register', body, config);

      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      const errors = error.response.data;
      console.log(errors.message);

      dispatch({
        type: REGISTER_FAIL,
        payload: errors,
      });
    }
  };

//Login User
export const login = (email, password, googleToken) => async (dispatch) => {
  try {
    dispatch({
      type: LOGIN_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = { email, password, googleToken };

    const { data } = await axios.post('/api/users/login', body, config);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    // const errors = err.response.data.errors;
    const errors = error.response.data;
    console.log(error.response.data.message);

    dispatch({
      type: LOGIN_FAIL,
      payload: errors,
    });
  }
};

//Load User
export const getUserDetails = (token, id) => async (dispatch) => {
  try {
    dispatch({
      type: FIND_USER_REQUEST,
    });

    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.get(`/api/users/getUserDetails/${id}`, config);
    console.log('USER DETAILS ++');
    console.log(res.data);

    // // delete res.data.user.image;

    const userImage = await axios.get(`/api/s3UserImageById/${id}`, config);
    console.log('THE USER IMAGE IS ++++++++++++++');
    console.log(userImage);

    const userData = {
      ...res.data.user,
      image: userImage.data.image,
    };

    console.log('My user data is ----');
    console.log(userData);

    dispatch({
      type: FIND_USER_SUCCESS,
      payload: userData,
    });
  } catch (err) {
    console.log('THE ERROR IS +++++++++++++++');
    console.log(err);
    dispatch({
      type: FIND_USER_FAIL,
    });

    //const errors = err.response.data.message;
  }
};

//Load User
export const loadUser = () => async (dispatch) => {
  try {
    const res = await axios.post('/api/users/loadUser');

    delete res.data.user.image;

    dispatch({
      type: USER_DETAILS_REQUEST,
    });

    // console.log("Loading User");
    if (res.data.token) {
      dispatch({
        type: USER_LOADED,
        payload: { ...res.data, imageLoaded: false },
      });
    } else if (res.data.status === 'guest') {
      dispatch({
        type: USER_GUEST,
      });
    }
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });

    //const errors = err.response.data.message;
    // console.log(err);
  }
};

//Last Login
export const lastLoginAction = (token) => async (dispatch, getState) => {
  try {
    const { auth } = getState();

    if (auth && !auth.loading) {
      const today = new Date();
      const lastLogin = new Date(auth && auth.user && auth.user.lastLogin);

      const diffMs = today - lastLogin; // milliseconds between now & Christmas
      const diffDays = Math.floor(diffMs / 86400000); // days
      const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
      const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

      if (diffMins >= 2) {
        const config = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        };

        await axios.patch('/api/users/lastLogin', {}, config);

        dispatch({
          type: USER_LAST_LOGIN_SUCCESS,
          payload: today,
        });
      }
    }
  } catch (error) {
    dispatch({
      type: USER_LAST_LOGIN_FAIL,
      payload: {
        message: 'Error updating last login',
      },
    });
    // console.log(error);
  }
};

//Logout /Clear Profile
export const logout = () => async (dispatch) => {
  try {
    await axios.get('/api/users/logout');

    //console.log(res.data);
    dispatch({
      type: LOGOUT,
    });

    // dispatch(loadUser());
  } catch (err) {
    console.log(err);
    const errors = err.response.data.errors;
    console.log(errors);
    // if(errors) {
    //     errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    // }

    dispatch({
      type: LOGOUT_FAIL,
    });
  }
};

//Forgot Password
export const fgt_pass = (email, googleToken) => async (dispatch) => {
  try {
    dispatch({
      type: FORGOT_PASSWORD_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = {
      email,
      googleToken,
    };

    const res = await axios.post('/api/users/forgotPassword', body, config);

    console.log(res.data);
    dispatch({
      type: FORGOT_PASSWORD,
      payload: res.data,
    });

    // dispatch(loadUser());
  } catch (error) {
    const errors = error.response.data;
    console.log(errors);

    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload: errors,
    });
  }
};

//Reset Password
export const reset_pass = (password, passwordConfirm, token) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_PASSWORD_REQUEST,
    });

    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };
    const body = { password, passwordConfirm };

    const { data } = await axios.patch(`/api/users/resetPassword/${token}`, body, config);

    console.log(data);
    dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errors = error.response.data;
    console.log(error.response.data.message);

    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload: errors,
    });
  }
};

export const activateEmail = (email) => async (dispatch) => {
  try {
    console.log(email);
    const { data } = await axios.post(`/api/users/activateAccount/${email}`);
    console.log(data);
    dispatch({
      type: EMAIL_ACTIVATION,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const activateEmailAction = (token) => async (dispatch) => {
  try {
    const res = await axios.post(`/api/users/activate/${token}`);

    dispatch({
      type: ACCOUNT_ACTIVATION,
      payload: res.data,
    });
  } catch (error) {
    const errors = error.response.data;
    console.log('///////// Errors');
    console.log(errors);
    dispatch({
      type: ACCOUNT_ACTIVATION_FAIL,
      payload: errors,
    });
  }
};

export const resetNotification = () => async (dispatch) => {
  try {
    dispatch({
      type: RESET_NOTIFICATION,
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateUserAction = (token, userDetails) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_NOTIFICATION,
    });

    const body = {
      name: userDetails.name,
      newPassword: userDetails.newPassword,
      newPasswordConfirm: userDetails.newPasswordConfirm,
      password: userDetails.password,
    };

    const res = await axios.post(`/api/users/updateUserDb`, body, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({
      type: UPDATE_USER,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    const errors = error.response.data;
    dispatch({
      type: UPDATE_USER_ERROR,
      payload: errors,
    });
  }
};

export const getUserImage = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_IMAGE_REQUEST,
    });

    const { data } = await axios.get('/api/s3UserImage');

    dispatch({
      type: GET_USER_IMAGE_SUCCESS,
      payload: data.image,
    });
  } catch (error) {}
};

export const resetsubscriptionLoaded = () => async (dispatch) => {
  try {
    dispatch({
      type: RESET_MEMBERSHIP_LOADED,
    });
  } catch (error) {
    console.log(error);
  }
};
