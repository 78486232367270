import React from 'react';
import SecondHeader from '../partials/SecondHeader';
import styles from './PageWrapper.module.css';

const PageWrapper = ({ children }) => {
  return (
    <>
      <SecondHeader />
      <div className={`${styles.wrapperCtn} mainBackground`}>
        <div className="container">{children}</div>
      </div>
    </>
  );
};

export default PageWrapper;
